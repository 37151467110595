import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _map from "lodash/map";
import { Helmet } from "react-helmet";

import loading from "../../hoc/loading";
import BackButton from "../../components/BackButton";
import Collection from "../../components/Collection";
import Image from "../../components/Image";
import LeftColumnLayout from "../../layouts/LeftColumn";
import { collectionsQuery } from "../../graphql/queries";

import {
  CollectionsTitle,
  CollectionsKindTitle,
  CollectionsList,
  CollectionsListLink,
  CollectionsSlider,
  CollectionLink
} from "./style";

const sliderSettings = {
  arrows: false,
  dots: true,
  rows: 1,
  slidesPerRow: 3
};

const kindNames = {
  clothing: "Vêtements",
  linen: "Linges",
  carpet: "Tapis",
  accessory: "Accessoires"
};

export default compose(
  graphql(collectionsQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({
      data: { loading, collections = {} },
      ownProps: { match: { params: { kind } } }
    }) => {
      return {
        loading,
        collections: collections
          ? _filter(
              _map(collections.edges, "node"),
              ({ kinds }) => kinds.indexOf(kind) !== -1
            )
          : [],
        kind: {
          slug: kind,
          name: kindNames[kind]
        }
      };
    }
  }),
  loading()
)(function CollectionsKind({
  collections = [],
  kind = {},
  match: { params: { collection } }
}) {
  const activeCollection = collection
    ? _find(collections, ({ slug }) => slug === collection)
    : null;

  return (
    <LeftColumnLayout
      column={
        <Fragment>
          <BackButton to={`/collections`} />
          <CollectionsTitle>Collections</CollectionsTitle>
          <CollectionsKindTitle>{kind.name}</CollectionsKindTitle>
          <CollectionsList>
            {collections.map(({ id, slug, name }) => (
              <CollectionsListLink
                key={id}
                prefix="Collection"
                hasCollectionActive={!!activeCollection}
                to={`/collections/${kind.slug}/${slug}`}
              >
                {name}
              </CollectionsListLink>
            ))}
          </CollectionsList>
        </Fragment>
      }
    >
      {!activeCollection ? (
        <Fragment>
          <Helmet>
            <title>{kind.name}</title>
          </Helmet>
          <CollectionsSlider
            {...sliderSettings}
            arrows={collections.length >= 10}
            dots={!(collections.length >= 10)}
            slidesPerRow={Math.min(
              collections.length,
              sliderSettings.slidesPerRow
            )}
          >
            {collections.map(({ id, slug, name, listView }) => (
              <CollectionLink
                key={id}
                prefix="Collection"
                to={`/collections/${kind.slug}/${slug}`}
                image={
                  listView && <Image media={listView} size="collection-list" />
                }
              >
                {name}
              </CollectionLink>
            ))}
          </CollectionsSlider>
        </Fragment>
      ) : (
        <Fragment>
          <Helmet>
            <title>{`Collection ${activeCollection.name} dans ${
              kind.name
            }`}</title>
          </Helmet>
          <Collection
            key={activeCollection.id}
            slug={activeCollection.slug}
            kind={kind.slug}
          />
        </Fragment>
      )}
    </LeftColumnLayout>
  );
});
