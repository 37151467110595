import React from "react";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";
import Image from "react-image";

import CollectionLinkOriginal from "../../components/CollectionLink";
import { Section } from "../../components/Container/style";
import Slider from "../../components/Slider";

import { get, reset, responsiveImg } from "../../css/util";

export const Layout = Section.withComponent("article");

export const StaticPages = styled.nav`
  display: flex;
  flex-direction: row;
`;

export const StaticPageLink = styled(function({
  iconSrc,
  children,
  className,
  ...props
}) {
  return (
    <div className={className}>
      <NavLink {...props}>
        {children}
        <img src={iconSrc} alt="" />
      </NavLink>
    </div>
  );
})`
  flex: 1;
  padding: ${get("spacing.padding")};
  text-align: center;

  a {
    ${get("style.link")};
    ${get("font.home.title")};
    background: transparent 7px center / 18px 18px
      url("/images/bullet-arrow.svg") no-repeat;
    display: inline-block;
    line-height: 48px;
    padding-left: 32px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all ${get("transition.link")};
    vertical-align: middle;
    img {
      ${responsiveImg(true)};
      height: 48px;
      margin: 0 0 0 0.5em;
      vertical-align: middle;
    }

    &:hover {
      background-position: 10px center;
    }
  }
`;

const ContentBlock = styled(function({ title, children, ...props }) {
  return (
    <section {...props}>
      {!!title && (
        <h2>
          <span>{title}</span>
        </h2>
      )}
      <div>{children}</div>
    </section>
  );
})`
  margin: 0;

  > h2 {
    ${reset()};
    ${get("font.home.headline")};
    color: ${get("color.title")};
    margin: ${get("spacing.padding")} 0;
    position: relative;
    text-align: center;

    &:before {
      background: ${get("color.line")};
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 1;
    }

    span {
      background: ${get("color.background")};
      padding: 0 ${get("spacing.padding")};
      position: relative;
      z-index: 2;
    }
  }
`;

export const CatalogContentBlock = ContentBlock.extend`
  > div {
    display: flex;
  }
`;

export const CatalogLink = styled(function({ image, children, ...props }) {
  return (
    <NavLink {...props}>
      {!!image && (
        <figure>
          <Image src={image} alt={children} />
        </figure>
      )}
      <span>{children}</span>
    </NavLink>
  );
})`
  ${get("style.link")};
  ${get("font.home.headline")};
  display: block;
  flex: 1;
  margin-left: ${get("spacing.paddingDouble")};

  &:first-child {
    margin-left: 0;
  }

  > figure {
    ${reset()};

    img {
      ${responsiveImg()};
      width: 100%;
    }
  }

  > span {
    ${get("font.home.title")};
    display: block;
    padding: ${get("spacing.paddingHalf")} 0;
  }
`;

export const FeaturedCollectionsContentBlock = ContentBlock;

export const FeaturedCollectionsSlider = styled(Slider)`
  .slick-arrow {
    margin-top: calc(-16px - 20px / 2 - ${get("spacing.paddingHalf")});
  }
`;

export const CollectionLink = styled(CollectionLinkOriginal)`
`;
