import styled from "styled-components";

import { get, reset, responsiveImg } from "../../css/util";

export const Icon = styled.figure`
  ${reset()};
  border: 2px solid ${get("theme.color.primary")};
  border-color: ${get(
    "theme.color.primary"
  )} !important; // semble nécessaire...
  border-radius: 100%;
  box-sizing: border-box;
  height: 64px;
  margin: 0 auto;
  padding: 16px;
  width: 64px;

  > img {
    ${responsiveImg()};
  }
`;
