import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "apollo-boost";
import { persistCache } from "apollo-cache-persist";
import fetch from "unfetch";

import config from "./config";

export function createApolloClient(clientConfig = {}, initialState = {}) {
  const httpLink = new HttpLink({
    fetch: fetch,
    uri: config.GRAPHQL_URI,
    credentials: "same-origin",
  });

  // https://risan.io/set-authorization-header-with-apollo-client.html
  const authLink = new ApolloLink((operation, forward) => {
    let tokens = null;
    try {
      tokens = JSON.parse(localStorage.getItem("tokens") || "null");
    } catch (err) {
      console.error(err);
      localStorage.setItem("tokens", null);
    }
    operation.setContext({
      headers: {
        authorization: tokens ? `Bearer ${tokens.access_token}` : "",
      },
    });
    return forward(operation);
  });

  const cache = new InMemoryCache();

  persistCache({
    cache,
    storage: window.localStorage,
    maxSize: false,
  });

  return new ApolloClient({
    connectToDevTools: process.browser,
    link: authLink.concat(httpLink),
    cache: cache.restore(initialState || {}),
    ...clientConfig,
  });
}

export const apolloClient = createApolloClient({ ssrMode: false });
