import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { compose, pure, withStateHandlers } from "recompose";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _pick from "lodash/pick";
import _values from "lodash/values";

import loading from "../../hoc/loading";
import { collectionQuery } from "../../graphql/queries";

import Configurator from "../Configurator";
import cartStore from "../../stores/cart";
import { connect } from "react-redux";
import BackgroundImage from "../BackgroundImage";

import { Layout, CollectionInfos, CustomizeButton } from "./style";

const COLLECTION_PROPERTIES = [
  "id",
  "baseline",
  "name",
  "preview",
  "listView",
  "featuredView",
  "details",
  "orderDetails",
];
const COMBINATION_PROPERTIES = ["id", "name", "parts"];
const COMBINATION_PART_PROPERTIES = ["id", "name"];
const PRODUCT_PROPERTIES = [
  "id",
  "name",
  "kind",
  "fullImage",
  "properties",
  "standards",
];
const VARIANT_PROPERTIES = [
  "id",
  "image",
  "reference",
  "description",
  "attributes",
];
const VARIANT_ATTRIBUTES_PROPERTIES = ["property", "value"];
const PRODUCT_PROPERTY_PROPERTIES = [
  "id",
  "name",
  "kind",
  "required",
  "colors",
  "tags",
];
const PRODUCT_COLOR_PROPERTIES = ["id", "name", "kind", "colors"];
const MEDIA_PROPERTIES = ["id", "name", "hash"];
function prepareMediaProperties(media) {
  if (!media) return null;
  return _pick(media, MEDIA_PROPERTIES);
}
function prepareProductProperties(properties) {
  return _map(properties, (property) => ({
    ..._pick(property, PRODUCT_PROPERTY_PROPERTIES),
    colors: _map(property.colors, (color) =>
      _pick(color, PRODUCT_COLOR_PROPERTIES)
    ),
  }));
}
function prepareProduct(product, variant) {
  return !product || !variant
    ? null
    : {
        ..._pick(product, PRODUCT_PROPERTIES),
        properties: prepareProductProperties(product.properties),
        variant: prepareVariant(variant),
      };
}
function prepareVariant(variant) {
  return !variant
    ? null
    : {
        ..._pick(variant, VARIANT_PROPERTIES),
        image: prepareMediaProperties(variant.image),
        attributes: _map(variant.attributes, (att) =>
          _pick(att, VARIANT_ATTRIBUTES_PROPERTIES)
        ),
      };
}
function prepareProductToCart(
  collection,
  { combination: combinationRaw, products: productsRaw, variants: variantsRaw }
) {
  const productsList = _values(productsRaw);

  const singleProduct =
    combinationRaw || !productsList.length || !variantsRaw[productsList[0].id]
      ? null
      : prepareProduct(productsList[0], variantsRaw[productsList[0].id]);
  const parts = !combinationRaw
    ? []
    : _filter(
        _map(combinationRaw.parts, (combinationPart) =>
          productsRaw[combinationPart.id]
            ? {
                ..._pick(combinationPart, COMBINATION_PART_PROPERTIES),
                product: prepareProduct(
                  productsRaw[combinationPart.id],
                  variantsRaw[productsRaw[combinationPart.id].id]
                ),
              }
            : null
        ),
        (part) => part && part.product
      );
  const combination =
    !combinationRaw || !parts.length
      ? null
      : {
          ..._pick(combinationRaw, COMBINATION_PROPERTIES),
          parts,
        };

  return combination || singleProduct
    ? {
        collection: {
          ..._pick(collection, COLLECTION_PROPERTIES),
          preview: prepareMediaProperties(collection.preview),
          listView: prepareMediaProperties(collection.listView),
          featuredView: prepareMediaProperties(collection.featuredView),
          details: prepareMediaProperties(collection.details),
          orderDetails: prepareMediaProperties(collection.orderDetails),
        },
        combination,
        singleProduct,
      }
    : null;
}

export default compose(
  pure,
  connect(
    () => ({}),
    (dispatch) => ({
      addProduct: (product) => dispatch(cartStore.cartAddProduct(product)),
    })
  ),
  graphql(collectionQuery, {
    options: ({ slug, kind }) => ({
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network",
      variables: { slug, kind },
    }),
    props: ({ data: { loading, collection } }) => {
      return { collection, loading };
    },
  }),
  withStateHandlers(
    { opened: false }, // Open collection
    {
      open: () => () => ({ opened: true }),
    }
  ),
  loading()
)(
  class Collection extends React.Component {
    onAddProduct = (product) => {
      const preparedProduct = prepareProductToCart(
        this.props.collection,
        product
      );
      if (!preparedProduct) return;
      return this.props.addProduct(preparedProduct);
    };

    render() {
      const { collection = {}, opened } = this.props;

      return (
        <Layout>
          {opened ? (
            <Configurator
              key={collection.id}
              collection={collection}
              products={collection.products}
              onAddProduct={this.onAddProduct}
            />
          ) : (
            <Fragment>
              {!!collection.preview && (
                <BackgroundImage
                  media={collection.preview}
                  size="collection-preview"
                />
              )}
              <CollectionInfos
                style={{ position: "relative", zIndex: 2 }}
                prefix="Collection"
                name={collection.name}
              >
                {collection.baseline}
              </CollectionInfos>
              <CustomizeButton onClick={this.props.open}>
                Personnaliser
              </CustomizeButton>
            </Fragment>
          )}
        </Layout>
      );
    }
  }
);
