import React from "react";
import { compose, withStateHandlers, lifecycle } from "recompose";
import styled from "styled-components";

import { reset, get, mixin } from "../css/util";

const LoadingMessage = styled(function({ className, children }) {
  return (
    <div className={className}>
      <p>{children}</p>
    </div>
  );
})`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  > p {
    ${reset()};
    ${mixin("font.bold", 14)};
    color: ${get("color.grey")};
    padding: ${get("spacing.paddingDouble")};
    text-align: center;
  }
`;

export default function loading(expiration = 500) {
  return function(Component) {
    return compose(
      withStateHandlers(
        { expired: false },
        {
          resetExpired: () => () => ({ expired: false }),
          hasExpired: () => () => ({ expired: true })
        }
      ),
      lifecycle({
        componentDidMount() {
          if (loading) {
            this._timeout = setTimeout(this.props.hasExpired, expiration);
          }
        },
        componentWillReceiveProps(nextProps) {
          if (nextProps.loading && !this.props.loading) {
            // Reset expiration
            if (this._timeout) window.clearTimeout(this._timeout);
            this.props.resetExpired();
          }
          if (!nextProps.expired && this.props.expired) {
            // New timeout after resetting expiration
            this._timeout = setTimeout(this.props.hasExpired, expiration);
          }
        }
      })
    )(function({ loading, expired, ...props }) {
      if (loading) {
        if (!expired) return null;
        return <LoadingMessage>Chargement&hellip;</LoadingMessage>;
      }
      return <Component {...props} />;
    });
  };
}
