import React from "react";
import styled, { css } from "styled-components";
import { reset } from "../../css/util";

export const ImageContainer = styled.div`
  height: 100%;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  ${({ index }) => css`
    z-index: ${99 - index};
  `};
`;

export const PinContainer = styled.div`
  display: inline-block;
  height: 100%;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: auto;

  > img {
    /**
     * Devrait être height: 100%, mais à cause d'un bug firefox, on définit une hauteur fixe.
     */
    height: calc(100vh - 190px);
  }
`;

export const Images = styled(function ({ children, onHeight, ...props }) {
  return (
    <figure {...props}>
      {React.Children.map(children, (child, index) => (
        <ImageContainer key={index} index={index} onHeight={onHeight}>
          <PinContainer index={index}>{child}</PinContainer>
        </ImageContainer>
      ))}
    </figure>
  );
})`
  ${reset()};
  flex: 1;
  position: relative;
  text-align: center;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
`;

export const Pin = styled(function ({ x, y, image, ...props }) {
  return <div {...props} />;
})`
  ${reset()};
  background: transparent center center / 100% 100%
    url("/images/point-bleu-zoom.svg");
  cursor: pointer;
  height: 26px;
  left: ${({ x }) => x * 100 + "%"};
  pointer-events: all;
  position: absolute;
  top: ${({ y }) => y * 100 + "%"};
  transform: translate(-50%, -50%);
  transition: all 0.3s;
  width: 26px;
  z-index: 100;

  &:hover {
    transform: translate(-50%, -50%) scale(1.4);
  }
`;
