import _cloneDeep from "lodash/cloneDeep";
import _set from "lodash/set";

import builder from "./util";

export default builder({
  getInitialState: () => ({
    items: [
      // {
      //   product: {
      //     collection: {
      //       id: 'abc',
      //       slug: 'millesime',
      //       name: 'Millésime',
      //     },
      //     combination: {
      //       id,
      //       name,
      //       parts: [{
      //         id,
      //         name,
      //         product: {
      //           id,
      //           name,
      //           variant: {...}
      //         }
      //       }]
      //     },
      //     singleProduct: {
      //       id,
      //       name,
      //       variant: {...}
      //     }},
      //   quantity: 1,
      //   comment: '',
      // }
    ],
    date: null,
    customer: "",
    reference: "",
    member: {
      name: null,
      company: null,
      phone: null,
      email: null
    }
  }),
  actions: {
    cartSetValue: cart => ({ key, value }) => {
      const newCart = _cloneDeep(cart);
      _set(newCart, key, value);
      return newCart;
    },
    cartAddProduct: ({ items: oldItems, ...cart }) => (
      product,
      quantity = 1
    ) => ({
      ...cart,
      items: [...oldItems, { product, quantity }]
    }),
    cartRemoveItem: ({ items, ...cart }) => index => ({
      ...cart,
      items: [...items.slice(0, index), ...items.slice(index + 1)]
    }),
    cartReset: cart => (member = {}) => ({
      ...cart,
      date: null,
      customer: "",
      reference: "",
      member,
      items: []
    })
  }
});
