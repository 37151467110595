import styled from "styled-components";

import { get, media } from "../../css/util";

export const Container = styled.article`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 100%;
`;
export const Aside = styled.aside`
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  flex: 0;
  flex-basis: 200px;
  margin: 0;
  overflow: auto;
  padding: ${get("spacing.padding")};
  position: relative;
  z-index: 10;

  ${media.largeWidth`
    flex-basis: 300px;
  `};
`;
export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  max-height: 100%;
  overflow: auto;
  padding: ${get("spacing.padding")};
  position: relative;
  z-index: 1;
`;
