import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import vetementsImage from "./images/vetements.jpg";
import lingesImage from "./images/linges.jpg";
import tapisImage from "./images/tapis.jpg";
import accessoiresImage from "./images/accessoires.jpg";

import { CollectionsBlock, CollectionsLink } from "./style";

export default function Collections() {
  return (
    <Fragment>
      <Helmet>
        <title>Collections</title>
      </Helmet>
      <CollectionsBlock>
        <CollectionsLink to={`/collections/clothing`} image={vetementsImage}>
          Vêtements
        </CollectionsLink>
        <CollectionsLink to={`/collections/linen`} image={lingesImage}>
          Linges
        </CollectionsLink>
        <CollectionsLink to={`/collections/carpet`} image={tapisImage}>
          Tapis
        </CollectionsLink>
        <CollectionsLink to={`/collections/accessory`} image={accessoiresImage}>
          Accessoires
        </CollectionsLink>
      </CollectionsBlock>
    </Fragment>
  );
}
