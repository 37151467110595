import React from "react";
import { graphql } from "react-apollo";
import { Helmet } from "react-helmet";
import { compose } from "recompose";

import Image from "../../components/Image";
import { homeQuery } from "../../graphql/queries";
import loading from "../../hoc/loading";
import categoriesImage from "./images/categories.jpg";
import collectionsImage from "./images/collections.jpg";
import expertiseIcon from "./images/expertise-icon.svg";
import innovationIcon from "./images/innovation-icon.svg";
import {
  CatalogContentBlock,
  CatalogLink,
  CollectionLink,
  FeaturedCollectionsContentBlock,
  FeaturedCollectionsSlider,
  Layout,
  StaticPageLink,
  StaticPages
} from "./style";

const sliderSettings = {
  arrows: true,
  dots: false,
  rows: 1,
  slidesPerRow: 3
};

export default compose(
  graphql(homeQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({ data: { loading, featuredCollections } }) => {
      return { featuredCollections, loading };
    }
  }),
  loading()
)(function Home({ featuredCollections = [] }) {
  return (
    <Layout>
      <Helmet>
        <title>Accueil</title>
      </Helmet>
      <StaticPages>
        <StaticPageLink to={"/expertise-initial"} iconSrc={expertiseIcon}>
          L'expertise Initial
        </StaticPageLink>
        <StaticPageLink to={"/innovation-textile"} iconSrc={innovationIcon}>
          L'innovation textile
        </StaticPageLink>
      </StaticPages>
      <CatalogContentBlock title="Votre catalogue personnalisable">
        <CatalogLink to={"/metiers"} image={categoriesImage}>
          Métiers
        </CatalogLink>
        <CatalogLink to={"/collections"} image={collectionsImage}>
          Collections
        </CatalogLink>
      </CatalogContentBlock>
      {!!featuredCollections &&
        !!featuredCollections.length && (
          <FeaturedCollectionsContentBlock title="Focus nouveautés">
            <FeaturedCollectionsSlider {...sliderSettings}>
              {featuredCollections.map(
                ({ id, name, slug, featuredView, listView }) => (
                  <CollectionLink
                    key={id}
                    to={`/focus/${slug}`}
                    prefix="Collection"
                    image={
                      (featuredView || listView) && (
                        <Image
                          media={featuredView || listView}
                          size="collection-featured"
                        />
                      )
                    }
                  >
                    {name}
                  </CollectionLink>
                )
              )}
            </FeaturedCollectionsSlider>
          </FeaturedCollectionsContentBlock>
        )}
    </Layout>
  );
});
