import React from "react";
import styled from "styled-components";

import { get, mixin, reset } from "../../css/util";

export const Layout = styled.section`
  background: ${get('color.collection.background')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  margin: -${get("spacing.padding")};
  position: relative;
`;

export const CollectionInfos = styled(function({
  prefix,
  name,
  children,
  ...props
}) {
  return (
    <div {...props}>
      <h1>
        {!!prefix && <small>{prefix}</small>}
        {name}
      </h1>
      <p>{children}</p>
    </div>
  );
})`
  margin: ${get("spacing.paddingDouble")};
  position: relative;
  width: 280px;
  z-index: 2;

  > h1 {
    ${reset()};
    ${mixin("font.default", 36)};
    color: ${get("color.blue")};
    white-space: nowrap;

    small {
      ${mixin("font.bold", 16)};
      color: ${get("color.text")};
      display: block;
      text-transform: uppercase;
    }

    &:after {
      background: ${get("color.line")};
      content: "";
      display: block;
      height: 1px;
      margin: ${get("spacing.paddingDouble")} 0;
      width: 50%;
    }
  }

  > p {
    ${reset()};
    ${mixin("font.default", 20, 1.1)};
    color: ${get("color.text")};
    display: inline;
    margin-top: ${get("spacing.paddingDouble")};
    text-transform: uppercase;
  }
`;

export const StandardsList = styled(function({ children, ...props }) {
  return (
    <ul {...props}>
      {React.Children.map(children, (child, index) => (
        <li key={index}>{child}</li>
      ))}
    </ul>
  );
})`
  ${reset()};
  position: absolute;
  right: ${get("spacing.paddingDouble")};
  text-align: right;
  top: ${get("spacing.paddingDouble")};
  z-index: 7;
  pointer-events: none;

  > li {
    ${reset()};
    margin-bottom: ${get('spacing.paddingHalf')};
    text-align: right;
  }
`;

export const StandardIcon = styled(function({ name, svg, className }) {
  return <span dangerouslySetInnerHTML={{__html: svg}} title={name} className={className}></span>
})`
  display: block;

  > svg {
    fill: currentColor;
    width: 48px;
  }
`;

export const CustomizeButton = styled.a`
  ${get("style.button")};
  background: ${get('color.collection.background')};
  bottom: ${get("spacing.paddingDouble")};
  cursor: pointer;
  position: absolute;
  right: ${get("spacing.paddingDouble")};
  z-index: 8;
`;
