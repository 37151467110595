import React from "react";
import styled from "styled-components";

import { Icon } from "../../components/CategoryIcon/style";

import { get, mixin, reset } from "../../css/util";

export const Container = styled(function({ className, ...props }) {
  return (
    <div className={className}>
      <div {...props} />
    </div>
  );
})`
  align-items: center;
  background: #eaeaea url("/images/cross-background.png") center center/cover
    no-repeat;
  display: flex;
  flex: 1;
  justify-content: center;

  > div {
    background: ${get("color.background")};
    border: 1px solid ${get("color.primary")};
    margin: 0 auto;
    padding: ${get("spacing.paddingDouble")};
    width: 380px;
  }
`;

export const TitleIcon = Icon.extend`
  svg {
    fill: ${get("color.primary")};
  }
`;
export const Title = styled(function({ icon, children, ...props }) {
  return (
    <div {...props}>
      <h1>{children}</h1>
      {icon}
    </div>
  );
})`
  margin: 0;

  > h1 {
    ${reset()};
    ${get("font.headline")};
    margin: ${get("spacing.padding")};
    text-align: center;
  }
`;

export const Form = styled.form`
  ${reset()};
  margin-top: 50px;
`;

export const FullName = styled.p`
  ${reset()};
  ${mixin("font.bold", 16)};
  margin: ${get("spacing.padding")} 0;
  text-align: center;
`;

export const Properties = styled(function({ className, children }) {
  return (
    <div className={className}>
      {React.Children.map(children, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </div>
  );
})`
  margin: 0 -15px;
  overflow: hidden;
  width: 100%;

  > div {
    box-sizing: border-box;
    float: left;
    padding: 0 15px;
    width: 50%;
  }
`;

export const Input = styled.input`
  ${reset()};
  ${mixin("font.default", 14, 2)};
  border-bottom: 1px solid ${get("color.grey")};
  border-radius: 0;
  color: ${get("color.text")};
  margin: ${get("spacing.paddingHalf")} 0;
`;

export const FormActions = styled.div`
  margin-top: 50px;
  text-align: center;
`;

export const FormSubmit = styled.button`
  ${reset()};
  ${get("font.button")};
  background: ${get("color.primary")};
  border-radius: 0;
  color: ${get("color.white")};
  padding: ${get("spacing.paddingHalf")} ${get("spacing.paddingDouble")};
  text-align: center;
  text-decoration: none;
  transition: all ${get("transition.default")};

  &:hover {
  }
`;
