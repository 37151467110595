import React from "react";
import withRouter from "react-router-dom/withRouter";

import {Button, Link} from './style';

export default withRouter(function BackButton({ history, to }) {
  return to ? (
    <Link to={to}>Retour</Link>
  ) : (
    <Button onClick={history.goBack}>Retour</Button>
  );
});
