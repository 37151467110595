import React from "react";

import { media } from "../../util";

import { Container } from "./style";

export default function BackgroundImage({
  media: mediaObj,
  size = "original",
  ...props
}) {
  if (!mediaObj) return null;
  return <Container src={media(mediaObj, size)} {...props} />;
}
