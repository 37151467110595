import React from "react";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";
import Image from "react-image";

import { get, reset, responsiveImg } from "../../css/util";

export const CollectionsBlock = styled(function({ children, ...props }) {
  return (
    <nav {...props}>
      {React.Children.map(children, (child, index) => (
        <div key={index}>{child}</div>
      ))}
    </nav>
  );
})`
  overflow: hidden;
  width: 100%;

  > div {
    box-sizing: border-box;
    float: left;
    padding: ${get("spacing.paddingHalf")} ${get('spacing.paddingDouble')};
    width: 50%;
  }
`;

export const CollectionsLink = styled(function({ image, children, ...props }) {
  return (
    <NavLink {...props}>
      {!!image && (
        <figure>
          <Image src={image} alt={children} />
        </figure>
      )}
      <span>{children}</span>
    </NavLink>
  );
})`
  ${get("style.link")};
  ${get("font.home.headline")};
  display: block;
  margin-left: ${get("spacing.paddingDouble")};

  &:first-child {
    margin-left: 0;
  }

  > figure {
    ${reset()};
    img {
      ${responsiveImg()};
      width: 100%;
    }
  }

  > span {
    ${get("font.home.title")};
    display: block;
    padding: ${get("spacing.paddingHalf")} 0;
  }
`;
