import React from "react";
import Image from "../Image";

import { Icon } from "./style";

export default function CategoryIcon({icon}) {
  return (
    <Icon>
      <Image media={icon} size="icon" />
    </Icon>
  );
}
