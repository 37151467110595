import gql from "graphql-tag";

export const memberValidateMutation = gql`
  mutation MemberValidate($member: MemberValidateInput) {
    tokens: memberValidate(data: $member) {
      access_token
      expires_in
      refresh_token
    }
  }
`;

export const memberRefreshMutation = gql`
  mutation MemberRefreshToken($refreshToken: String) {
    tokens: memberRefreshToken(refreshToken: $refreshToken) {
      access_token
      expires_in
      refresh_token
    }
  }
`;

export const cartSaveAsDownloadedMutation = gql`
  mutation CartSaveAsDownloaded($cart: CartInput) {
    cartSaveAsDownloaded(data: $cart) {
      id
      customer
      member {
        id
        name
      }
    }
  }
`;

export const saveCartMutation = gql`
  mutation CartSave($cart: CartInput) {
    cartSave(data: $cart) {
      id
      customer
      member {
        id
        name
      }
    }
  }
`;
