import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";

import {mixin, get} from "../../css/util";

export const Button = styled.button`
  ${get("style.link")};
  ${mixin("font.bold", 12)};
  background: transparent 3px center / 14px 14px
    url("/images/back-arrow.svg") no-repeat;
  color: ${get('color.link.hover')};
  display: inline-block;
  line-height: 16px;
  padding-left: 24px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ${get("transition.link")};
  vertical-align: middle;

  &:hover {
    background-position: 0px center;
  }
`;

export const Link = Button.withComponent(NavLink);