import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { reset, responsiveImg, get, media } from "../../css/util";

export const Modal = styled(function ({ className, ...props }) {
  return (
    <ReactModal
      overlayClassName={className}
      closeTimeoutMS={250}
      className={"Content"}
      {...props}
    />
  );
})`
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 9999;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }

  .Content {
    background-color: ${get("color.white")};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
    left: 10%;
    max-height: calc(100vh - 72px);
    top: 50%;
    padding: 0;
    position: absolute;
    right: 10%;
    text-align: center;
    transform: translateY(-50%);
    ${media.largeWidth`
    left: 15%;
    right: 15%;
    `};
    outline: none;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: scale(0.5) translateY(-50%);
    transition: all 350ms ease;
    &--after-open {
      opacity: 1;
      transform: scale(1) translateY(-50%);
    }
    &--before-close {
      transition: all 150ms ease;
    }
  }
`;

export const CloseButton = styled.button`
  ${reset()};
  ${get("font.button")};
  background: ${get("color.white")};
  bottom: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
  color: ${get("color.primary")};
  right: 0;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
`;

export const Image = styled(function ({ className, alt, ...props }) {
  return (
    <figure className={className}>
      <img alt={alt} {...props} />
    </figure>
  );
})`
  ${reset()};
  background: ${get("color.background")};
  display: block;
  line-height: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  z-index: 10;
  text-align: center;
  img {
    ${responsiveImg()};
    max-height: calc(100vh - 144px);
    line-height: 0;
    width: auto !important;
  }
`;
