import React from "react";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";

import { get, reset, responsiveImg, truncate } from "../../css/util";

export const Link = styled(NavLink)`
  ${get("style.link")};
`;

export const Figure = styled.figure`
  ${reset()};
  img {
    ${responsiveImg()};
    display: block;
    min-height: 0;
    min-width: 0;
  }
`;

export const Title = styled(function({ prefix, children, ...props }) {
  return (
    <span {...props}>
      {prefix} <strong>{children}</strong>
    </span>
  );
})`
  ${get("font.home.slider")};
  ${truncate()};
  display: block;
  padding: ${get("spacing.paddingHalf")} 0;
  > strong {
    font-weight: ${get("fontWeight.default.bold")};
  }
`;
