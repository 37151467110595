import React from "react";
import { withProps } from "recompose";
import styled, { css } from "styled-components";

import {
  StandardsList as StandardsListOrigin,
  StandardIcon
} from "../Collection/style";

import { get, mixin, toggle, reset } from "../../css/util";

export const Layout = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  justify-content: stretch;
`;

export const AttributeButton = styled(function({
  disabled,
  isActive,
  isSecondary,
  ...props
}) {
  if (disabled) return null;
  return <button type="button" {...props} />;
})`
  ${reset()};
  position: relative;
  ${toggle(
    "isSecondary",
    css`
      color: ${get("color.configuration.attribute.secondary")};
      opacity: 0.33;
    `
  )};
`;

export const AttributeButtonColor = AttributeButton.withComponent(
  function AttributeButtonColor({ disabled, isActive, isSecondary, ...props }) {
    if (disabled) return null;
    return <button type="button" {...props} />;
  }
).extend`
  background: ${get("color.background")};
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  height: 32px;
  margin: ${get("spacing.padding")} ${get("spacing.padding")} 0 0;
  text-align: center;
  width: 32px;

  ${toggle(
    "isActive",
    css`
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.33);
      &:after {
        border: 4px solid ${get("color.background")};
        bottom: 0;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 8;
      }
    `
  )};
`;

export const AttributeButtonTag = AttributeButton.withComponent(
  function AttributeButtonTag({
    disabled,
    isActive,
    isSecondary,
    children,
    ...props
  }) {
    if (disabled) return null;
    return (
      <button type="button" {...props}>
        <span>{children}</span>
      </button>
    );
  }
).extend`
  ${reset()};
  ${get("font.configurator.attributes.tag")};
  background: ${get("color.background")};
  border: 1px solid ${get("color.line")};
  box-sizing: border-box;
  color: ${get("color.text")};
  display: block;
  margin: ${get("spacing.padding")} 0 0 0;
  text-align: center;
  width: 100%;
  
  > span {
    ${reset()};
    border: 4px solid ${get("color.background")};
    box-sizing: border-box;
    display: block;
    width: 100%;
  
    ${toggle(
      "isActive",
      css`
        border: 1px solid ${get("color.line")};
      `
    )};
  }
  
  ${toggle(
    "isActive",
    css`
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.33);
      border: 4px solid ${get("color.background")};
    `
  )};
`;

export const PreviewBlock = styled.div`
  background: ${get("color.collection.background")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-basis: 50%;
  height: 100%;
  padding: ${get("spacing.paddingDouble")};
  position: relative;
  overflow: auto;
  text-align: center;
`;

export const ConfigBlock = styled(function({ footer, children, ...props }) {
  return (
    <section {...props}>
      <main>{children}</main>
      {!!footer && <footer>{footer}</footer>}
    </section>
  );
})`
  background: ${get("color.background")};
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-basis: 50%;
  flex-direction: column;
  height: 100%;
  justify-content: stretch;
  overflow: auto;
  padding: ${get("spacing.paddingDouble")};
  text-align: center;

  > main {
    flex: 1;
  }
  > footer {
    flex: 0;
    margin-top: ${get("spacing.paddingDouble")};
    text-align: right;
  }
`;

export const TopMenu = styled(function({ children, ...props }) {
  return (
    <nav {...props}>
      <div>{children}</div>
    </nav>
  );
})`
  display: inline-block;
  flex: 0 0 auto;
  margin: 0 auto;
  margin-bottom: ${get("spacing.padding")};
  max-width: 100%;
  overflow: auto;
  padding: 0;
  position: relative;
  text-align: center;

  > div {
    white-space: nowrap;

    &:before {
      border-bottom: ${get("style.line.thin")};
      bottom: 1px;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
    }
  }
`;

export const TopMenuButton = AttributeButton.extend`
  ${reset()};
  ${get("font.configurator.topmenu.normal")};
  box-sizing: border-box;
  color: ${get("color.text")};
  display: inline-block;
  margin-left: ${get("spacing.padding")};
  margin-bottom: 3px;
  padding-bottom: ${get("spacing.padding")};
  text-align: center;

  ${toggle(
    "isActive",
    css`
      ${get("font.configurator.topmenu.active")};
      border-bottom: ${get("style.line.bold")};
      margin-bottom: 0;
    `
  )};

  &:first-child {
    margin-left: 0;
  }
`;

export const Part = styled(function Part({
  title,
  variant,
  products,
  children,
  ...props
}) {
  return (
    <div {...props}>
      {!!title && <h3>{title}</h3>}
      <div className="infos">
        {!!products && <div className="products">{products}</div>}
        <div className="properties">
          {children}

          {!!variant &&
            !!variant.message && (
            <div className={`message message--${variant.messageType}`}>
              {variant.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
})`
  text-align: left;

  > h3 {
    ${reset()};
    ${get("font.configurator.part")};
    color: ${get("color.grey")};
    padding: ${get("spacing.paddingHalf")};
  }

  > .infos {
    display: flex;
    margin: ${get("spacing.padding")} 0;
    flex-direction: row;
    > .products {
      flex: 1;
      padding: 0 ${get("spacing.paddingHalf")};
    }
    > .properties {
      border-left: ${get("style.line.thin")};
      flex: 1;
      min-width: 150px;
      padding: 0 ${get("spacing.paddingDouble")};
    }

    .message {
      ${get("font.configurator.message")};
      color: #ffffff;
      margin-top: ${get("spacing.paddingDouble")};
      padding: ${get("spacing.padding")};
      padding-left: 56px;
      position: relative;

      &::before {
        background-repeat: no-repeat;
        content: "";
        height: 24px;
        left: ${get("spacing.padding")};
        position: absolute;
        top: 13px;
        width: 24px;
      }

      &--notice {
        background: ${get("color.lightGrey")};
        color: ${get("color.grey")};
        &::before {
          background-image: url(/images/ico-notice.svg);
        }
      }

      &--info {
        background: ${get("color.blue")};
        &::before {
          background-image: url(/images/ico-info.svg);
        }
      }

      &--warning {
        background: #a21a3a;
        &::before {
          background-image: url(/images/ico-alerte.svg);
        }
      }
    }
  }
`;

export const ProductButton = AttributeButton.extend`
  ${get("font.configurator.product.normal")};
  display: block;
  margin-bottom: ${get("spacing.padding")};
  text-align: left;
  ${toggle(
    "isActive",
    css`
      ${get("font.configurator.product.active")};
    `
  )};
`;

const ProductName = withProps({ isActive: true })(
  ProductButton.withComponent("span")
);

export const Product = styled(function({ name, variant, className, children }) {
  return (
    <Part
      variant={variant}
      products={name ? <ProductName>{name}</ProductName> : []}
      className={className}
    >
      {children}
    </Part>
  );
})``;

export const ViewDescriptionButton = styled.button`
  ${reset()};
  ${mixin("font.bold", 13)};
  color: ${get("color.text")};
  display: inline-block;
  margin-top: ${get("spacing.paddingHalf")};
  padding: 0;
  text-transform: uppercase;
`;

export const References = styled.div`
  background: rgba(235, 235, 235, 0.75);
  bottom: 0;
  left: 0;
  padding: ${get("spacing.paddingDouble")};
  position: absolute;
  text-align: left;
  z-index: 100;
`;

export const Reference = styled(function Reference({
  part,
  children,
  ...props
}) {
  return (
    <p {...props}>
      <strong>Réf.&nbsp;{part}&nbsp;:</strong> {children}
    </p>
  );
})`
  ${reset()};
  ${get("font.configurator.reference")};
  strong {
    font-weight: ${get("fontWeight.default.bold")};
  }
`;

export const AttributesBlock = styled(function AttributesBlock({
  title,
  subtitle,
  children,
  ...props
}) {
  return (
    <div {...props}>
      <h4>
        {title}&nbsp;: {!!subtitle && <span>{subtitle}</span>}
      </h4>
      <div>{children}</div>
    </div>
  );
})`
  text-align: left;

  > h4 {
    ${reset()};
    ${get("font.configurator.attributes.headline")};

    > span {
      ${reset()};
      ${get("font.configurator.attributes.subheadline")};
    }
  }

  & + & {
    padding-top: ${get("spacing.paddingDouble")};
  }
`;

export const ConfigFooter = styled.div`
  align-items: end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AddToCartButton = styled.button`
  ${get("style.button")};
  vertical-align: middle;
  white-space: nowrap;

  svg {
    display: inline-block;
    margin: -0.5em 0;
  }
`;

export const KnowMoreButton = AddToCartButton;

export const StandardsList = StandardsListOrigin.extend`
  bottom: ${get("spacing.paddingDouble")};
  right: ${get("spacing.padding")};
  top: auto;
  z-index: 100;

  li:last-child {
    // button
    margin-bottom: 0;
  }
`;

export { StandardIcon };

export const ViewStandardsButton = styled.button`
  ${get("style.button")};
  pointer-events: all;
`;
