import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import _flatten from "lodash/flatten";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _map from "lodash/map";
import _pickBy from "lodash/pickBy";
import _uniq from "lodash/uniq";
import { Helmet } from "react-helmet";

import loading from "../../hoc/loading";
import LeftColumnLayout from "../../layouts/LeftColumn";
import BackButton from "../../components/BackButton";
import Collection from "../../components/Collection";
import BackgroundImage from "../../components/BackgroundImage";
import Image from "../../components/Image";
import CategoryIcon from "../../components/CategoryIcon";
import {
  CategoryTitle,
  CollectionLink,
  CollectionsList,
  CollectionsListLink,
  CollectionsSlider,
  ProductTypeLink,
  ProductTypesList,
  ProductTypeTitle
} from "./style";
import { categoryQuery } from "../../graphql/queries";

const sliderSettings = {
  arrows: false,
  dots: true,
  rows: 1,
  slidesPerRow: 3
};

export default compose(
  graphql(categoryQuery, {
    options: ({ match: { params: { category: slug } } }) => ({
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network",
      variables: { slug }
    }),
    props: ({ data: { loading, category = {} } }) => {
      return {
        loading,
        category
      };
    }
  }),
  loading()
)(function Category({ category, match: { params: { kind, collection } } }) {
  const productKindsData = {
    clothing: `Vêtements`,
    carpet: `Tapis`,
    linen: `Linges`,
    accessory: `Accessoires`
  };
  const existingProductKinds = _uniq(
    _flatten(_map(category.collections, "kinds"))
  );
  const productKinds = _map(
    _pickBy(
      productKindsData,
      (name, kind) => existingProductKinds.indexOf(kind) !== -1
    ),
    (name, id) => ({ id, name })
  );

  const activeProductKind = kind
    ? { id: kind, name: productKindsData[kind] }
    : null;
  const collectionsForProductKind = kind
    ? _filter(category.collections, ({ kinds }) => kinds.indexOf(kind) !== -1)
    : [];

  const activeCollection = collection
    ? _find(category.collections, ({ slug }) => slug === collection)
    : null;

  return (
    <LeftColumnLayout
      column={
        <Fragment>
          <BackButton
            to={!activeCollection ? `/metiers` : `/metiers/${category.slug}`}
          />
          <CategoryTitle
            icon={!!category.icon && <CategoryIcon icon={category.icon} />}
          >
            {category.name}
          </CategoryTitle>
          {!activeCollection ? (
            <ProductTypesList>
              {productKinds.map(({ id, name }) => (
                <ProductTypeLink
                  key={id}
                  to={`/metiers/${category.slug}/${id}`}
                  hasProductKindActive={!!activeProductKind}
                >
                  {name}
                </ProductTypeLink>
              ))}
            </ProductTypesList>
          ) : (
            <Fragment>
              <ProductTypeTitle>{activeProductKind.name}</ProductTypeTitle>
              <CollectionsList>
                {_map(
                  _filter(
                    category.collections,
                    ({ kinds }) => kinds.indexOf(kind) !== -1
                  ),
                  ({ id, slug, name }) => (
                    <CollectionsListLink
                      key={id}
                      prefix="Collection"
                      hasCollectionActive={!!activeCollection}
                      to={`/metiers/${category.slug}/${kind}/${slug}`}
                    >
                      {name}
                    </CollectionsListLink>
                  )
                )}
              </CollectionsList>
            </Fragment>
          )}
        </Fragment>
      }
    >
      {!kind &&
        category.background && (
          <Fragment>
            <Helmet>
              <title>{category.name}</title>
            </Helmet>
            <BackgroundImage
              media={category.background}
              size="category-background"
            />
          </Fragment>
        )}
      {!!kind &&
        !activeCollection && (
          <Fragment key={activeProductKind.id}>
            <Helmet>
              <title>{`${activeProductKind.name} de ${category.name}`}</title>
            </Helmet>
            <CollectionsSlider
              {...sliderSettings}
              slidesPerRow={Math.min(
                collectionsForProductKind.length,
                sliderSettings.slidesPerRow
              )}
            >
              {_map(
                collectionsForProductKind,
                ({ id, name, slug, listView }) => (
                  <CollectionLink
                    key={id}
                    prefix="Collection"
                    to={`/metiers/${category.slug}/${kind}/${slug}`}
                    image={
                      listView && (
                        <Image media={listView} size="collection-list" />
                      )
                    }
                  >
                    {name}
                  </CollectionLink>
                )
              )}
            </CollectionsSlider>
          </Fragment>
        )}
      {!!activeCollection && (
        <Fragment>
          <Helmet>
            <title>{`Collection ${activeCollection.name} dans ${
              category.name
            }`}</title>
          </Helmet>
          <Collection
            key={activeCollection.id}
            slug={activeCollection.slug}
            kind={kind}
          />
        </Fragment>
      )}
    </LeftColumnLayout>
  );
});
