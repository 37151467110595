import "regenerator-runtime/runtime";
import React from "react";
import { compose, withStateHandlers, withHandlers } from "recompose";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import _omit from "lodash/omit";
import { Helmet } from "react-helmet";

import authService from "../../stores/auth";
import AccountIcon from "../../components/icons/AccountIcon";

import {
  Container,
  Title,
  TitleIcon,
  Form,
  Properties,
  Input,
  FullName,
  FormActions,
  FormSubmit
} from "./style";
import _pick from "lodash/pick";

const memberUpdateMutation = gql`
  mutation MemberUpdate($member: MemberValidateInput) {
    memberUpdate(data: $member) {
      id
    }
  }
`;

export default compose(
  withRouter,
  connect(
    ({ auth }) => auth,
    dispatch => ({
      updateMemberInStore: user => dispatch(authService.updateUser(user))
    })
  ),
  withStateHandlers(
    ({ user }) => ({
      form: {
        name: "Anonyme",
        company: "",
        job: "",
        phone: "",
        ..._omit(user, ["id", "__typename"])
      }
    }),
    {
      setFormValue: ({ form }) => evt => ({
        form: { ...form, [evt.target.name]: evt.target.value }
      })
    }
  ),
  graphql(memberUpdateMutation, {
    props: ({ mutate: memberUpdate, ownProps: { user } }) => ({
      memberUpdateOnServer: async memberData => {
        const member = {
          ..._pick(user, ["googleId", "email"]),
          ...memberData
        };
        try {
          await memberUpdate({ variables: { member } });
          return true;
        } catch (err) {
          console.error(err);
          return false;
        }
      }
    })
  }),
  withHandlers({
    getInputProps: ({ form, setFormValue }) => name => {
      return {
        name,
        value: form[name],
        onChange: setFormValue
      };
    },
    submitForm: ({
      form,
      memberUpdateOnServer,
      updateMemberInStore,
      history
    }) => async evt => {
      evt.preventDefault();
      const savedOnline = await memberUpdateOnServer(form);
      setTimeout(() => updateMemberInStore(form), 100);
      history.push("/", {
        messages: {
          success: `Les informations de compte ont été enregistrées${
            savedOnline ? " en ligne" : " sur votre appareil seulement"
          } !`
        }
      });
    }
  })
)(function Account({ user, getInputProps, submitForm }) {
  return (
    <Container>
      <Helmet>
        <title>Mon compte</title>
      </Helmet>
      <Title
        icon={
          <TitleIcon>
            <AccountIcon />
          </TitleIcon>
        }
      >
        Mon compte
      </Title>
      <Form onSubmit={submitForm}>
        <FullName>{user.name}</FullName>
        <Properties>
          <Input
            type="text"
            placeholder="Agence"
            {...getInputProps("company")}
          />
          <Input type="text" placeholder="Fonction" {...getInputProps("job")} />
        </Properties>
        <Properties>
          <Input
            type="text"
            placeholder="Téléphone"
            {...getInputProps("phone")}
          />
        </Properties>
        <FormActions>
          <FormSubmit type="submit">Valider</FormSubmit>
        </FormActions>
      </Form>
    </Container>
  );
});
