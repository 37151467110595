import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import _map from "lodash/map";

import loading from "../../hoc/loading";

import Image from "../../components/Image";

import { CollectionsSlider, CollectionLink } from "./style";
import { featuredCollectionsQuery } from "../../graphql/queries";

const sliderSettings = {
  arrows: false,
  dots: true,
  rows: 1,
  slidesPerRow: 3
};

export default compose(
  graphql(featuredCollectionsQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({ data: { collections, loading } }) => {
      return { collections, loading };
    }
  }),
  loading()
)(function FeaturedCollections({ collections = [] }) {
  return (
    <Fragment>
      <Helmet>
        <title>Dernières collections</title>
      </Helmet>
      <CollectionsSlider
        {...sliderSettings}
        slidesPerRow={Math.min(collections.length, sliderSettings.slidesPerRow)}
      >
        {_map(collections, ({ id, name, slug, listView }) => (
          <CollectionLink
            key={id}
            prefix="Collection"
            to={`/focus/${slug}`}
            image={
              listView && <Image media={listView} size="collection-list" />
            }
          >
            {name}
          </CollectionLink>
        ))}
      </CollectionsSlider>
    </Fragment>
  );
});
