import React from "react";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";
import NavLink from "react-router-dom/NavLink";

import { reset, get, mixin, toggle } from "../../css/util";

export const Modal = styled(function({ className, ...props }) {
  return (
    <ReactModal
      overlayClassName={className}
      closeTimeoutMS={250}
      className={"Content"}
      {...props}
    />
  );
})`
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 72px;
  z-index: 100;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }

  .Content {
    background-color: ${get("color.white")};
    bottom: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
    left: 0;
    max-height: 100%;
    overflow: auto;
    padding: 0;
    position: absolute;
    top: 0;
    width: 300px;
  }

  .ReactModal__Content {
    transform: translateX(-100%);
    transition: transform 350ms ease;
    &--after-open {
      transform: translateX(0%);
    }
    &--before-close {
      transform: translateX(-100%);
      transition: transform 150ms ease;
    }
  }
`;

export const CloseButton = styled.button`
  ${reset()};
  ${get("font.button")};
  background: ${get("color.white")};
  bottom: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
  color: ${get("color.primary")};
  left: 50%;
  padding: 5px 15px;
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
`;

export const MenuList = styled(function({ children, ...props }) {
  return (
    <ul {...props}>
      {React.Children.map(children, (child, index) => (
        <li key={index}>{child}</li>
      ))}
    </ul>
  );
})`
  ${reset()};
  padding: ${get("spacing.padding")} ${get("spacing.paddingDouble")};
`;

export const Link = styled(NavLink)`
  ${get("style.link")};
  ${get("font.mainMenu.firstLevel")};
  color: ${get("color.primary")};
  display: block;
  margin: ${get("spacing.padding")} 0 ${get("spacing.paddingHalf")} 0;
  &:after {
    border-bottom: ${get("style.line.thin")};
    content: "";
    display: block;
    margin-top: ${get("spacing.paddingHalf")};
    width: 33%;
  }
`;

export const SubLink = styled(NavLink)`
  ${get("style.link")};
  ${get("font.mainMenu.secondLevel")};
  color: ${get("color.primary")};
  cursor: pointer;
  display: block;
  padding: 0.25em 0;

  ${toggle(
    "disabled",
    css`
      opacity: 0.75;
    `
  )};
`;

export const Button = SubLink.withComponent("a");

export const OfflineAlert = styled.span`
  ${mixin("font.bold", 10, 1)};
  background: ${get("color.warning")};
  border-radius: 3px;
  color: ${get("color.white")};
  margin-left: 0.5em;
  padding: 3px 6px;
  text-align: center;

  &:before {
    content: "\u26A0 ";
  }
`;
