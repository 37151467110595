import React from "react";
import { Helmet } from "react-helmet";

import BackButton from "../../../components/BackButton";

import {
  Article,
  Main,
  Header,
  Headline,
  Title,
  Subtitle,
  Baseline,
  Blocks,
  Block,
  Strong,
  Text,
  Image,
  Paragraph,
  KpiBlocks,
  KpiBlock
} from "./style";
import imageBackground from "./images/background.jpg";
import imageExperts from "./images/experts.jpg";
import imageMethodologie from "./images/methodologie.jpg";
import imageEssais from "./images/essais.jpg";

export default function() {
  return (
    <Article background={imageBackground} backButton={<BackButton to="/" />}>
      <Helmet>
        <title>L’innovation textile</title>
      </Helmet>
      <Header>
        <Headline
          icon={
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 310.4 400"
            >
              <g fill="#007dc5">
                <path
                  d="M221.3,100.1c-18.9-18.2-43.7-27.5-69.9-26.6c-50.3,2-91.4,44.6-91.5,95c-0.1,22.3,7.8,44.1,22.1,61.2
		c20.6,24.8,32,56.1,32,88.2c0,0-2.2,14.7,8.3,14.7h65.9c10.7,0,8.3-14.7,8.3-14.7c0-31.4,11.5-62.4,33.4-89.8
		c13.5-17,20.7-37.5,20.7-59.3C250.6,142.6,240.2,118.2,221.3,100.1L221.3,100.1z M216.8,217.7c-23.8,29.8-36.5,63.7-37,98.2h-49.3
		c-0.5-35.3-13.1-69.6-35.8-96.9C83,204.9,76.5,187,76.6,168.6c0.2-41.6,34-76.7,75.5-78.4c21.7-0.8,42.2,7,57.7,21.9
		c15.6,15,24.2,35.1,24.2,56.7C233.8,186.8,228,203.7,216.8,217.7L216.8,217.7z M216.8,217.7"
                />
                <path
                  d="M265,45.4c-3.3-3.3-8.6-3.3-11.8,0l-20.3,20.3c-3.3,3.3-3.3,8.6,0,11.8c3.3,3.2,8.6,3.3,11.8,0L265,57.2
		C268.2,54,268.2,48.7,265,45.4L265,45.4z M265,45.4"
                />
                <path
                  d="M188.5,343.9h-66.6c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3h66.6c4.6,0,8.3-3.8,8.3-8.3
		C196.8,347.7,193,343.9,188.5,343.9L188.5,343.9z M188.5,343.9"
                />
                <path
                  d="M175.9,375.3h-41.4c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3h41.4c4.6,0,8.3-3.8,8.3-8.3
		C184.2,379,180.5,375.3,175.9,375.3L175.9,375.3z M175.9,375.3"
                />
                <path
                  d="M155.2,45.4c4.6,0,8.3-3.8,8.3-8.3V8.3c0-4.6-3.8-8.3-8.3-8.3c-4.6,0-8.3,3.8-8.3,8.3V37
		C146.9,41.7,150.6,45.4,155.2,45.4L155.2,45.4z M155.2,45.4"
                />
                <path
                  d="M77.5,77.6c3.3-3.3,3.3-8.6,0-11.8L57.2,45.5c-3.3-3.3-8.6-3.3-11.8,0c-3.2,3.3-3.3,8.6,0,11.8l20.3,20.3
		C69,80.8,74.3,80.8,77.5,77.6L77.5,77.6z M77.5,77.6"
                />
                <path
                  d="M302.1,146.9h-28.7c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3h28.7c4.6,0,8.3-3.8,8.3-8.3
		C310.4,150.6,306.6,146.9,302.1,146.9L302.1,146.9z M302.1,146.9"
                />
                <path
                  d="M37,146.9H8.3c-4.6,0-8.3,3.8-8.3,8.3c0,4.6,3.8,8.3,8.3,8.3H37c4.6,0,8.3-3.8,8.3-8.3
		C45.4,150.6,41.7,146.9,37,146.9L37,146.9z M37,146.9"
                />
              </g>
            </svg>
          }
        >
          <Title>L’innovation textile</Title>
          <Subtitle>Au cœur de notre démarche</Subtitle>
        </Headline>
      </Header>
      <Main>
        <Baseline>
          Notre objectif&nbsp;? Vous offrir toujours <br />plus de confort, de
          style et de sécurité&nbsp;!
        </Baseline>
        <Blocks>
          <Block>
            <Text>
              <Strong>Nos experts à votre service</Strong>
              <Paragraph>
                Notre <strong>cabinet de style</strong> intégré,{" "}
                <strong>basé en France</strong> et plus précisément à
                Strasbourg, se compose d'ingénieurs textiles{" "}
                <strong>à la pointe de l'expertise</strong>, et de spécialistes
                marché <strong>pour chaque secteur d'activité</strong>.
              </Paragraph>
            </Text>
            <Image src={imageExperts} alt="Illustration des experts" />
          </Block>
          <Block>
            <Image
              src={imageMethodologie}
              alt="Illustration de la méthodologie"
            />
            <Text>
              <Strong>Des méthodologies de tests produits rigoureux</Strong>
              <Paragraph>
                <strong>Tous nos articles sont testés</strong> dans nos process
                d'entretien industriel selon un protocole de test{" "}
                <strong>très strict</strong> pour vous{" "}
                <strong>garantir une qualité pérenne</strong> de l'ensemble de
                nos articles.
              </Paragraph>
            </Text>
          </Block>
          <Block>
            <Text>
              <Strong>Des essais au porté réguliers</Strong>
              <Paragraph>
                <strong>
                  Des tests au porté de nos tenues professionnelles sont
                  réalisés régulièrement
                </strong>{" "}
                et en condition réelle d'utilisation afin de vous assurer un
                confort et une fonctionnalité optimale.{" "}
              </Paragraph>
            </Text>
            <Image src={imageEssais} alt="Illustration des essais" />
          </Block>
        </Blocks>
        <KpiBlocks title="Chiffres clés">
          <KpiBlock figure="6 ingénieurs">
            textiles et habillement basés en France
          </KpiBlock>
          <KpiBlock figure="1 cabinet">de style basé à Strasbourg</KpiBlock>
          <KpiBlock figure="+ 500">nouveaux articles développés / an</KpiBlock>
          <KpiBlock figure="+ 450 000">
            tests de lavage matières et articles réalisés / an
          </KpiBlock>
          <KpiBlock figure="Remise DAO">sous 48 heures</KpiBlock>
        </KpiBlocks>
      </Main>
    </Article>
  );
}
