import React from "react";
import styled, { css } from "styled-components";
import NavLink from "react-router-dom/NavLink";

import Slider from "../../components/Slider";
import CollectionLinkOriginal from "../../components/CollectionLink";
import { CollectionsList, CollectionsListLink } from "../CollectionsKind/style";
import { get, reset, media } from "../../css/util";

export const CategoryTitle = styled(function({ icon, children, ...props }) {
  return (
    <div {...props}>
      {icon}
      <h1>{children}</h1>
    </div>
  );
})`
  margin: ${get("spacing.paddingDouble")} 0;

  > h1 {
    ${reset()};
    ${get("font.headline")};
    margin: ${get("spacing.padding")};
    text-align: center;
  }
`;

export const ProductTypeTitle = styled.h2`
  ${reset()};
  ${get("font.subheadline")};
  margin: ${get("spacing.padding")};
  margin-top: -${get("spacing.padding")};
  text-align: center;
`;

export const ProductTypesList = styled.nav`
  padding: ${get("spacing.padding")} 0;
`;

export const ProductTypeLink = styled(function ProductTypeLink({
  hasProductKindActive,
  ...props
}) {
  return <NavLink {...props} />;
})`
  ${get("style.link")};
  ${get("font.menu.normal")};
  display: block;
  letter-spacing: 0;
  margin-left: 1px;
  padding: ${get("spacing.padding")};
  padding-left: calc(${get("spacing.padding")} + 2px);
  text-align: center;
  ${({ hasProductKindActive, theme }) =>
    !!hasProductKindActive &&
    css`
      border-left: ${theme.style.line.thin};
      padding-left: calc(${get("spacing.padding")} + 1px);
    `};

  &.active {
    ${get("font.menu.active")};
    border-left: ${get("style.line.bold")};
    margin-left: 0px;
    letter-spacing: 0;
    padding-left: ${get("spacing.padding")};
  }
`;

export const CollectionsSlider = styled(function({
  className,
  children,
  ...props
}) {
  return (
    <div className={className}>
      <div>
        <Slider {...props}>{children}</Slider>
      </div>
    </div>
  );
})`
  height: 100%;
  margin: -${get("spacing.padding")};

  ${media.largeWidth`
    margin-left: -15%;
    margin-right: -15%;
  `};

  > div {
    box-sizing: border-box;
    height: 580px;
    margin: -${580 / 2}px auto;
    position: relative;
    top: 50%;
    width: ${({ theme, children }) =>
      (240 + theme.spacing.standard) * Math.min(children.length, 3)}px;
    ${media.bigHeight`
    width: ${({ theme, children }) =>
      (260 + theme.spacing.standard) * Math.min(children.length, 3)}px;
    `};
  }

  .slick-slide {
    text-align: center;
  }
`;

export const CollectionLink = styled(CollectionLinkOriginal)`
  box-sizing: border-box;
  padding: ${get("spacing.paddingHalf")};

  figure > img {
    width: 240px;
    ${media.bigHeight`
      width: 260px;
    `};
  }
`;

export { CollectionsListLink, CollectionsList };
