import _chunk from "lodash/chunk";
import _filter from "lodash/filter";
import _intersection from "lodash/intersection";
import _uniq from "lodash/uniq";

import { series } from "./util";

const hasCaches = "caches" in window;

const FILES_LOT_SIZE = 5;

export default async function(
  paths = [],
  cacheName = "files",
  callback = null
) {
  if (!hasCaches) return;

  const cache = await caches.open(cacheName);
  const cleanPaths = _filter(_uniq(paths)).map(encodeURI);
  const total = cleanPaths.length;

  await series(
    _chunk(cleanPaths, FILES_LOT_SIZE).map((filesLot, i) => () =>
      new Promise(function(resolve, reject) {
        try {
          cache.addAll(filesLot);

          let lastLoaded = 0;
          let countIter = 0;
          const evaluateLoading = async function() {
            const keys = await cache.keys();
            const pathsInCache = keys.map(({ url }) => url);
            const loaded = _intersection(pathsInCache, filesLot).length;
            if (lastLoaded !== loaded) {
              lastLoaded = loaded;
              countIter = 0;
            }
            countIter++;
            if (callback) callback(loaded + i * FILES_LOT_SIZE, total);
            if (loaded === filesLot.length) return resolve();
            if (countIter > 50)
              return reject(new Error(`Unable to load all files`)); // Stop if too many iterations without change
            window.setTimeout(evaluateLoading, 200);
          };
          evaluateLoading();
        } catch (err) {
          console.error(err);
          reject(err);
        }
      })
    )
  );
}
