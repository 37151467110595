import React from "react";
import _keyBy from "lodash/keyBy";
import _values from "lodash/values";

import {
  Modal,
  CloseButton,
  Container,
  CollectionTitle,
  Product,
  ProductTitle,
  Variant,
  VariantAttribute,
  VariantDescription,
} from "./style";
import { nl2br } from "../../util";

function validAttributes(properties) {
  return function ({ property }) {
    const prop = properties[property];
    return !!prop && prop.kind !== "sex";
  };
}

function attributeValue(properties, attribute) {
  switch (properties[attribute.property].kind) {
    case "color":
      const colors = _keyBy(properties[attribute.property].colors, "id");
      const color = colors && colors[attribute.value];
      return color ? color.name : "Inconnue";
    case "text":
    default:
      return attribute.value;
  }
}

export default class PopupDescription extends React.Component {
  renderVariant(product) {
    const variant =
      (product && this.props.active.variants[product.id]) ||
      _values(this.props.active.variants)[0];
    if (!variant) return null;
    const properties = _keyBy(product && product.properties, "id");
    return (
      <Variant>
        {variant.attributes
          .filter(validAttributes(properties))
          .map((attribute) => (
            <VariantAttribute
              key={attribute.property}
              label={properties[attribute.property].name}
            >
              {attributeValue(properties, attribute)}
            </VariantAttribute>
          ))}
        {!!variant.reference && (
          <VariantAttribute label="Réf.">{variant.reference}</VariantAttribute>
        )}
        {!!variant.description && (
          <VariantDescription
            dangerouslySetInnerHTML={{ __html: nl2br(variant.description) }}
          />
        )}
      </Variant>
    );
  }

  render() {
    const { collection, active, isOpen, onClose } = this.props;
    if (!collection || !active || !active.products) return null;
    return (
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <CloseButton onClick={onClose}>Fermer</CloseButton>
        <Container>
          <CollectionTitle prefix="Collection">
            {collection.name}
          </CollectionTitle>
          {!active.combination ? (
            <Product>
              <ProductTitle>{_values(active.products)[0].name}</ProductTitle>
              {this.renderVariant(_values(active.products)[0])}
            </Product>
          ) : (
            active.combination.parts.map((combinationPart) => (
              <Product key={combinationPart.id}>
                <ProductTitle>
                  {[
                    combinationPart.name,
                    active.products[combinationPart.id] &&
                      active.products[combinationPart.id].name,
                  ]
                    .filter(Boolean)
                    .join(" - ")}
                </ProductTitle>
                {this.renderVariant(active.products[combinationPart.id])}
              </Product>
            ))
          )}
        </Container>
      </Modal>
    );
  }
}
