import _filter from "lodash/filter";
import _find from "lodash/find";
import _flatten from "lodash/flatten";
import _isEqual from "lodash/isEqual";
import _keyBy from "lodash/keyBy";
import _map from "lodash/map";
import _mapValues from "lodash/mapValues";
import _memoize from "lodash/memoize";
import _minBy from "lodash/minBy";
import _pick from "lodash/pick";
import _uniq from "lodash/uniq";
import _uniqBy from "lodash/uniqBy";
import _values from "lodash/values";
import React, { Fragment } from "react";

import { ProductStandardsInfos } from "../../common/standards";
import { media } from "../../util";
import BigProductImageWithPins from "../BigProductImageWithPins";
import ColorBadge from "../ColorBadge";
import CartIcon from "../icons/CartIcon";
import PopupCarousel from "../PopupCarousel";
import PopupDescription from "../PopupDescription";
import PopupImage from "../PopupImage";
import PopupStandards from "../PopupStandards";
import {
  AddToCartButton,
  AttributeButtonColor,
  AttributeButtonTag,
  AttributesBlock,
  ConfigBlock,
  ConfigFooter,
  KnowMoreButton,
  Layout,
  Part,
  PreviewBlock,
  Product,
  ProductButton,
  Reference,
  References,
  StandardIcon,
  StandardsList,
  TopMenu,
  TopMenuButton,
  ViewDescriptionButton,
  ViewStandardsButton,
} from "./style";

function toAttributesObject(attributes) {
  return _mapValues(_keyBy(attributes, "property"), "value");
}

/**
 * Returns the distance between two objects (0 = equals, 1 = one property/value diff, etc.)
 *
 * @param objectA
 * @param objectB
 * @return {number}
 */
function distanceBetweenObjects(objectA, objectB) {
  return (
    Object.keys(objectA).filter((key) => objectA[key] !== objectB[key]).length +
    Object.keys(objectB).filter((key) => objectA[key] !== objectB[key]).length
  );
}

function firstValue(obj) {
  const values = _values(obj);
  return values && values.length ? values[0] : null;
}

const EMPTY_COMBINATION_PART_ID = "-";

const defaultActiveState = {
  sex: null, // sex if present
  combination: null, // combination obj
  products: {}, // key = combination.parts.id ; value = product obj
  variants: {}, // key = product.id ; value = variant obj
};

function getActiveStateWithCombination(
  combination = null,
  active = { sex: null, combination: null, products: {}, variants: {} }
) {
  const products = combination
    ? _mapValues(
        _keyBy(
          _map(combination.parts, ({ id: part, products }) => ({
            part,
            product: firstValue(products),
          })),
          "part"
        ),
        "product"
      )
    : {};
  const variants = products
    ? _mapValues(
        _keyBy(
          _map(_filter(products), ({ id: product, variants }) => ({
            product,
            variant: variants[0],
          })),
          "product"
        ),
        "variant"
      )
    : {};
  return {
    ...active,
    combination,
    products,
    variants,
  };
}

function getActiveStateWithProduct(
  product,
  partId = EMPTY_COMBINATION_PART_ID,
  active = { sex: null, combination: null, products: {}, variants: {} }
) {
  const combination =
    partId === EMPTY_COMBINATION_PART_ID ? null : active.combination;
  const products =
    partId === EMPTY_COMBINATION_PART_ID
      ? { [partId]: product }
      : {
          ...active.products,
          [partId]: product,
        };
  const variants = products
    ? _mapValues(
        _keyBy(
          _map(_filter(products), ({ id: product, variants }) => ({
            product,
            variant:
              (active.variants && active.variants[product]) || variants[0],
          })),
          "product"
        ),
        "variant"
      )
    : {};
  return {
    ...active,
    combination,
    products,
    variants,
  };
}

export default class Configurator extends React.Component {
  state = {
    isStandardsOpen: false,
    isDetailsOpen: false,
    isDescriptionOpen: false,
    isPinImageOpen: false,
    selectedPin: null,
    active: {
      ...defaultActiveState,
    },
  };

  getCombinations = _memoize((products = []) => {
    const productsWithCombinations = _filter(
      products,
      ({ combination, combinationPart }) => combination && combinationPart
    );
    const combinations = _keyBy(
      _map(
        _uniqBy(_map(productsWithCombinations, "combination"), "id"),
        (combination) => ({
          ...combination,
          parts: _map(combination.parts, (part) => ({
            ...part,
            products: _keyBy(
              _filter(
                productsWithCombinations,
                ({ combination: c, combinationPart: cp }) =>
                  c.id === combination.id && cp.id === part.id
              ),
              "id"
            ),
          })),
        })
      ),
      "id"
    );
    return combinations;
  });

  getTopLevelProduct = _memoize((products = []) => {
    const topLevelProducts = _keyBy(
      _filter(
        products,
        ({ combination, combinationPart }) => !combination || !combinationPart
      ),
      "id"
    );
    return topLevelProducts;
  });

  getSexOptions = _memoize((products = []) => {
    const sexValuesInProducts = _filter(
      _map(products, (product) => {
        const sexProperty = _find(
          product.properties,
          ({ kind }) => kind === "sex"
        );
        if (!sexProperty) return product;
        return {
          ...product,
          sexValues: _map(
            product.variants,
            ({ attributes }) =>
              _find(attributes, { property: sexProperty.id }).value
          ),
        };
      }),
      "sexValues"
    );
    const sexOptions = _uniq(_flatten(_map(sexValuesInProducts, "sexValues")));
    return sexOptions;
  });

  getProductsBySex = _memoize(({ products = [], sex: sexValue }) => {
    const productsBySex = _filter(
      _map(products, (product) => {
        const sexProperty = _find(
          product.properties,
          ({ kind }) => kind === "sex"
        );
        if (!sexProperty) return product; // Un produit sans sex vaut pour tous les sexes
        const variants = _filter(
          product.variants,
          ({ attributes }) =>
            _find(attributes, { property: sexProperty.id }).value === sexValue
        );
        return {
          ...product,
          variants,
        };
      }),
      ({ variants }) => variants.length
    );
    return productsBySex;
  });

  componentWillMount() {
    const sexOptions = this.getSexOptions(this.props.products);
    const sex = sexOptions.length ? sexOptions[0] : null;
    const products = sex
      ? this.getProductsBySex({ products: this.props.products, sex })
      : this.props.products;
    const combinations = this.getCombinations(products);
    const topLevelProducts = this.getTopLevelProduct(products);

    const active = Object.keys(combinations).length
      ? getActiveStateWithCombination(firstValue(combinations), { sex })
      : Object.keys(topLevelProducts).length
      ? getActiveStateWithProduct(
          firstValue(topLevelProducts),
          EMPTY_COMBINATION_PART_ID,
          { sex }
        )
      : {
          ...defaultActiveState,
        };

    this.setState({
      active,
    });
  }

  activateSex = (sex = null) => {
    const products = sex
      ? this.getProductsBySex({ products: this.props.products, sex })
      : this.props.products;
    const combinations = this.getCombinations(products);
    const topLevelProducts = this.getTopLevelProduct(products);

    const active = Object.keys(combinations).length
      ? getActiveStateWithCombination(firstValue(combinations), { sex })
      : Object.keys(topLevelProducts).length
      ? getActiveStateWithProduct(
          firstValue(topLevelProducts),
          EMPTY_COMBINATION_PART_ID,
          { sex }
        )
      : {
          ...defaultActiveState,
        };

    this.setState({
      active,
    });
  };

  activateCombination = (combination = null) => {
    this.setState(({ active }) => ({
      active: getActiveStateWithCombination(combination, active),
    }));
  };

  activateProduct = (product, partId = EMPTY_COMBINATION_PART_ID) => {
    this.setState(({ active }) => ({
      active: getActiveStateWithProduct(product, partId, active),
    }));
  };

  activateVariant = (variant, productId) => {
    this.setState(({ active }) => ({
      active: {
        ...active,
        variants: {
          ...active.variants,
          [productId]: variant,
        },
      },
    }));
  };

  getVariantSeekerForProduct = ({ id: productId, properties, variants }) => {
    const {
      active: { variants: activeVariants = {} },
    } = this.state;

    const propertiesWithOptions = _filter(
      properties,
      ({ kind }) => kind !== "text"
    );
    const chooseablePropertiesId = _map(propertiesWithOptions, "id");

    const activeVariant = activeVariants[productId] || { attributes: [] };
    const activeAttributes = _pick(
      toAttributesObject(activeVariant.attributes),
      chooseablePropertiesId
    );

    const variantsWithAttributesObjects = _map(variants, (variant) => ({
      variant,
      attributes: _pick(
        toAttributesObject(variant.attributes),
        chooseablePropertiesId
      ),
    }));

    const findExactVariantForAttribute = function (property, value) {
      const attributesToFind = { ...activeAttributes, [property]: value };
      const variantObj = _find(
        variantsWithAttributesObjects,
        ({ attributes }) => _isEqual(attributes, attributesToFind)
      );
      if (!variantObj) return null;
      return variantObj.variant;
    };

    const findBestVariantForAttribute = function (property, value) {
      const attributesToFind = { ...activeAttributes, [property]: value };
      const variantObj = _minBy(
        _map(
          // On récupère toutes les variantes avec la bonne valeur pour l'attribut
          _filter(
            variantsWithAttributesObjects,
            ({ attributes }) => attributes[property] === value
          ),
          // On calcule la distance de chaque variante avec la configuration idéale
          (v) => ({
            ...v,
            distance: distanceBetweenObjects(v.attributes, attributesToFind),
          })
        ),
        // On reécupère la variante avec la distance minimale
        "distance"
      );
      if (!variantObj) return null;
      return variantObj.variant;
    };

    return {
      findExactVariantForAttribute,
      findBestVariantForAttribute,
      isActive: (property, value) => activeAttributes[property] === value,
    };
  };

  openStandards = () => {
    this.setState({ isStandardsOpen: true });
  };
  closeStandards = () => {
    this.setState({ isStandardsOpen: false });
  };

  openDetails = () => {
    this.setState({ isDetailsOpen: true });
  };
  closeDetails = () => {
    this.setState({ isDetailsOpen: false });
  };

  openDescription = () => {
    this.setState({ isDescriptionOpen: true });
  };
  closeDescription = () => {
    this.setState({ isDescriptionOpen: false });
  };

  openPinImage = (pin) => {
    this.setState({ selectedPin: pin, isPinImageOpen: true });
  };
  closePinImage = () => {
    this.setState({ isPinImageOpen: false });
  };
  render() {
    const { onAddProduct, collection } = this.props;
    const {
      isStandardsOpen,
      isDetailsOpen,
      isDescriptionOpen,
      isPinImageOpen,
      selectedPin,
      active,
    } = this.state;

    const standardsForProducts = _uniq(
      (_values(active.products) || []).reduce((standards, product) => {
        return product
          ? [...standards, ...(product.standards || [])]
          : standards;
      }, [])
    );

    standardsForProducts.sort(function (a, b) {
      // Clever sort
      const [allA, nameA, numberA] = /^(\w+)\s*(\d+)/.exec(a);
      const [allB, nameB, numberB] = /^(\w+)\s*(\d+)/.exec(b);
      return !!nameA && nameA === nameB
        ? parseInt(numberA || "0", 10) - parseInt(numberB || "0", 10)
        : a.localeCompare(b);
    });

    const standardsInfosForProducts = (standardsForProducts || [])
      .map((standard) => ProductStandardsInfos[standard])
      .filter((standard) => !!standard);

    const pinImages = _values(active.variants).reduce(
      (pinImages, variant) => [
        ...pinImages,
        ...(variant.pins || []).map((pin) => pin.image).filter(Boolean),
      ],
      []
    );

    return (
      <Layout>
        <PreviewBlock>
          {this.renderSex()}
          <BigProductImageWithPins
            onHeight
            withPins
            onSelectPin={(pin) => this.openPinImage(pin)}
            products={_values(active.products)}
            variants={_values(active.variants)}
          />
          <References>
            {_map(_values(active.variants), (variant, partIndex) => (
              <Reference
                key={variant.id}
                part={
                  active.combination && active.combination.parts[partIndex].name
                }
              >
                {variant.reference || "(aucune réf)"}
              </Reference>
            ))}
            <ViewDescriptionButton type="button" onClick={this.openDescription}>
              + d'infos
            </ViewDescriptionButton>
          </References>
          {!!standardsInfosForProducts.length && (
            <StandardsList>
              {standardsInfosForProducts.map((standard) => (
                <StandardIcon key={standard.name} {...standard} />
              ))}
              <ViewStandardsButton onClick={this.openStandards}>
                Les risques
              </ViewStandardsButton>
            </StandardsList>
          )}
          <PopupStandards
            standards={standardsForProducts}
            isOpen={isStandardsOpen}
            onClose={this.closeStandards}
          />
          <PopupDescription
            collection={collection}
            active={active}
            isOpen={isDescriptionOpen}
            onClose={this.closeDescription}
          />
          <PopupCarousel
            images={pinImages}
            selectedSlide={
              selectedPin ? pinImages.indexOf(selectedPin.image) : 0
            }
            isOpen={isPinImageOpen}
            onClose={this.closePinImage}
          />
        </PreviewBlock>
        <ConfigBlock
          footer={
            !!onAddProduct && (
              <ConfigFooter>
                {collection.details ? (
                  <Fragment>
                    <KnowMoreButton type="button" onClick={this.openDetails}>
                      En&nbsp;savoir&nbsp;+
                    </KnowMoreButton>
                    <PopupImage
                      src={media(collection.details, "original")}
                      isOpen={isDetailsOpen}
                      onClose={this.closeDetails}
                    />
                  </Fragment>
                ) : (
                  <span />
                )}

                <AddToCartButton
                  type="button"
                  onClick={() => onAddProduct({ ...active })}
                >
                  <CartIcon size={24} />
                  &nbsp; Sélectionner
                </AddToCartButton>
              </ConfigFooter>
            )
          }
        >
          {this.renderTopLevel()}
          {active.combination && this.renderCombination()}
          {!active.combination &&
            !!Object.keys(active.products).length &&
            this.renderSingleProduct(_values(active.products)[0])}
        </ConfigBlock>
      </Layout>
    );
  }

  renderTopLevel = () => {
    const { active } = this.state;
    const products = active.sex
      ? this.getProductsBySex({
          products: this.props.products,
          sex: active.sex,
        })
      : this.props.products;
    const combinations = this.getCombinations(products);
    const topLevelProducts = this.getTopLevelProduct(products);
    const totalTopLevel =
      Object.keys(combinations).length + Object.keys(topLevelProducts).length;
    return (
      totalTopLevel > 1 && (
        <TopMenu>
          {_map(combinations, (combination) => (
            <TopMenuButton
              key={combination.id}
              onClick={() => this.activateCombination(combination)}
              isActive={
                active.combination && active.combination.id === combination.id
              }
            >
              {combination.name}
            </TopMenuButton>
          ))}
          {_map(topLevelProducts, (product) => (
            <TopMenuButton
              key={product.id}
              onClick={() => this.activateProduct(product)}
              isActive={
                active.products[EMPTY_COMBINATION_PART_ID] &&
                active.products[EMPTY_COMBINATION_PART_ID].id === product.id
              }
            >
              {product.name}
            </TopMenuButton>
          ))}
        </TopMenu>
      )
    );
  };

  renderSex = () => {
    const {
      active: { sex = null },
    } = this.state;

    const sexOptions = this.getSexOptions(this.props.products);
    // Si au moins un produit a une propriété sex, on affiche le menu
    if (!sexOptions.length) return;

    const getButtonPropertiesForAttribute = (value) => {
      return {
        disabled: sexOptions.indexOf(value) === -1,
        isActive: value === sex,
        onClick: () => this.activateSex(value),
      };
    };

    return (
      <TopMenu>
        <TopMenuButton {...getButtonPropertiesForAttribute("male")}>
          Homme
        </TopMenuButton>
        <TopMenuButton {...getButtonPropertiesForAttribute("female")}>
          Femme
        </TopMenuButton>
        <TopMenuButton {...getButtonPropertiesForAttribute("unisex")}>
          Unisexe
        </TopMenuButton>
      </TopMenu>
    );
  };

  renderCombination = () => {
    const {
      active: {
        variants = {},
        combination: { parts = [] },
        products: activeProducts = {},
      },
    } = this.state;

    return (
      (parts.length > 1 || Object.keys(parts[0].products).length > 1) &&
      _map(
        parts,
        ({ id, name, products }) =>
          Object.keys(products).length > 0 && (
            <Part
              key={id}
              title={parts.length > 1 && name}
              variant={
                (activeProducts[id] && variants[activeProducts[id].id]) || {}
              }
              products={
                (parts.length > 1 || Object.keys(products).length > 1) &&
                _map(products, (product) => (
                  <ProductButton
                    key={product.id}
                    onClick={() => this.activateProduct(product, id)}
                    isActive={
                      activeProducts[id] && activeProducts[id].id === product.id
                    }
                  >
                    {product.name}
                  </ProductButton>
                ))
              }
            >
              {!!activeProducts[id] &&
                this.renderProductProperties(activeProducts[id])}
            </Part>
          )
      )
    );
  };

  renderSingleProduct = (product) => {
    let name = product.name;
    if (this.props.products.length === 1) {
      switch (product.kind) {
        // case "clothing":
        //   name = "Vêtement";
        //   break;
        case "carpet":
          name = "Tapis";
          break;
        // case "linen":
        //   name = "Linge";
        //   break;
        // case "accessory":
        //   name = "Accessoire";
        //   break;
        default:
          name = "";
      }
    } else {
      name = "";
    }

    const {
      active: { variants = {}, products: activeProducts = {} },
    } = this.state;

    return (
      <Product
        variant={
          (activeProducts[EMPTY_COMBINATION_PART_ID] &&
            variants[activeProducts[EMPTY_COMBINATION_PART_ID].id]) ||
          {}
        }
        name={name}
      >
        {this.renderProductProperties(product)}
      </Product>
    );
  };

  renderProductProperties = (product) => {
    const { id: productId, properties } = product;
    const {
      findExactVariantForAttribute,
      findBestVariantForAttribute,
      isActive,
    } = this.getVariantSeekerForProduct(product);

    const getButtonPropertiesForAttribute = (property, value) => {
      const exactVariant = findExactVariantForAttribute(property, value);
      const bestVariant =
        !exactVariant && findBestVariantForAttribute(property, value);
      return {
        disabled: !exactVariant && !bestVariant,
        isActive: isActive(property, value),
        isSecondary: !!bestVariant,
        onClick: () =>
          this.activateVariant(exactVariant || bestVariant, productId),
      };
    };

    const propertiesToDisplay = _filter(
      properties,
      ({ kind }) => kind !== "text" && kind !== "sex"
    );

    return _map(
      propertiesToDisplay,
      ({ id: propertyId, name, kind, tags, colors }) => {
        const colorsWithAttributes =
          kind === "color"
            ? _map(colors, (color) => ({
                color,
                attributes: getButtonPropertiesForAttribute(
                  propertyId,
                  color.id
                ),
              }))
            : [];
        const tagsWithAttributes =
          kind === "tag"
            ? _map(tags, (tag) => ({
                tag,
                attributes: getButtonPropertiesForAttribute(propertyId, tag),
              }))
            : [];
        let subtitle;
        switch (kind) {
          case "color":
            if (colorsWithAttributes.length) {
              subtitle = (
                _find(
                  colorsWithAttributes,
                  ({ attributes }) => attributes.isActive
                ) || colorsWithAttributes[0]
              ).color.name;
            }
            break;
          case "tag":
            if (tagsWithAttributes.length === 1) {
              subtitle = (
                _find(
                  tagsWithAttributes,
                  ({ attributes }) => attributes.isActive
                ) || tagsWithAttributes[0]
              ).tag;
            }
            break;
          default:
            subtitle = "";
            break;
        }
        return (
          <AttributesBlock key={propertyId} title={name} subtitle={subtitle}>
            {kind === "sex" && (
              /* Normalement jamais utilisé */ <Fragment>
                <AttributeButtonTag
                  {...getButtonPropertiesForAttribute(propertyId, "male")}
                >
                  Homme
                </AttributeButtonTag>
                <AttributeButtonTag
                  {...getButtonPropertiesForAttribute(propertyId, "female")}
                >
                  Femme
                </AttributeButtonTag>
                <AttributeButtonTag
                  {...getButtonPropertiesForAttribute(propertyId, "unisex")}
                >
                  Unisexe
                </AttributeButtonTag>
              </Fragment>
            )}
            {kind === "tag" &&
              tagsWithAttributes.length > 1 &&
              _map(tagsWithAttributes, ({ tag, attributes }) => (
                <AttributeButtonTag key={tag} {...attributes}>
                  {tag}
                </AttributeButtonTag>
              ))}
            {kind === "color" &&
              _map(colorsWithAttributes, ({ color, attributes }) => (
                <AttributeButtonColor key={color.id} {...attributes}>
                  <ColorBadge {...color} />
                </AttributeButtonColor>
              ))}
          </AttributesBlock>
        );
      }
    );
  };
}
