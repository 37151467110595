import config from "./config";

export function cdn(path) {
  return `${config.CDN_URI}${path}`;
}

export function media(media, mode = "original") {
  return `${config.CDN_URI}media/${media.id}/${mode}/${media.hash || "0"}-${
    media.name
  }`;
}

export function nl2br(text = "") {
  return (text || "").replace(/\r?\n/gim, "<br />");
}

export function series(tasks, initial, delay = 20) {
  return tasks.reduce((current, next) => {
    return current.then(
      (...args) =>
        new Promise(function(resolve, reject) {
          window.setTimeout(() => {
            try {
              resolve(next(...args));
            } catch (err) {
              reject(err);
            }
          }, delay);
        })
    );
  }, Promise.resolve(initial));
}
