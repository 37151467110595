import { combineReducers } from "redux";

import application from "./application";
import sync from "./sync";
import status from "./status";
import auth from "./auth";
import cart from "./cart";
import categories from "./categories";

export default combineReducers({
  application,
  status,
  auth,
  cart,
  categories,
  sync
});
