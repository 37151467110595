import React from "react";

import { Modal, CloseButton, Image } from "./style";

export default function({ isOpen, onClose, src, alt }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel={alt}>
      <CloseButton onClick={onClose}>Fermer</CloseButton>
      <Image src={src} alt={alt} />
    </Modal>
  );
}
