import React from "react";
import { Helmet } from "react-helmet";

import BackButton from "../../../components/BackButton";

import {
  Article,
  Main,
  Header,
  Headline,
  Title,
  Subtitle,
  Baseline,
  Blocks,
  Block,
  Strong,
  Text,
  List,
  ListItem,
  Image,
  KpiBlocks,
  KpiBlock
} from "./style";
import imageBackground from "./images/background.jpg";
import imageEntreprise from "./images/image.jpg";
import imagePriseEnCharge from "./images/prise-en-charge.jpg";
import imageBudget from "./images/budget.jpg";

export default function() {
  return (
    <Article background={imageBackground} backButton={<BackButton to="/" />}>
      <Helmet>
        <title>L'Expertise Initial</title>
      </Helmet>
      <Header>
        <Headline
          icon={
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 74 50"
            >
              <path
                fill="#007dc5"
                d="M19.3,51L0.4,27.4c-0.7-0.9-0.6-2.1,0.3-2.8c0.9-0.7,2.1-0.5,2.8,0.3l16.3,20.3L70.7,0.5c0.8-0.7,2.1-0.6,2.8,0.2
            c0.7,0.8,0.6,2.1-0.2,2.8L19.3,51z"
              />
            </svg>
          }
        >
          <Title>L’Expertise Initial</Title>
          <Subtitle>Notre savoir-faire à votre service</Subtitle>
        </Headline>
      </Header>
      <Main>
        <Baseline>
          Notre objectif&nbsp;? 100% satisfaction client&nbsp;!
        </Baseline>
        <Blocks>
          <Block>
            <Text>
              <Strong>
                L’image de votre <br />entreprise valorisée
              </Strong>
              <List>
                <ListItem>
                  Des articles textiles{" "}
                  <strong>de qualité pour un service soigné</strong>.
                </ListItem>
                <ListItem>
                  Des gammes complètes{" "}
                  <strong>de nappages et linges de lit</strong>.
                </ListItem>
                <ListItem>
                  Un large choix <strong>de tapis anti-salissure</strong>.
                </ListItem>
              </List>
            </Text>
            <Image
              src={imageEntreprise}
              alt="Illustration de l'image de l'entreprise"
            />
          </Block>
          <Block>
            <Image
              src={imagePriseEnCharge}
              alt="Illustration de la prise en charge"
            />
            <Text>
              <Strong>La prise en charge intégrale et quotidienne</Strong>
              <List>
                <ListItem>
                  <strong>Un suivi</strong> régulier et personnalisé{" "}
                  <strong>avec des équipes expertes</strong>.
                </ListItem>
                <ListItem>
                  Un accès internet dédié{" "}
                  <strong>mis à votre disposition</strong> 24h/24{" "}
                  <strong>
                    pour un suivi de votre prestation en temps réel
                  </strong>.
                </ListItem>
              </List>
            </Text>
          </Block>
          <Block>
            <Text>
              <Strong>Un budget maîtrisé</Strong>
              <List>
                <ListItem>
                  Un budget connu <strong>et évalué ensemble</strong>.
                </ListItem>
                <ListItem>
                  La suppression des coûts{" "}
                  <strong>d’investissement et d’amortissement</strong>.
                </ListItem>
              </List>
            </Text>
            <Image src={imageBudget} alt="Illustration du budget" />
          </Block>
        </Blocks>
        <KpiBlocks title="Chiffres clés">
          <KpiBlock
            figure="95"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 35">
                <g>
                  <path
                    d="M37.871,24.615c0.214-0.261,0.416-0.507,0.589-0.772c0.488-0.742,0.646-1.526,0.47-2.331
		c-0.144-0.656-0.5-1.257-1.091-1.839c-0.169-0.166-0.203-0.329-0.115-0.545c0.371-0.903,0.559-1.72,0.577-2.497
		c0.027-1.195-0.365-2.136-1.199-2.877c-0.522-0.465-1.159-0.8-2.004-1.056c-0.8-0.242-1.664-0.38-2.719-0.435
		c-0.342-0.018-0.697-0.027-1.053-0.027c-0.898,0-1.842,0.056-2.884,0.17c-0.157,0.017-0.314,0.037-0.481,0.057l-0.542,0.066
		l0.118-0.365c0.015-0.049,0.028-0.089,0.042-0.127c0.841-2.282,1.29-4.194,1.411-6.017c0.068-1.021,0.002-1.903-0.203-2.695
		c-0.272-1.056-0.781-1.851-1.554-2.43c-0.623-0.467-1.377-0.745-2.373-0.876c-0.538-0.071-0.906,0.059-1.17,0.415
		c-0.151,0.203-0.336,0.473-0.438,0.754c-0.339,0.945-0.625,1.807-0.874,2.635c-0.542,1.803-1.095,3.558-1.9,5.214
		c-1.062,2.185-2.368,3.849-3.993,5.088c-0.731,0.557-1.514,1.014-2.395,1.398l-0.272,0.119v-0.294c0-0.194-0.002-0.385-0.004-0.575
		c-0.005-0.442-0.009-0.86,0.011-1.288c0.009-0.19-0.046-0.353-0.16-0.471c-0.12-0.124-0.302-0.192-0.515-0.192
		c-2.383,0.007-4.77,0.008-6.584,0.008l-5.862-0.001c-0.514,0-0.705,0.187-0.705,0.691v20.796l0,0.057c0,0.031,0,0.063,0.001,0.094
		c0.019,0.313,0.251,0.532,0.565,0.533C2.721,35,4.874,35,7.028,35c2.067,0,4.135,0,6.202-0.001c0.357,0,0.588-0.23,0.59-0.585
		c0.001-0.309,0.001-0.618,0-0.927l0-1.292l0.224-0.02c0.034-0.003,0.062-0.006,0.09-0.006c0.194-0.003,0.388-0.005,0.581-0.008
		c0.45-0.005,0.915-0.011,1.376-0.028c0.315-0.012,0.561,0.073,0.776,0.271c0.119,0.109,0.256,0.202,0.401,0.302
		c0.033,0.023,0.067,0.045,0.1,0.068c1.492,1.032,3.098,1.674,4.773,1.909c0.779,0.109,1.623,0.178,2.509,0.203
		c1.104,0.032,2.277,0.047,3.583,0.047c0.28,0,0.56-0.001,0.841-0.002c1.421-0.007,2.842-0.162,4.225-0.461
		c1.061-0.23,1.867-0.559,2.537-1.034c0.597-0.424,0.938-0.929,1.043-1.547c0.085-0.501,0.001-1.016-0.259-1.575
		c-0.085-0.184-0.045-0.328,0.125-0.439c0.196-0.129,0.361-0.25,0.505-0.371c0.744-0.626,1.103-1.345,1.095-2.197
		c-0.005-0.586-0.192-1.165-0.57-1.769c-0.055-0.088-0.112-0.175-0.16-0.248l-0.128-0.196l0.084-0.107
		C37.672,24.857,37.773,24.734,37.871,24.615z M37.146,26.709c0.211,0.603,0.13,1.151-0.231,1.604
		c-0.083,0.105-0.182,0.204-0.295,0.298c-0.291,0.242-0.637,0.467-1.058,0.689c-0.19,0.1-0.313,0.231-0.356,0.379
		c-0.042,0.144-0.01,0.313,0.093,0.49c0.208,0.359,0.352,0.66,0.453,0.948c0.182,0.519,0.031,0.993-0.438,1.371
		c-0.42,0.339-0.909,0.528-1.331,0.667c-0.952,0.315-1.944,0.456-2.788,0.552c-1.105,0.126-2.273,0.19-3.472,0.19
		c-0.225,0-0.457-0.002-0.688-0.007c-0.821-0.016-1.654-0.061-2.46-0.104l-0.016-0.001c-0.427-0.023-0.868-0.046-1.301-0.065
		c-1.76-0.077-3.396-0.614-4.991-1.64c-0.106-0.068-0.212-0.139-0.318-0.212c-0.262-0.18-0.532-0.389-0.801-0.621
		c-0.145-0.124-0.305-0.182-0.508-0.182l-0.01,0c-0.593,0.006-1.185,0.011-1.778,0.016l-0.694,0.005l-0.338,0v-2.369
		c0-3.797,0-7.723-0.004-11.591c0-0.03,0.001-0.058,0.004-0.084c0.021-0.187,0.118-0.302,0.312-0.377
		c0.221-0.086,0.439-0.178,0.654-0.275c1.503-0.683,2.841-1.648,3.985-2.876c1.096-1.176,2.025-2.594,2.84-4.335
		c0.781-1.668,1.321-3.398,1.849-5.171c0.262-0.878,0.493-1.612,0.768-2.338l0.033-0.086c0.135-0.359,0.213-0.505,0.447-0.505
		c0.074,0,0.151,0.018,0.26,0.043c0.014,0.003,0.03,0.007,0.045,0.01c0.048,0.011,0.101,0.023,0.163,0.035
		c0.896,0.175,1.587,0.605,2.047,1.265c0.046,0.066,0.09,0.134,0.131,0.205c0.083,0.141,0.156,0.291,0.22,0.451
		c0.096,0.239,0.171,0.498,0.224,0.776c0.183,0.964,0.179,1.992-0.013,3.234c-0.267,1.729-0.766,3.483-1.526,5.35
		c-0.051,0.124-0.102,0.249-0.155,0.375c-0.011,0.025-0.022,0.051-0.033,0.077c-0.01,0.024-0.02,0.047-0.03,0.07
		c-0.029,0.069-0.054,0.134-0.065,0.197c-0.024,0.136-0.001,0.257,0.07,0.361c0.01,0.015,0.021,0.029,0.033,0.043
		c0.108,0.127,0.289,0.209,0.46,0.209c0.031,0,0.061-0.003,0.089-0.008c1.334-0.254,2.689-0.406,4.027-0.452
		c0.27-0.009,0.52-0.014,0.763-0.014c0.921,0,1.733,0.067,2.48,0.206c0.658,0.122,1.505,0.328,2.225,0.845
		c0.684,0.491,1.049,1.157,1.085,1.982c0.005,0.103,0.006,0.207,0.003,0.311c-0.019,0.73-0.219,1.491-0.618,2.366
		c-0.308,0.676-0.243,0.805,0.263,1.225c0.457,0.379,0.763,0.794,0.935,1.268c0.205,0.561,0.129,1.145-0.224,1.736
		c-0.284,0.475-0.676,0.888-1.2,1.264c-0.158,0.114-0.249,0.257-0.263,0.415c-0.002,0.02-0.002,0.04-0.002,0.059
		c0.005,0.138,0.069,0.274,0.189,0.397c0.101,0.104,0.195,0.209,0.281,0.316C36.832,26.015,37.02,26.349,37.146,26.709z
		 M12.724,13.911v20H1.109v-20H12.724z"
                  />
                </g>
              </svg>
            }
          >
            ans d’expérience
          </KpiBlock>
          <KpiBlock
            figure="26 sites"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 43">
                <path
                  d="M40.836,32.746c-0.085,0.018-0.173,0.036-0.262,0.044c-0.442,0.035-1.045-0.048-1.343-0.283
	c-0.422-0.336-0.39-0.573-0.224-1.272l0.065-0.261c0.18-0.712,0.132-0.837-0.517-1.341c-0.047-0.036-0.115-0.081-0.192-0.13
	c-0.111-0.071-0.381-0.245-0.45-0.392c0.039,0.002,0.079,0.005,0.112,0.007c0.247,0.017,0.401,0.022,0.519-0.026
	c0.178-0.072,0.646-0.483,0.753-0.644c0.668-1-0.221-2.149-0.999-2.799c0.027-0.02,0.053-0.039,0.079-0.058
	c0.274-0.201,0.585-0.428,0.538-0.954c-0.023-0.252-0.235-0.495-0.44-0.73c-0.111-0.126-0.236-0.27-0.27-0.354
	c-0.032-0.076-0.036-0.26-0.039-0.407c-0.006-0.273-0.012-0.531-0.142-0.699c-0.416-0.54-1.039-0.347-1.544-0.191
	c-0.172,0.053-0.342,0.121-0.513,0.208c0.118-0.45,0.356-0.832,0.63-1.272c0.096-0.153,0.195-0.311,0.293-0.479
	c0.175-0.299,0.406-0.534,0.651-0.783c0.174-0.177,0.353-0.359,0.523-0.574c0.212-0.267,0.449-0.584,0.566-0.939
	c0.069-0.207,0.002-0.394-0.046-0.531c-0.016-0.045-0.04-0.112-0.014-0.132c0.024-0.013,0.132,0.029,0.208,0.061
	c0.24,0.1,0.642,0.266,1.079-0.143c0.407-0.382,0.401-0.815,0.393-1.232c-0.001-0.085-0.003-0.172-0.001-0.263
	c0.005-0.283-0.012-0.585-0.029-0.887c-0.02-0.363-0.041-0.739-0.019-1.062c0.04-0.551,0.302-1.237,0.534-1.841
	c0.103-0.27,0.203-0.531,0.28-0.768v0c0.032-0.096,0.2-0.291,0.348-0.463c0.369-0.426,0.827-0.957,0.557-1.434
	c-0.221-0.391-0.817-0.454-1.395-0.515c-0.275-0.029-0.559-0.059-0.685-0.121c-0.054-0.027-0.115-0.088-0.179-0.153
	c-0.109-0.111-0.246-0.249-0.445-0.294c-0.252-0.054-0.478,0.065-0.677,0.171c-0.11,0.058-0.214,0.119-0.302,0.119
	c-0.258,0-0.367-0.107-0.504-0.243c-0.22-0.217-0.458-0.39-0.897-0.183l-0.013-0.026c-0.317-0.666-0.604-1.233-1.654-1.195
	c-0.131,0.006-0.282,0.054-0.427,0.102C34.656,7.414,34.5,7.467,34.463,7.46c-0.093-0.01-0.205-0.094-0.313-0.175
	c-0.139-0.104-0.297-0.222-0.489-0.238c-0.311-0.024-0.503,0.032-0.642,0.074c-0.156,0.047-0.214,0.065-0.444-0.071
	c-0.621-0.363-1.304-0.873-1.409-1.146c-0.063-0.164-0.003-0.273,0.117-0.468c0.116-0.187,0.259-0.419,0.16-0.714
	c-0.082-0.243-0.247-0.401-0.466-0.444c-0.49-0.089-1.053,0.42-1.175,0.564c-0.077,0.091-0.173,0.252-0.09,0.449
	c-0.026,0.008-0.055,0.017-0.089,0.027c-0.093,0.011-0.361-0.089-0.428-0.15c-0.123-0.134-0.104-0.26-0.029-0.537
	c0.067-0.245,0.158-0.581-0.081-0.881c-0.277-0.348-0.743-0.349-1.155-0.349c-0.226,0-0.459,0-0.57-0.053
	c-0.054-0.039-0.238-0.251-0.338-0.364c-0.114-0.131-0.218-0.248-0.265-0.288c-0.084-0.071-0.174-0.105-0.252-0.135
	c-0.087-0.033-0.15-0.057-0.208-0.128c-0.135-0.163-0.194-0.321-0.251-0.473c-0.113-0.3-0.239-0.639-0.893-0.639
	c-0.019,0-0.039,0-0.058,0.001c-0.228,0.007-0.381,0.077-0.483,0.123c-0.015,0.006-0.033,0.015-0.038,0.022
	c-0.023-0.027-0.069-0.089-0.147-0.231c-0.094-0.173-0.146-0.334-0.192-0.476C24.114,0.387,23.99,0.003,23.215,0
	c-0.002,0-0.004,0-0.006,0c-0.585,0-1.746,0.374-2.225,0.666c-0.404,0.247-0.711,0.77-0.863,1.472
	c-0.162,0.754-0.117,1.571,0.103,2.129c-0.255,0.115-0.321,0.351-0.37,0.523c-0.037,0.13-0.071,0.253-0.169,0.366
	c-0.258,0.298-0.912,0.43-1.39,0.527c-0.161,0.033-0.311,0.063-0.435,0.096c-0.421,0.11-2.17,0.766-2.4,1.73
	c-0.044,0.184-0.045,0.439,0.131,0.711c-0.433,0.174-0.638,0.122-1.159-0.007l-0.288-0.07c-0.22-0.052-0.464-0.071-0.702-0.089
	C12.773,8,12.355,7.94,12.212,7.541c-0.025-0.069-0.01-0.124,0.025-0.232c0.058-0.185,0.155-0.495-0.222-0.833
	c-0.29-0.261-0.564-0.211-0.759-0.175c-0.081,0.015-0.157,0.028-0.233,0.023c-0.06-0.004-0.221-0.042-0.386-0.08
	c-0.357-0.081-0.479-0.109-0.548-0.099c-0.044,0.001-0.088,0.001-0.135,0.001C9.793,6.144,9.54,6.141,9.346,6.279l-0.03,0.02
	L9.291,6.323c-0.049,0.045-0.094,0.101-0.13,0.171C8.931,6.93,9.265,7.14,9.39,7.218C9.454,7.258,9.514,7.295,9.524,7.32
	C9.527,7.35,9.507,7.443,9.495,7.504c-0.04,0.182-0.093,0.432-0.002,0.673c0.073,0.195,0.199,0.319,0.3,0.419
	c0.066,0.065,0.122,0.121,0.152,0.182c0.237,0.492,0.242,0.965,0.247,1.466c0.003,0.362,0.007,0.734,0.097,1.126
	c-0.099-0.02-0.153-0.058-0.22-0.106c-0.117-0.085-0.262-0.19-0.575-0.243c-0.296-0.05-0.518,0.106-0.679,0.219
	c-0.088,0.063-0.172,0.122-0.246,0.137c-0.16,0.031-0.352,0.055-0.542,0.078c-0.174,0.022-0.345,0.043-0.493,0.07
	c-0.206,0.035-0.325,0.084-0.412,0.137c-0.014-0.007-0.028-0.015-0.045-0.024c-0.065-0.138-0.195-0.336-0.405-0.656
	c-0.059-0.09-0.106-0.161-0.121-0.187l-0.005,0.002l0.011-0.008c-0.001,0-0.001,0-0.001,0c-0.007-0.009-0.014-0.019-0.022-0.028
	l-0.156-0.195L6.36,10.576c-0.463-0.443-1.172-0.705-1.877-0.385c-0.23,0.103-0.37,0.242-0.483,0.353
	c-0.157,0.157-0.253,0.242-0.542,0.227l-0.082-0.004c-0.61-0.032-1.138-0.059-1.87,0.104c-0.629,0.14-1.125,0.25-1.383,0.921
	c-0.097,0.252-0.205,0.619-0.031,0.903c0.121,0.199,0.329,0.29,0.584,0.342c-0.039,0.132-0.038,0.271,0.002,0.408
	c-0.308,0.083-0.585,0.262-0.633,0.574c-0.069,0.448,0.391,0.613,0.638,0.701c0.056,0.02,0.133,0.048,0.146,0.054
	c0.12,0.111,0.167,0.252,0.218,0.402c0.096,0.284,0.238,0.669,0.886,0.647c0.238-0.009,0.455-0.18,0.584-0.283
	c0.004-0.003,0.007-0.006,0.011-0.009c0.075,0.028,0.169,0.104,0.261,0.178c0.116,0.095,0.247,0.202,0.4,0.262l0.249,0.1
	c0.483,0.197,1.021,0.416,1.541,0.425c0.018,0.142,0.067,0.259,0.146,0.352c0.188,0.221,0.482,0.234,0.739,0.247
	c0.124,0.006,0.252,0.012,0.356,0.039c0.002,0.005,0.004,0.011,0.006,0.017c0.043,0.12,0.115,0.32,0.332,0.405
	c0.082,0.032,0.17,0.047,0.26,0.053c-0.093,0.199-0.016,0.415,0.226,0.75c0.232,0.319,0.525,0.45,0.834,0.5
	c-0.043,0.099-0.055,0.184-0.032,0.271c0.042,0.162,0.181,0.223,0.265,0.259c0.106,0.046,0.199,0.086,0.262,0.301
	c0.019,0.069-0.015,0.169-0.055,0.286c-0.053,0.152-0.113,0.325-0.085,0.523c0.043,0.311,0.23,0.528,0.395,0.719
	c0.079,0.091,0.16,0.183,0.218,0.287c0.069,0.122,0.121,0.23,0.169,0.333c0.118,0.248,0.228,0.482,0.497,0.741
	c0.451,0.434,1.045,0.744,1.534,0.96c-0.083,0.201-0.008,0.376,0.043,0.496c0.027,0.064,0.05,0.119,0.056,0.174l0.027,0.231
	c0.04,0.33,0.044,0.364-0.092,0.745c-0.032,0.09-0.063,0.169-0.09,0.24c-0.208,0.539-0.222,0.774,0.255,1.218
	c-0.239,0.349-0.35,1.039-0.46,1.865c-0.022,0.174-0.041,0.313-0.056,0.383c-0.034,0.147-0.086,0.307-0.139,0.471
	c-0.189,0.586-0.44,1.364,0.118,1.785c-0.398,0.714-0.513,1.594-0.625,2.446c-0.15,1.138-0.29,2.214-1.04,2.893
	c-0.09,0.082-0.207,0.14-0.319,0.196c-0.187,0.092-0.4,0.197-0.432,0.435c-0.034,0.248,0.165,0.464,0.423,0.691
	c0.215,0.128,0.45,0.21,0.751,0.245c0.054-0.004,0.093-0.004,0.118-0.001c-0.005,0.018-0.014,0.04-0.027,0.07l-0.067,0.146
	l0.105,0.121c0.062,0.071,0.111,0.136,0.157,0.197c0.127,0.168,0.247,0.328,0.52,0.505c0.226,0.147,0.483,0.229,0.731,0.308
	c0.185,0.06,0.361,0.116,0.511,0.195l0.063,0.033l0.071-0.006c0.239-0.019,0.327,0.074,0.37,0.235l0.016,0.062l0.045,0.047
	c0.126,0.131,0.256,0.26,0.385,0.388l0.054,0.04c0.392,0.215,0.66,0.116,0.877,0.037c0.138-0.051,0.246-0.089,0.4-0.064
	c0.101,0.018,0.12,0.049,0.18,0.152c0.067,0.111,0.158,0.263,0.373,0.347c0.195,0.074,0.318,0.069,0.425,0.065
	c0.062-0.002,0.112-0.006,0.189,0.011l0.076,0.016l0.072-0.032c0.085-0.038,0.174-0.06,0.267-0.069
	c0.131,0.089,0.273,0.164,0.421,0.222l0.061,0.016c0.013,0.001,1.249,0.085,1.505-0.091c0.199-0.137,0.252-0.295,0.258-0.432
	c0.27,0.116,0.462,0.136,0.663,0.158c0.147,0.016,0.298,0.032,0.507,0.09c0.166,0.047,0.311,0.2,0.463,0.363
	c0.158,0.167,0.321,0.341,0.54,0.433c0.174,0.074,0.349,0.046,0.504,0.021c0.102-0.017,0.209-0.032,0.259-0.008
	c0.025,0.014,0.072,0.098,0.1,0.148c0.053,0.094,0.114,0.2,0.209,0.283l0.066,0.056c0.463,0.398,0.787,0.687,1.496,0.629
	c0.137-0.011,0.251-0.052,0.351-0.088c0.127-0.045,0.201-0.072,0.3-0.054c0.059,0.011,0.112,0.055,0.195,0.128
	c0.099,0.087,0.223,0.194,0.403,0.24C23.768,42.99,23.853,43,23.935,43c0.345,0,0.64-0.17,0.885-0.313
	c0.167-0.096,0.323-0.187,0.465-0.216c0.041-0.005,0.178,0.015,0.269,0.028c0.321,0.044,0.628,0.088,0.806-0.08
	c0.129-0.122,0.248-0.258,0.249-0.428c0.001-0.164-0.103-0.265-0.179-0.338c-0.062-0.06-0.121-0.116-0.162-0.219
	c-0.072-0.179-0.11-0.368-0.151-0.568c-0.051-0.257-0.104-0.521-0.234-0.769c0.049-0.033,0.09-0.071,0.124-0.117
	c0.136-0.186,0.096-0.401,0.059-0.591c-0.025-0.136-0.05-0.264-0.012-0.361c0.157-0.408,0.552-0.556,0.969-0.711
	c0.156-0.058,0.31-0.116,0.45-0.185c0.229-0.112,0.443-0.291,0.65-0.464c0.287-0.239,0.609-0.482,0.806-0.472
	c0.044,0.015,0.165,0.11,0.237,0.168c0.145,0.115,0.295,0.234,0.445,0.275c0.274,0.078,0.544,0.039,0.788-0.004
	c0.027,0.076,0.069,0.142,0.124,0.196c0.197,0.192,0.49,0.171,0.751,0.153c0.368-0.026,0.573-0.085,0.683-0.224
	c0.13,0.112,0.336,0.128,0.537,0.143c0.148,0.011,0.315,0.024,0.441,0.07c0.028,0.011,0.039,0.022,0.084,0.096
	c0.053,0.088,0.133,0.219,0.309,0.301c0.173,0.08,0.353,0.141,0.533,0.2c0.162,0.053,0.324,0.107,0.479,0.178
	c0.114,0.053,0.229,0.114,0.344,0.175c0.378,0.201,0.796,0.427,1.274,0.402c0.343-0.02,0.641-0.147,0.955-0.283
	c0.204-0.087,0.413-0.177,0.64-0.237c0.038-0.01,0.1-0.018,0.167-0.028c0.175-0.026,0.277-0.043,0.351-0.084l0.13-0.071
	c0.161-0.087,0.242-0.13,0.291-0.309c0.012-0.041,0.031-0.109,0.094-0.237c0.274-0.556,0.579-0.962,0.98-1.474
	c0.183-0.234,0.483-0.411,0.774-0.582c0.313-0.185,0.636-0.376,0.836-0.652c0.105-0.145,0.125-0.34,0.146-0.529
	c0.009-0.086,0.018-0.183,0.036-0.23c0.035-0.097,0.114-0.204,0.199-0.318c0.164-0.221,0.368-0.496,0.316-0.841
	c-0.048-0.324-0.15-0.53-0.319-0.649C41.326,32.643,41.065,32.698,40.836,32.746z M40.381,34.625
	c-0.12,0.153-0.267,0.241-0.453,0.352c-0.12,0.071-0.247,0.148-0.382,0.248c-0.324,0.242-0.54,0.552-0.75,0.852
	c-0.136,0.195-0.264,0.379-0.419,0.542c-0.095,0.1-0.176,0.177-0.246,0.244c-0.285,0.272-0.422,0.424-0.453,0.919
	c-0.273,0.03-0.514,0.134-0.751,0.235c-0.136,0.058-0.273,0.118-0.416,0.16c-0.136,0.04-0.226,0.085-0.305,0.125
	c-0.055,0.028-0.09,0.046-0.131,0.055c-0.131-0.039-0.34-0.075-0.669-0.114l-0.032-0.008c-0.36-0.122-1.03-0.35-1.312-0.539
	c-0.147-0.099-0.23-0.196-0.31-0.29c-0.114-0.134-0.22-0.243-0.378-0.301c0.049-0.082-0.244-0.094-0.354-0.161
	c-0.271-0.167-0.748,0.094-1.077,0.012c-0.17-0.042-0.791-0.205-0.81-0.033c-0.008-0.005-0.015-0.011-0.023-0.018
	c-0.117-0.089-0.262-0.2-0.508-0.249l-0.046-0.009l-0.045,0.008c-0.202,0.036-0.405,0.064-0.609,0.084
	c-0.143-0.034-0.246-0.11-0.323-0.239l-0.054-0.09l-0.103-0.023c-0.787-0.183-1.192,0.119-1.617,0.438
	c-0.211,0.158-0.43,0.321-0.732,0.468c-0.173,0.084-0.311,0.128-0.437,0.17c-0.25,0.081-0.486,0.159-0.819,0.454
	c-0.483,0.427-0.542,0.721-0.613,1.062c-0.014,0.071-0.03,0.147-0.051,0.231c-0.1,0.04-0.18,0.102-0.236,0.184
	c-0.076,0.111-0.103,0.248-0.08,0.407c-0.036,0.253,0.021,0.494,0.165,0.703l0.003,0.018c0.045,0.3,0.098,0.658,0.201,0.972
	c-0.114,0.053-0.222,0.111-0.327,0.168c-0.265,0.143-0.495,0.266-0.763,0.274c-0.218,0.008-0.421-0.06-0.635-0.131
	c-0.214-0.071-0.436-0.143-0.699-0.158c-0.015-0.001-0.032-0.001-0.046-0.001c-0.134,0-0.252,0.031-0.347,0.056
	c-0.041,0.01-0.083,0.022-0.119,0.028c-0.007-0.009-0.014-0.019-0.02-0.029c-0.116-0.161-0.179-0.243-0.259-0.289
	c-0.178-0.102-0.399-0.044-0.485-0.08c-0.01-0.008-0.02-0.016-0.031-0.024c-0.14-0.101-0.247-0.179-0.329-0.241l0.064-0.122
	c-0.001,0-0.001,0-0.001,0c-0.313-0.167-0.521-0.277-0.66-0.174c-0.02-0.002-0.041-0.003-0.06-0.005
	c-0.165-0.015-0.307-0.027-0.448-0.086c-0.066-0.027-0.166-0.135-0.255-0.23c-0.141-0.15-0.3-0.321-0.507-0.412
	c-0.347-0.152-0.687-0.224-1.046-0.301c-0.11-0.023-0.222-0.047-0.335-0.073l-0.122-0.029c-0.407-0.097-0.729-0.176-0.947,0.037
	c-0.125,0.122-0.158,0.296-0.153,0.473c-0.835-0.016-1.558-0.104-1.967-0.241c-0.141-0.047-0.213-0.108-0.298-0.178
	c-0.149-0.124-0.318-0.266-0.736-0.28c-0.209-0.009-0.354,0.052-0.461,0.094c-0.123,0.049-0.145,0.058-0.24,0.004
	c-0.079-0.045-0.134-0.153-0.197-0.278c-0.076-0.152-0.163-0.323-0.33-0.443c-0.2-0.142-0.502-0.203-0.822-0.267
	c-0.201-0.04-0.407-0.082-0.553-0.145c-0.201-0.071-0.394-0.165-0.531-0.244c-0.068-0.07-0.083-0.136-0.055-0.235l0.038-0.133
	l-0.092-0.103c-0.256-0.285-0.433-0.414-0.743-0.526c0.89-0.817,1.115-2.235,1.315-3.506c0.083-0.523,0.161-1.018,0.279-1.418
	c0.029-0.097,0.104-0.237,0.177-0.372c0.122-0.225,0.247-0.457,0.261-0.668c0.021-0.321-0.063-0.456-0.207-0.569
	c-0.031-0.025-0.031-0.025-0.039-0.055l-0.123-0.084l-0.077-0.1c-0.01-0.002-0.018-0.003-0.026-0.005
	c0.014-0.038,0.047-0.105,0.125-0.209l0.545-2.315c0.185,0.286,0.298,0.651,0.416,1.03c0.091,0.293,0.184,0.596,0.317,0.869
	c0.26,0.532,0.553,0.78,0.839,0.695c0.428-0.126,0.345-0.865,0.318-1.107c-0.032-0.282-0.165-0.582-0.294-0.873
	c-0.092-0.204-0.185-0.416-0.231-0.595l-0.034-0.137c-0.124-0.509-0.193-0.789-0.623-1.249c-0.174-0.187-0.365-0.301-0.519-0.392
	c-0.283-0.169-0.329-0.204-0.27-0.438c0.034-0.136,0.089-0.231,0.152-0.342c0.112-0.195,0.238-0.416,0.224-0.831
	c-0.011-0.223-0.075-0.431-0.193-0.622c-0.016-0.111,0.014-0.197,0.101-0.28l0.128-0.124l-0.075-0.162
	c-0.148-0.318-0.27-0.391-0.511-0.514c-0.056-0.029-0.122-0.063-0.203-0.108c-0.891-0.498-1.27-0.797-1.786-1.636
	c-0.256-0.416-0.353-0.574-0.428-0.946c0.094-0.204,0.12-0.42,0.075-0.642l-0.025-0.07c-0.1-0.188-0.235-0.35-0.398-0.483
	c0.013-0.055,0.044-0.11,0.088-0.181c0.069-0.111,0.163-0.264,0.111-0.451c-0.053-0.19-0.227-0.299-0.373-0.373
	c-0.236-0.12-0.457-0.074-0.622-0.04c-0.057,0.012-0.099,0.021-0.134,0.024c0.247-0.18,0.307-0.372,0.306-0.517
	c-0.002-0.193-0.115-0.469-0.646-0.686c-0.17-0.069-0.343-0.053-0.479-0.042c-0.045,0.004-0.09,0.008-0.128,0.008
	c-0.02-0.138-0.06-0.353-0.268-0.426c-0.202-0.071-0.391-0.065-0.539-0.062c-0.132,0.002-0.217,0.004-0.289-0.022
	c0.026-0.159,0.064-0.397-0.102-0.543c-0.22-0.193-0.511-0.126-0.724-0.08c-0.069,0.016-0.167,0.039-0.212,0.034
	c-0.359-0.076-0.744-0.268-1.118-0.454c-0.511-0.255-1.04-0.519-1.561-0.519c-0.01,0-0.02,0-0.03,0
	c-0.057,0.001-0.152,0.012-0.236,0.063c-0.007-0.029-0.013-0.062-0.018-0.095c-0.013-0.067-0.025-0.131-0.041-0.189
	c0.238-0.03,0.433-0.112,0.537-0.297c0.112-0.2,0.06-0.432-0.041-0.643c0.231-0.07,0.38-0.204,0.407-0.379
	c0.021-0.133-0.018-0.305-0.271-0.453c0.041-0.119,0.035-0.229-0.02-0.331c-0.155-0.288-0.603-0.311-1.318-0.218
	c0.313-0.289,0.939-0.428,1.924-0.406c0.014,0.008,0.048,0.039,0.07,0.061c0.081,0.076,0.191,0.179,0.353,0.195
	c0.105,0.008,0.155-0.024,0.455-0.209c0.053-0.033,0.097-0.061,0.1-0.064c0.026-0.009,0.109-0.015,0.164-0.019
	c0.116-0.008,0.235-0.017,0.336-0.06c0.186-0.08,0.223-0.268,0.242-0.37c0.002-0.013,0.006-0.03,0.009-0.045
	c0.538-0.296,0.727-0.031,1.063,0.536c0.095,0.159,0.188,0.315,0.291,0.444c0.597,0.746,1.146,0.544,1.674,0.349
	C8,12.401,8.157,12.343,8.328,12.301c0.06-0.015,0.181-0.054,0.322-0.102c0.178-0.059,0.548-0.183,0.623-0.188
	c0.041,0.005,0.161,0.073,0.24,0.119c0.152,0.088,0.325,0.187,0.518,0.234c0.22,0.055,1.111,0.119,1.486-0.127
	c0.14-0.093,0.186-0.209,0.2-0.29c0.042-0.249-0.149-0.453-0.352-0.668c-0.101-0.108-0.255-0.271-0.27-0.352
	c-0.029-0.152,0.001-0.365,0.03-0.571c0.025-0.181,0.049-0.353,0.037-0.545c-0.019-0.312-0.056-0.893-0.168-1.188
	c-0.074-0.199-0.219-0.353-0.346-0.487c-0.134-0.142-0.25-0.265-0.277-0.412c-0.017-0.092,0.004-0.159,0.034-0.25
	c0.019-0.06,0.042-0.132,0.052-0.214c0.109,0.021,0.209,0.031,0.308,0.04c0.067,0.007,0.135,0.013,0.21,0.024
	c0.134,0.018,0.25-0.037,0.344-0.086c0,0,0,0.001,0,0.002c-0.078,0.326-0.097,0.418-0.087,0.494c0.005,0.037,0.009,0.05,0.112,0.34
	c0.079,0.226,0.246,0.695,0.262,0.782l0.239-0.02l0.11-0.018l0.318,0.021l0.244,0.023l0.663,0.089
	c0.262,0.038,0.496,0.085,0.714,0.127c0.774,0.153,1.383,0.272,2.329-0.087c0.076-0.029,0.172-0.058,0.271-0.089
	c0.416-0.126,0.845-0.256,0.915-0.598c0.039-0.189-0.049-0.374-0.261-0.552C16.963,7.6,16.73,7.646,16.562,7.681
	c-0.039,0.008-0.09,0.018-0.128,0.022c0.012-0.074,0.113-0.219,0.485-0.516c0.39-0.311,0.871-0.417,1.382-0.53
	c0.236-0.052,0.472-0.105,0.702-0.177l0.183-0.055c0.368-0.109,0.826-0.244,1.08-0.49c0.143-0.14,0.207-0.326,0.262-0.491
	c0.025-0.074,0.068-0.197,0.095-0.222c0.025-0.015,0.12-0.015,0.176-0.014c0.146,0.001,0.388,0.001,0.525-0.195
	c0.197-0.284,0.014-0.65-0.134-0.943c-0.044-0.089-0.099-0.2-0.109-0.246c-0.126-0.623-0.124-1.774,0.164-2.159
	c0.243-0.325,1.094-0.634,1.724-0.634c0.021,0,0.042,0,0.064,0.001c0.251,0.009,0.431,0.07,0.493,0.166
	c0.049,0.076,0.09,0.216,0.133,0.365c0.124,0.424,0.308,1.061,1.028,1.061c0.013,0,0.026,0,0.04,0
	c0.215-0.007,0.356-0.136,0.458-0.23c0.08-0.074,0.106-0.091,0.099-0.103c0.055,0.062,0.124,0.317,0.167,0.471
	c0.084,0.309,0.157,0.575,0.328,0.712c0.172,0.137,0.364,0.108,0.48,0.092c0.024-0.004,0.06-0.009,0.046-0.022
	c0.054,0.042,0.105,0.117,0.16,0.196c0.078,0.113,0.167,0.242,0.3,0.327c0.207,0.134,0.523,0.155,0.857,0.176
	c0.198,0.013,0.565,0.036,0.623,0.088c0.018,0.057-0.015,0.255-0.037,0.374c-0.04,0.231-0.077,0.449-0.016,0.63
	c0.137,0.404,0.405,0.686,0.775,0.816c0.38,0.132,0.842,0.092,1.266-0.103c0.236,0.529,0.511,0.736,0.931,1.052l0.253,0.192
	c0.123,0.095,0.236,0.222,0.357,0.356c0.255,0.283,0.543,0.604,0.969,0.63c0.199,0.012,0.371-0.051,0.517-0.107
	c0.127-0.049,0.225-0.094,0.346-0.08c0.022,0.007,0.081,0.052,0.12,0.081c0.108,0.083,0.242,0.185,0.418,0.206
	c0.246,0.026,0.477-0.03,0.68-0.081c0.23-0.059,0.429-0.108,0.625-0.039c0.218,0.076,0.295,0.253,0.403,0.536
	c0.101,0.265,0.216,0.565,0.532,0.732c0.345,0.181,0.566-0.041,0.659-0.137c0.022-0.022,0.056-0.056,0.06-0.059
	c0.031,0.012,0.104,0.103,0.148,0.157c0.113,0.141,0.254,0.317,0.483,0.384c0.326,0.094,0.618,0.016,0.873-0.056
	c0.211-0.06,0.392-0.112,0.585-0.061c0.048,0.013,0.142,0.083,0.216,0.139c0.117,0.089,0.25,0.189,0.402,0.24
	c0.286,0.096,0.616,0.111,0.935,0.125c0.023,0.001,0.047,0.002,0.07,0.003c-0.083,0.138-0.177,0.269-0.272,0.405
	c-0.154,0.216-0.313,0.44-0.437,0.706c-0.267,0.569-0.443,1.213-0.612,1.836c-0.076,0.278-0.151,0.551-0.232,0.813
	c-0.099,0.318-0.05,0.781-0.002,1.23c0.027,0.244,0.051,0.474,0.047,0.644c-0.002,0.076,0.005,0.18,0.014,0.297
	c0.014,0.211,0.045,0.649-0.051,0.755c-0.053,0.057-0.079,0.061-0.19,0.054C39,16.998,38.9,16.995,38.793,17.035
	c-0.041,0.017-0.067,0.008-0.181-0.056c-0.362-0.203-0.687-0.201-1.092,0.482l-0.062,0.102c-0.131,0.216-0.256,0.42-0.143,0.625
	c0.062,0.11,0.168,0.168,0.295,0.199c-0.168,0.273-0.393,0.547-0.671,0.816c-0.502,0.488-0.605,0.679-0.88,1.189l-0.054,0.099
	c-0.11,0.204-0.247,0.374-0.392,0.554c-0.21,0.262-0.428,0.532-0.565,0.914c-0.141,0.396-0.129,0.693-0.117,1.009
	c0.004,0.108,0.009,0.221,0.008,0.338c-0.006,0.023-0.043,0.082-0.069,0.121c-0.086,0.132-0.204,0.313-0.145,0.538
	c0.044,0.168,0.179,0.308,0.409,0.428c0.6,0.311,0.922-0.248,1.112-0.584c0.064-0.113,0.124-0.227,0.197-0.31
	c0.322-0.377,0.59-0.573,0.776-0.531c0.018,0.108,0.03,0.324,0.038,0.462c0.014,0.276,0.024,0.425,0.064,0.518
	c0.123,0.298,0.347,0.566,0.545,0.802c0.009,0.01,0.017,0.02,0.026,0.031c-0.348,0.29-0.508,0.527-0.538,0.784
	c-0.044,0.38,0.213,0.687,0.569,1.112l0.196,0.235c0.38,0.449,0.526,0.678,0.326,0.989c-0.13,0.203-0.32,0.23-0.621,0.257
	c-0.333,0.031-0.837,0.077-0.948,0.733c-0.118,0.691,0.419,1.056,0.85,1.349c0.265,0.18,0.515,0.35,0.529,0.509
	c-0.008,0.049-0.087,0.195-0.135,0.283c-0.093,0.171-0.18,0.332-0.204,0.484c-0.042,0.286-0.007,0.669,0.091,0.975
	c0.169,0.525,0.403,0.685,0.77,0.885c0.313,0.171,1.084,0.489,1.533,0.489c0.176,0,0.329-0.076,0.453-0.136
	c0.014-0.007,0.03-0.015,0.046-0.023C40.696,33.999,40.45,34.528,40.381,34.625z M35.358,38.492l0-0.001l0.002,0.001
	C35.361,38.491,35.355,38.491,35.358,38.492z"
                />
              </svg>
            }
          >
            sur le territoire national
          </KpiBlock>
          <KpiBlock
            figure="86 350"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 30">
                <g>
                  <path
                    d="M48.458,0h-33.8c-0.279,0-0.542,0.264-0.542,0.544v7.644v0.121h-0.121H3.856C1.73,8.308,0,10.043,0,12.175
		v13.958c0,0.353,0.279,0.544,0.542,0.544h7.902h0.078l0.032,0.072l0.087,0.197C9.459,28.772,11.344,30,13.333,30
		c2.513,0,4.62-1.764,5.124-4.289l0.053-0.267l0.019-0.097h0.099h16.389h0.101l0.017,0.1l0.048,0.275
		C35.617,28.201,37.771,30,40.306,30c2.607,0,4.808-1.971,5.118-4.586l0.034-0.292l0.013-0.107h0.107h2.88
		c0.279,0,0.542-0.264,0.542-0.544V0.544C49,0.264,48.737,0,48.458,0z M44.587,7.056c0,1.368-1.091,1.384-1.101,1.384h-0.13
		c-0.011,0-1.13-0.016-1.129-1.384l0-1.124l-0.274,0c-0.036,0-0.361,0.019-0.361,0.493v0.505c0,0.509,0.35,0.527,0.365,0.527
		L41.957,8.33c-0.011,0-1.148-0.017-1.147-1.401V6.424c0-1.292,1.006-1.392,1.314-1.392l2.498,0.001l0,0.9h-0.255
		C44.453,6.322,44.587,6.941,44.587,7.056z M44.62,8.865l0,0.973l-3.853-0.001l0-0.968L44.62,8.865z M41.56,10.338v0.943l1.889,0
		c0.039-0.001,0.384-0.025,0.384-0.467v-0.476h0.786v0.493c0,1.402-1.158,1.418-1.171,1.418l-1.889,0l0,0.631l-0.8,0v-0.631
		l-1.013,0l0-0.967l1.013,0l0-0.943H41.56z M41.936,17.229l2.682,0v0.976l-2.682-0.001c-0.013,0-1.171-0.016-1.171-1.427v-0.631
		c0-1.41,1.159-1.426,1.171-1.426l2.682-0.005v0.974l-2.696-0.001c-0.031,0.001-0.314,0.026-0.314,0.459l0,0.631
		C41.608,17.216,41.902,17.229,41.936,17.229z M40.766,14.189l0-0.968l3.853,0.001l0,0.967L40.766,14.189z M39.995,9.83
		c-0.263,0-0.477-0.214-0.477-0.478c0-0.264,0.214-0.478,0.477-0.478c0.264,0,0.477,0.214,0.477,0.479
		C40.472,9.616,40.259,9.83,39.995,9.83z M39.995,13.228c0.264,0,0.477,0.214,0.477,0.478c0,0.263-0.213,0.478-0.477,0.478
		c-0.263,0-0.477-0.214-0.477-0.478C39.519,13.442,39.732,13.228,39.995,13.228z M39.748,18.724h4.874v0.938h-4.874V18.724z
		 M39.748,4.49V3.551h4.874V4.49H39.748z M39.869,2.982c-0.197,0-0.358-0.161-0.358-0.36c0-0.198,0.16-0.36,0.358-0.359
		c0.198,0,0.359,0.161,0.359,0.36C40.228,2.821,40.067,2.982,39.869,2.982z M8.218,24.871v0.665v0.121H8.097H1.205H1.084v-0.121
		v-6.514V18.9h0.121h4.772c0.676,0,1.205-0.531,1.205-1.208v-3.988c0-0.678-0.529-1.208-1.205-1.208H1.186H1.058l0.007-0.128
		l0.019-0.35c0.08-1.456,1.271-2.555,2.771-2.555h10.206h0.121v0.121v9.97v0.121h-0.121h-0.663
		C10.542,19.674,8.218,22.005,8.218,24.871z M1.084,17.813v-0.121v-3.988v-0.121h0.121h4.772h0.121v0.121v3.988v0.121H5.977H1.205
		H1.084z M13.399,28.979c-2.221,0-4.097-1.882-4.097-4.109c0-2.227,1.876-4.109,4.097-4.109c2.259,0,4.097,1.843,4.097,4.109
		C17.496,27.098,15.62,28.979,13.399,28.979z M35.011,24.327H18.622h-0.101l-0.018-0.1l-0.049-0.274
		c-0.298-1.678-1.414-3.093-2.985-3.785l-0.198-0.088L15.2,20.048v-0.079V1.275V1.154h0.121h19.362l0.367,23.173H35.011z
		 M40.306,28.913c-2.221,0-4.097-1.882-4.097-4.109s1.876-4.109,4.097-4.109c2.221,0,4.097,1.882,4.097,4.109
		C44.403,27.069,42.565,28.913,40.306,28.913z"
                  />
                  <path
                    d="M13.399,23.541c-0.03-0.002-0.061-0.003-0.091-0.003c-0.307,0-0.583,0.11-0.802,0.315
		c-0.275,0.256-0.432,0.627-0.432,1.017c0,0.745,0.582,1.329,1.325,1.329s1.325-0.584,1.325-1.329
		C14.724,24.125,14.142,23.541,13.399,23.541z M13.399,25.535c-0.384,0-0.663-0.28-0.663-0.665c0-0.385,0.279-0.665,0.663-0.665
		c0.384,0,0.663,0.28,0.663,0.665C14.062,25.255,13.783,25.535,13.399,25.535z"
                  />
                  <path
                    d="M40.307,23.474c-0.743,0-1.326,0.584-1.326,1.329c0,0.745,0.583,1.329,1.326,1.329
		c0.743,0,1.325-0.584,1.325-1.329C41.632,24.059,41.049,23.474,40.307,23.474z M40.307,25.468c-0.384,0-0.663-0.28-0.663-0.665
		c0-0.385,0.279-0.665,0.663-0.665c0.384,0,0.663,0.28,0.663,0.665C40.969,25.189,40.69,25.468,40.307,25.468z"
                  />
                  <path
                    d="M39.869,2.315c-0.169,0-0.306,0.137-0.306,0.307c0,0.17,0.137,0.307,0.306,0.307
		c0.17,0,0.307-0.137,0.307-0.307C40.176,2.452,40.039,2.315,39.869,2.315z M40.075,2.503l-0.181,0.124v0.074l0.181,0v0.052h-0.413
		V2.593c0-0.094,0.041-0.131,0.115-0.131c0.076,0,0.108,0.049,0.117,0.105l0.181-0.124V2.503z"
                  />
                  <path d="M39.776,2.515c-0.053,0-0.069,0.048-0.069,0.093v0.093h0.14V2.634C39.848,2.577,39.845,2.515,39.776,2.515z" />
                  <path
                    d="M43.01,5.932v1.124c0,0.456,0.305,0.475,0.341,0.476l0.136,0c0.031-0.001,0.314-0.019,0.318-0.467
		c-0.078-0.403-0.18-0.921-0.225-1.133H43.01z"
                  />
                </g>
              </svg>
            }
          >
            clients servis
          </KpiBlock>
          <KpiBlock
            figure="660 000"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 39">
                <g>
                  <path
                    d="M32.809,19.035c-0.797-2.343-1.646-4.664-2.654-6.924c-0.397-0.891-0.783-1.788-1.371-2.575
		c-0.533-0.713-1.204-1.201-2.076-1.423c-0.802-0.204-4.155-0.241-5.013-0.175c-0.779,0.06-1.476,0.365-2.052,0.921
		c-0.672,0.648-1.116,1.449-1.501,2.289c-1.203,2.622-2.206,5.324-3.134,8.055c-0.438,1.29,0.399,2.696,1.698,2.89
		c0.182,0.027,0.371,0.004,0.565,0.004c-0.01,0.033-0.018,0.067-0.029,0.099c-0.391,1.158-0.781,2.317-1.172,3.475
		c-0.183,0.54,0.001,0.799,0.571,0.799c1.101,0.001,2.202,0,3.303,0c0.074,0,0.148,0,0.223,0c0.005,0.075,0.01,0.118,0.011,0.16
		c0.028,2.357,0.055,4.714,0.083,7.071c0.013,1.143-0.002,2.287,0.049,3.428c0.057,1.258,1.2,2.099,2.402,1.817
		c0.514-0.12,0.936-0.395,1.28-0.814c0.406,0.46,0.887,0.763,1.483,0.836c0.88,0.108,1.592-0.191,1.938-1.001
		c0.132-0.309,0.199-0.67,0.204-1.009c0.048-3.379,0.077-6.759,0.112-10.139c0.001-0.074,0.008-0.149,0.013-0.234
		c0.1,0,0.177,0,0.253,0c1.108,0,2.216,0,3.324,0c0.499,0,0.698-0.283,0.536-0.764c-0.293-0.87-0.589-1.74-0.884-2.61
		c-0.115-0.339-0.228-0.678-0.347-1.033c0.056-0.003,0.083-0.009,0.109-0.006c0.973,0.097,1.715-0.476,2.054-1.182
		C33.097,20.343,33.038,19.706,32.809,19.035z M31.043,21.096c-0.325,0-0.556-0.182-0.731-0.434
		c-0.103-0.149-0.189-0.317-0.246-0.489c-0.72-2.151-1.433-4.304-2.149-6.457c-0.107-0.321-0.342-0.494-0.6-0.445
		c-0.337,0.064-0.501,0.37-0.385,0.733c0.171,0.531,0.348,1.06,0.522,1.59c0.491,1.489,0.969,2.983,1.476,4.467
		c0.582,1.704,1.191,3.4,1.787,5.099c0.03,0.086,0.055,0.173,0.089,0.285c-0.098,0-0.173,0-0.249,0c-1.031,0-2.062,0-3.093,0
		c-0.474,0-0.643,0.171-0.648,0.654c-0.037,3.536-0.073,7.073-0.11,10.609c-0.001,0.135,0.002,0.272-0.023,0.403
		c-0.073,0.383-0.245,0.703-0.646,0.812c-0.469,0.127-0.873-0.019-1.204-0.365c-0.288-0.3-0.352-0.683-0.352-1.083
		c0-1.221,0-2.443,0-3.664c0-2.187,0-4.375,0-6.562c0-0.092,0.001-0.185-0.007-0.277c-0.024-0.294-0.214-0.472-0.511-0.482
		c-0.287-0.009-0.504,0.171-0.541,0.456c-0.011,0.084-0.006,0.17-0.006,0.255c0,3.423-0.001,6.846,0,10.269
		c0,0.495-0.167,0.91-0.583,1.183c-0.571,0.375-1.557,0.313-1.64-0.812c-0.005-0.064-0.006-0.128-0.006-0.192
		c-0.03-3.536-0.061-7.073-0.091-10.609c0-0.036,0-0.071-0.001-0.107c-0.011-0.395-0.198-0.585-0.588-0.586
		c-1.052-0.002-2.104-0.001-3.156-0.001c-0.077,0-0.153,0-0.264,0c0.255-0.752,0.496-1.467,0.739-2.182
		c0.271-0.796,0.545-1.591,0.816-2.387c0.768-2.26,1.534-4.521,2.301-6.782c0.016-0.047,0.031-0.094,0.044-0.142
		c0.085-0.308-0.044-0.581-0.316-0.67c-0.29-0.095-0.554,0.044-0.662,0.36c-0.173,0.508-0.339,1.019-0.509,1.528
		c-0.559,1.683-1.11,3.37-1.685,5.047c-0.081,0.237-0.245,0.469-0.427,0.642c-0.558,0.532-1.324,0.104-1.496-0.614
		c-0.045-0.19-0.044-0.421,0.02-0.603c0.612-1.725,1.208-3.456,1.88-5.157c0.49-1.24,1.063-2.449,1.646-3.649
		c0.197-0.405,0.503-0.77,0.816-1.099c0.497-0.523,1.163-0.68,1.855-0.732c0.273-0.02,0.547-0.044,0.819-0.032
		c0.24,0.011,2.829,0.107,3.383,0.179c0.849,0.109,1.48,0.612,1.895,1.343c0.414,0.728,0.789,1.484,1.125,2.252
		c0.994,2.273,1.845,4.603,2.65,6.951C32.228,20.315,31.739,21.095,31.043,21.096z"
                  />
                  <path
                    d="M12.884,11.225c-0.164-1.077-0.672-1.945-1.615-2.515c-0.992-0.599-2.099-0.774-3.224-0.818
		c-0.98-0.038-1.962-0.013-2.943-0.007C4.37,7.889,3.649,7.996,2.945,8.19c-1.983,0.547-2.881,1.843-2.914,3.671
		c-0.055,2.975-0.021,5.951-0.01,8.927c0.002,0.626,0.26,1.167,0.751,1.554c0.568,0.448,1.192,0.532,1.868,0.146
		c0.006,0.092,0.012,0.147,0.013,0.203c0.016,2.017,0.033,4.034,0.047,6.051c0.019,2.727,0.025,5.454,0.058,8.181
		c0.01,0.806,0.314,1.492,1.078,1.857c0.779,0.372,1.526,0.256,2.217-0.25c0.161-0.118,0.303-0.264,0.456-0.4
		c0.059,0.064,0.111,0.121,0.165,0.177c0.675,0.697,1.738,0.896,2.504,0.447c0.757-0.444,1.062-1.138,1.067-2.009
		c0.029-4.68,0.068-9.36,0.104-14.04c0.001-0.08,0.009-0.16,0.014-0.248c0.959,0.396,1.72,0.186,2.263-0.618
		c0.265-0.393,0.356-0.837,0.355-1.311c-0.005-2.649,0.002-5.298-0.006-7.947C12.974,12.128,12.952,11.671,12.884,11.225z
		 M11.563,21.529c-0.296,0.258-0.606,0.219-0.821-0.108c-0.244-0.371-0.286-0.798-0.287-1.224c-0.007-1.917-0.004-3.835-0.004-5.752
		c0-0.185,0-0.369,0-0.554c0-0.443-0.154-0.642-0.494-0.642c-0.317,0-0.514,0.242-0.518,0.636c-0.029,3.061-0.059,6.121-0.087,9.182
		C9.31,27.512,9.27,31.957,9.229,36.403c-0.001,0.163,0.009,0.327-0.001,0.49c-0.024,0.409-0.14,0.78-0.531,0.973
		c-0.403,0.198-0.796,0.113-1.152-0.136c-0.399-0.279-0.539-0.693-0.539-1.163c-0.002-2.834-0.001-5.667-0.001-8.501
		c0-1.754,0-3.508-0.001-5.262c0-0.12,0.005-0.245-0.024-0.359c-0.061-0.238-0.299-0.361-0.588-0.327
		c-0.224,0.027-0.399,0.225-0.401,0.471c-0.004,0.547-0.001,1.094-0.001,1.64c0,1.804,0,3.608,0,5.412
		c0,2.315,0.001,4.63-0.001,6.945c-0.001,0.703-0.369,1.186-1.018,1.35c-0.552,0.14-1.064-0.179-1.164-0.744
		c-0.041-0.229-0.048-0.466-0.05-0.699c-0.047-5.596-0.09-11.192-0.138-16.788c-0.017-1.96-0.044-3.92-0.063-5.88
		c-0.002-0.242-0.072-0.437-0.3-0.536c-0.176-0.076-0.356-0.068-0.519,0.05c-0.174,0.127-0.197,0.313-0.197,0.511
		c0.001,2.017,0.007,4.034-0.005,6.051c-0.002,0.365-0.07,0.731-0.129,1.093c-0.021,0.128-0.091,0.253-0.159,0.367
		c-0.204,0.339-0.508,0.385-0.811,0.125c-0.285-0.245-0.414-0.559-0.413-0.938c0.002-2.834-0.003-5.667,0.005-8.501
		c0.001-0.288,0.047-0.58,0.108-0.862c0.22-1.022,0.884-1.627,1.837-1.94c0.671-0.22,1.367-0.321,2.067-0.329
		c1.155-0.014,2.313-0.009,3.466,0.044c0.704,0.033,1.404,0.172,2.044,0.508c0.861,0.453,1.273,1.206,1.369,2.154
		c0.038,0.373,0.044,0.751,0.045,1.127c0.004,2.628,0.001,5.255,0.003,7.883C11.968,20.998,11.833,21.293,11.563,21.529z"
                  />
                  <path
                    d="M6.641,7.598c2.001-0.054,3.62-1.714,3.599-3.756C10.265,1.658,8.519-0.02,6.468,0
		C4.393,0.02,2.714,1.74,2.741,3.835C2.77,6.004,4.533,7.654,6.641,7.598z M6.503,1.03c1.52,0.007,2.717,1.209,2.719,2.729
		c0.002,1.588-1.185,2.808-2.731,2.807c-1.52-0.001-2.742-1.238-2.736-2.77C3.761,2.264,4.994,1.023,6.503,1.03z"
                  />
                  <path
                    d="M23.932,7.595c2.001,0.015,3.752-1.609,3.76-3.788C27.7,1.728,26.024,0,24.01,0
		c-2.141,0-3.813,1.667-3.812,3.803C20.199,5.909,21.856,7.579,23.932,7.595z M23.946,1.03c1.509,0.001,2.745,1.259,2.735,2.783
		c-0.01,1.541-1.23,2.76-2.754,2.753c-1.527-0.007-2.72-1.229-2.715-2.783C21.217,2.258,22.439,1.028,23.946,1.03z"
                  />
                </g>
              </svg>
            }
          >
            personnes habillées
          </KpiBlock>
          <KpiBlock
            figure="6,7 millions"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47 39">
                <g>
                  <path
                    d="M46.909,12.862c-3.028-2.362-6.055-4.725-9.085-7.084c-0.157-0.122-0.327-0.249-0.512-0.31
		c-0.679-0.223-1.368-0.418-2.053-0.625c-0.516-0.156-1.031-0.316-1.593-0.488c0.245-0.226,0.462-0.426,0.68-0.627
		c-0.044-0.056-0.063-0.086-0.088-0.111c-1.187-1.175-2.373-2.351-3.566-3.521c-0.062-0.06-0.178-0.093-0.269-0.093
		c-4.615-0.004-9.23-0.005-13.846,0.002c-0.106,0-0.237,0.06-0.314,0.135c-0.603,0.583-1.196,1.177-1.791,1.768
		c-0.6,0.596-1.199,1.192-1.846,1.836c0.224,0.207,0.443,0.409,0.67,0.619c-0.064,0.023-0.111,0.041-0.16,0.055
		c-1.141,0.346-2.282,0.691-3.422,1.041C9.6,5.493,9.483,5.545,9.389,5.617C6.575,7.806,3.763,9.998,0.952,12.19
		C0.634,12.438,0.318,12.687,0,12.937c1.998,2.968,3.982,5.916,5.973,8.874c1.931-0.99,3.844-1.972,5.789-2.97
		c0,6.74,0,13.444,0,20.159c7.831,0,15.641,0,23.472,0c0-6.718,0-13.422,0-20.162c1.945,0.997,3.859,1.978,5.795,2.97
		c1.998-2.968,3.984-5.919,5.971-8.871C46.953,12.899,46.931,12.88,46.909,12.862z M30.641,1.517
		c0.697,0.706,1.432,1.45,2.177,2.204c-0.714,0.701-1.44,1.414-2.18,2.141c-0.993-0.764-2.148-1.091-3.382-0.983
		C28.366,3.777,29.5,2.651,30.641,1.517z M29.542,1.053c-0.365,0.359-0.691,0.676-1.014,0.996c-1.057,1.048-2.112,2.099-3.17,3.146
		C24.677,5.87,23.975,6.526,23.5,7.407c-0.565-1.041-1.437-1.784-2.238-2.587c-1.195-1.196-2.399-2.384-3.598-3.576
		c-0.054-0.054-0.105-0.111-0.18-0.192C21.508,1.053,25.496,1.053,29.542,1.053z M16.377,1.499c1.135,1.126,2.269,2.251,3.41,3.383
		c-1.28-0.107-2.426,0.222-3.433,0.985c-0.734-0.728-1.461-1.449-2.183-2.165C14.907,2.967,15.648,2.228,16.377,1.499z
		 M6.326,20.458c-1.635-2.428-3.268-4.853-4.913-7.294c0.348-0.272,0.689-0.537,1.054-0.822c1.684,2.499,3.361,4.987,5.053,7.498
		C7.121,20.047,6.732,20.248,6.326,20.458z M34.194,17.121c0,6.957,0,13.89,0,20.838c-7.132,0-14.24,0-21.374,0
		c0-6.933,0-13.868,0-20.843c-1.473,0.76-2.909,1.5-4.357,2.246c-1.723-2.559-3.438-5.106-5.164-7.668
		c0.391-0.307,0.776-0.61,1.163-0.912C6.263,9.377,8.065,7.971,9.87,6.569C9.959,6.5,10.065,6.443,10.172,6.41
		c1.265-0.385,2.533-0.761,3.798-1.148c0.146-0.045,0.237-0.022,0.342,0.086c0.652,0.667,1.31,1.327,1.974,1.997
		c0.269-0.242,0.494-0.478,0.751-0.671c1.12-0.838,2.353-1.019,3.647-0.489c1.302,0.534,2.047,1.533,2.257,2.918
		c0.032,0.213,0.035,0.431,0.036,0.647c0.003,1.232,0.002,2.465,0.002,3.697c0,0.085,0,0.171,0,0.273c0.355,0,0.69,0,1.052,0
		c0-0.093,0-0.177,0-0.261c0-1.297,0.01-2.594-0.002-3.892c-0.014-1.618,1.073-3.094,2.701-3.53
		c1.248-0.335,2.381-0.069,3.376,0.754c0.2,0.165,0.384,0.348,0.634,0.575c0.053-0.079,0.094-0.169,0.159-0.234
		c0.598-0.598,1.204-1.19,1.8-1.79c0.1-0.1,0.182-0.126,0.32-0.084c1.272,0.388,2.547,0.767,3.819,1.154
		c0.101,0.031,0.202,0.083,0.285,0.147c2.169,1.688,4.335,3.379,6.503,5.069c0.023,0.018,0.051,0.029,0.089,0.05
		c-1.732,2.572-3.446,5.118-5.175,7.684C37.095,18.618,35.659,17.877,34.194,17.121z M40.676,20.457
		c-0.411-0.212-0.795-0.41-1.196-0.617c1.686-2.506,3.362-4.997,5.052-7.509c0.355,0.278,0.7,0.549,1.058,0.829
		C43.95,15.595,42.318,18.018,40.676,20.457z"
                  />
                </g>
              </svg>
            }
          >
            de vêtements en circulation
          </KpiBlock>
        </KpiBlocks>
      </Main>
    </Article>
  );
}
