import React from "react";
import styled from "styled-components";
import Slider from "../../components/Slider";
import NavLink from "react-router-dom/NavLink";

import { get, media } from "../../css/util";

export const CategoriesSlider = styled(Slider)`
  margin: ${get("spacing.padding")} 0;
  .slick-list {
    text-align: left;
  }
  ${media.largeWidth`
    margin-left: -15%;
    margin-right: -15%;
  `};
`;

export const CategoryLink = styled(function({ icon, children, ...props }) {
  return (
    <NavLink {...props}>
      {icon}
      <span>{children}</span>
    </NavLink>
  );
})`
  ${get("style.link")};
  display: block;
  margin: ${get("spacing.paddingHalf")} 0;
  text-align: center;
  vertical-align: top;
  ${media.bigHeight`
    margin: ${get("spacing.padding")} 0;
  `};

  > span {
    ${get("font.menu.active")};
    display: block;
    height: 2em;
    line-height: 1em;
    margin-top: 1em;
    padding: ${get("spacing.paddingHalf")};
  }
`;
