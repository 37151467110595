import { apolloClient } from "../apollo";
import builder from "./util";

export default builder({
  getInitialState: () => ({
    user: null,
    authenticated: false
  }),
  actions: {
    isAuthenticated: ({ user }) => (authenticated = true) => ({
      authenticated,
      user
    }),
    login: () => user => ({
      authenticated: true,
      user
    }),
    logout: () => () => (apolloClient.clearStore(), { authenticated: false, user: null }),
    updateUser: ({ user }) => userUpdated => ({
      authenticated: true,
      user: { ...user, ...userUpdated }
    })
  }
});
