import jwt from "jsonwebtoken";
import _pick from "lodash/pick";
import React from "react";
import { graphql } from "react-apollo";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";
import { compose, withHandlers, withState } from "recompose";

import { memberValidateMutation } from "../../graphql/mutations";
import authService from "../../stores/auth";
import { Explanation, GoogleLoginButton, Layout, Logo, Title } from "./style";

export default compose(
  withState("error", "setError"),
  connect(
    ({ auth }) => auth,
    dispatch => ({
      isAuthenticated: (authenticated = true) =>
        dispatch(authService.isAuthenticated(authenticated))
    })
  ),

  // Validate account function -> error OR isloggedin
  graphql(memberValidateMutation, {
    props: ({ mutate: memberValidate, ownProps: { setError } }) => ({
      login: async memberData => {
        const member = {
          googleId: memberData.sub,
          email: memberData.email,
          name: memberData.name,
          firstname: memberData.given_name,
          lastname: memberData.family_name
        };
        try {
          const { data } = await memberValidate({ variables: { member } });
          const tokens = {
            ...data.tokens,
            expires_at: new Date().getTime() + data.tokens.expires_in * 1000
          };
          localStorage.setItem("tokens", JSON.stringify(tokens || null));
          return tokens;
        } catch (err) {
          setError(err.graphQLErrors[0].message);
          console.error(err);
          return false;
        }
      }
    })
  }),
  withHandlers({
    onSuccess: ({ login, isAuthenticated }) => async response => {
      if (!response) return;
      const { credential } = response;
      if (!credential) return;
      const profileObj = jwt.decode(credential);
      const res = await login(profileObj);
      isAuthenticated(res);
    },
    onError: () => response => {
      console.error(response);
    }
  })
)(function Login({ authenticated, error, location, onSuccess, onError }) {
  const { from } = location.state || { from: { pathname: "/" } };

  if (!!error) {
    return (
      <Redirect
        to={{ pathname: "/connexion", state: { from, messages: { error } } }}
      />
    );
  }

  if (!!authenticated) {
    return <Redirect to={from} />;
  }

  return (
    <Layout>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <Title>Catalogue Interactif Textile</Title>
      <Logo alt="Initial" />
      <GoogleLoginButton
        accessType="offline"
        onSuccess={onSuccess}
        onError={onError}
      >
        Connectez-vous
      </GoogleLoginButton>
      <Explanation>
        Connectez-vous grâce à votre compte Google Initial et accédez au
        Catalogue Interactif Textile
      </Explanation>
    </Layout>
  );
});
