import React from "react";

import { Modal, CloseButton, Image } from "./style";
import { media } from "../../util";
import Slider from "../Slider";

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 250,
};

export default function ({ isOpen, onClose, images, selectedSlide, alt }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel={alt}>
      <CloseButton onClick={onClose}>Fermer</CloseButton>
      <Slider {...sliderSettings} initialSlide={selectedSlide}>
        {images.map((image) => (
          <Image src={media(image, "pin-detail")} alt={alt} />
        ))}
      </Slider>
    </Modal>
  );
}
