import React from "react";
import styled from "styled-components";

import { get } from "../../css/util";

export const Container = styled(function({ src, mode, ...props }) {
  return <div {...props} />;
})`
  background: transparent none;
  background-image: url(${({ src }) => (src || "").replace(/['"]/, "\\$1")});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${get("mode", "cover")};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;
