import React from "react";

import { Link, Figure, Title } from "./style";

export default function CollectionLink({ prefix, image, children, ...props }) {
  return (
    <Link {...props}>
      <Figure>{image}</Figure>
      <Title /*prefix={prefix}*/>{children}</Title>
    </Link>
  );
}
