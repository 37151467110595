import React, { Fragment } from "react";
import {
  compose,
  branch,
  renderComponent,
  renderNothing,
  withState,
  withStateHandlers,
  withHandlers,
  lifecycle,
  pure
} from "recompose";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";

import DataLoader from "../DataLoader";

import { isLocalhost } from "../../registerServiceWorker";
import applicationService from "../../stores/application";

import dataLoader from "../../data-loader";
import filesLoader from "../../files-loader";

import iconDelete from "./icons/cloud-delete.svg";
import iconDownload from "./icons/cloud-download.svg";
import iconUpdate from "./icons/cloud-update.svg";

import { Button, Text } from "./style";

const versionNumberQuery = gql`
  query Version {
    versionNumber
  }
`;

export default compose(
  connect(
    ({ application, status }) => ({ ...application, ...status }),
    dispatch => ({
      isInstalled: version => dispatch(applicationService.isInstalled(version)),
      isUninstalled: () => dispatch(applicationService.isUninstalled()),
      hasUpdated: version => dispatch(applicationService.hasUpdated(version))
    })
  ),
  branch(() => {
    // Pas de bouton si pas de Service Worker ou qu'on est en localhost
    if (!("serviceWorker" in navigator)) return true;
    return !!isLocalhost;
  }, renderNothing),
  branch(
    ({ online }) => !online,
    renderComponent(function() {
      return <Text>Hors ligne</Text>;
    })
  ),
  withState("error", "setError", null),
  withState("installing", "setInstalling", false),
  withStateHandlers(
    {
      dataLoading: { loaded: 0, total: 0 },
      staticFilesLoading: { loaded: 0, total: 0 },
      filesLoading: { loaded: 0, total: 0 }
    },
    {
      setDataLoading: (...state) => dataLoading => ({ ...state, dataLoading }),
      setStaticFilesLoading: (...state) => staticFilesLoading => ({
        ...state,
        staticFilesLoading
      }),
      setFilesLoading: (...state) => filesLoading => ({
        ...state,
        filesLoading
      })
    }
  ),
  graphql(versionNumberQuery, {
    options: { fetchPolicy: "network-only" },
    props: ({
      data: { loading, versionNumber: newVersion, refetch: checkUpdate },
      ownProps: { version }
    }) => ({
      hasUpdate: !loading && newVersion !== version,
      newVersion,
      checkUpdate
    })
  }),
  withHandlers({
    install: ({
      setDataLoading,
      setStaticFilesLoading,
      setFilesLoading,
      isInstalled,
      hasUpdated,
      newVersion,
      setError,
      setInstalling,
      checkUpdate
    }) => async (isUpdate = false) => {
      // const ready = await navigator.serviceWorker.ready;
      // if (!ready) return console.error("No SW ready");
      // if (!ready.active) return console.error("No SW active");
      // if (ready.active.state !== "activated")
      // return console.error("SW not activated!");
      try {
        setError(null);
        setInstalling(true);
        const files = await dataLoader(setDataLoading);
        const host = `${window.location.protocol}//${window.location.host}`;
        await filesLoader(
          [
            "https://fonts.googleapis.com/css?family=Lato:400,700",
            `${host}/images/back-arrow.svg`,
            `${host}/images/bullet-arrow.svg`,
            `${host}/images/calendar.svg`,
            `${host}/images/cross-background.png`,
            `${host}/images/login-background.jpg`,
            `${host}/images/login-header.jpg`,
            `${host}/images/standards/en-342.png`,
            `${host}/images/standards/en-343.png`,
            `${host}/images/standards/en-1149-5.png`,
            `${host}/images/standards/en-13034-6.png`,
            `${host}/images/standards/en-14058.png`,
            `${host}/images/standards/en-14404.png`,
            `${host}/images/standards/iec-61482-2.png`,
            `${host}/images/standards/iso-11611.png`,
            `${host}/images/standards/iso-11612.png`,
            `${host}/images/standards/iso-20471.png`
          ],
          "static.v1",
          (loaded, total) => setStaticFilesLoading({ loaded, total })
        );
        await filesLoader(files, "files.v1", (loaded, total) =>
          setFilesLoading({ loaded, total })
        );
        if (!isUpdate) {
          isInstalled(newVersion);
        } else {
          hasUpdated(newVersion);
        }
        setInstalling(false);
        checkUpdate();
      } catch (err) {
        console.error(err);
        setError(err);
      }
    }
  }),
  withHandlers({
    checkUpdateRegularly: ({ checkUpdate }) => () => {
      return window.setInterval(checkUpdate, 60000);
    },
    update: ({ install }) => () => install(true),
    uninstall: ({ isUninstalled }) => () => {
      isUninstalled();
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.checkUpdateRegularly();
    }
  }),
  pure
)(function UpdateButton({
  installed,
  installing,
  hasUpdate,
  install,
  uninstall,
  update,
  dataLoading,
  filesLoading,
  staticFilesLoading
}) {
  return (
    <Fragment>
      {!!dataLoading.total && (
        <DataLoader {...dataLoading}>Chargement des données&hellip;</DataLoader>
      )}
      {(!!staticFilesLoading.total || !!filesLoading.total) && (
        <DataLoader
          loaded={staticFilesLoading.loaded + filesLoading.loaded}
          total={staticFilesLoading.total + filesLoading.total}
        >
          Chargement des fichiers&hellip;
        </DataLoader>
      )}
      {!!installing ? (
        <Text>Installation&hellip;</Text>
      ) : (
        <Fragment>
          {!installed ? (
            <Button onClick={install} icon={iconDownload}>
              Installer
            </Button>
          ) : (
            <Button onClick={uninstall} icon={iconDelete}>
              Désinstaller
            </Button>
          )}
          {!!installed &&
            !!hasUpdate && (
              <Button onClick={update} icon={iconUpdate}>
                Mettre à jour
              </Button>
            )}
        </Fragment>
      )}
    </Fragment>
  );
});
