const {
  REACT_APP_GRAPHQL_URI,
  REACT_APP_CDN_URI,
  REACT_APP_WEBSITE_URI,
} = process.env;

export default {
  GRAPHQL_URI: REACT_APP_GRAPHQL_URI,
  CDN_URI: REACT_APP_CDN_URI,
  WEBSITE_URI: REACT_APP_WEBSITE_URI,
};
