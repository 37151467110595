import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import {get, mixin, reset} from "../../css/util";

export const Modal = styled(function({ className, ...props }) {
  return (
    <ReactModal
      overlayClassName={className}
      closeTimeoutMS={250}
      className={"Content"}
      {...props}
    />
  );
})`
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 9999;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }

  .Content {
    background-color: ${get("color.white")};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
    // left: 10%;
    left: 50%;
    // max-height: calc(100vh - 72px);
    top: 50%;
    padding: 0;
    position: absolute;
    // right: 10%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    max-width: 720px;
    width: 50%;
  }

  // .ReactModal__Content {
  //   opacity: 0;
  //   transform: scale(0.5) translate(-50%, -50%);
  //   transition: all 350ms ease;
  //   &--after-open {
  //     opacity: 1;
  //     transform: scale(1) translate(-50%, -50%);
  //   }
  //   &--before-close {
  //     transition: all 150ms ease;
  //   }
  // }
`;

export const CloseButton = styled.button`
  ${reset()};
  ${get("font.button")};
  background: ${get("color.white")};
  bottom: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
  color: ${get("color.primary")};
  right: 0;
  padding: 5px 15px;
  position: absolute;
  z-index: 1;
`;

export const Container = styled.div`
  ${reset()};
  background-color: ${get("color.white")};
  padding: ${get('spacing.paddingDouble')};
  position: relative;
  z-index: 10;

  .ReactModal__Content & {
    max-height: 0vh;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    transition: all 350ms ease;
  }
  .ReactModal__Content--after-open & {
    max-height: calc(100vh - 72px);
    padding-top: ${get('spacing.paddingDouble')};
    padding-bottom: ${get('spacing.paddingDouble')};
  }
    // opacity: 0;
    // transform: scale(0.5) translate(-50%, -50%);
    // transition: all 350ms ease;
    // &--after-open {
    //   opacity: 1;
    //   transform: scale(1) translate(-50%, -50%);
    // }
    // &--before-close {
    //   transition: all 150ms ease;
    // }
`;

export const CollectionTitle = styled(function({prefix, children, ...props}) {
  return <h3 {...props}>
    {prefix}{' '}
    <strong>{children}</strong>
  </h3>
})`
  ${reset()};
  ${mixin('font.default', 24)};
  color: ${get('color.primary')};
  > strong {
    ${mixin('font.bold', 24)};
  }
`;

export const Product = styled.div`
  margin-top: ${get('spacing.padding')};
`;

export const ProductTitle = styled.h4`
  ${reset()};
  ${mixin('font.bold', 16)};
  color: ${get('color.text')};
  text-transform: uppercase;
`;

export const Variant = styled.div`
`;

export const VariantAttribute = styled(function({label, children, ...props}) {
  return <div {...props}>
    <strong>{label}</strong>&nbsp;:{' '}
    {children}
  </div>
})`
  ${reset()};
  ${mixin('font.default', 14)};
  color: ${get('color.text')};
  > strong {
    ${mixin('font.bold', 14)};
  }
`;

export const VariantDescription = styled.p`
  ${reset()};
  ${mixin('font.italic', 14)};
  margin: ${get('spacing.paddingHalf')} 0;
`;