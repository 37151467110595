import React from "react";
import Route from "react-router-dom/Route";

import { Layout, Menu, MenuLink } from "./style";

export default function FirstLevelNavigation({
  component: Component,
  ...props
}) {
  return (
    <Route
      {...props}
      render={matchProps => (
        <Layout>
          <Menu>
            <MenuLink to={"/metiers"}>Métiers</MenuLink>
            <MenuLink to={"/collections"}>Collections</MenuLink>
            <MenuLink to={"/focus"}>Dernières collections</MenuLink>
          </Menu>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
}
