import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import withRouter from "react-router/withRouter";
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
  withHandlers
} from "recompose";
import slickCss from "slick-carousel/slick/slick.css";
import { injectGlobal } from "styled-components";

import Messages from "./components/Messages";
import normalize from "./css/normalize.css";
import * as theme from "./css/theme";
import {
  cartSaveAsDownloadedMutation,
  saveCartMutation
} from "./graphql/mutations";
import { memberInfoQuery } from "./graphql/queries";
import asyncComponent from "./hoc/async-component";
import FirstLevelNavigationRoute from "./layouts/FirstLevelNavigation";
import LoggedInLayout from "./layouts/LoggedIn";
import Account from "./pages/Account";
import Categories from "./pages/Categories";
import Category from "./pages/Category";
import Collections from "./pages/Collections";
import CollectionsKind from "./pages/CollectionsKind";
import Error404 from "./pages/Error404";
import FeaturedCollection from "./pages/FeaturedCollection";
import FeaturedCollections from "./pages/FeaturedCollections";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Standards from "./pages/Standards";
import StaticExpertiseInitial from "./pages/static/ExpertiseInitial";
import StaticInnovationTextile from "./pages/static/InnovationTextile";
import authService from "./stores/auth";
import statusService from "./stores/status";
import syncService from "./stores/sync";

injectGlobal`
  ${normalize}
  ${slickCss}
`;

//TODO Delete this
injectGlobal`
  html {
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
  
  body {
    ${theme.font.default(16, 1.5)};
    background: ${theme.color.background};
    color: ${theme.color.text};
    &.ReactModal__Body--open {
      overflow: hidden;
    }
  }
`;

const Cart = asyncComponent(() => import("./pages/Cart"));

const LoggedInMenu = function LoggedInMenu() {
  return (
    <LoggedInLayout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          path="/expertise-initial"
          component={StaticExpertiseInitial}
        />
        <Route
          exact
          path="/innovation-textile"
          component={StaticInnovationTextile}
        />
        <Route exact path="/risques" component={Standards} />
        <Route exact path="/panier" component={Cart} />
        <FirstLevelNavigationRoute
          path="/metiers"
          exact
          component={Categories}
        />
        <Route
          path="/metiers/:category/:kind?/:collection?"
          component={Category}
        />
        <FirstLevelNavigationRoute
          path="/collections"
          exact
          component={Collections}
        />
        <Route
          path="/collections/:kind/:collection?"
          component={CollectionsKind}
        />
        <FirstLevelNavigationRoute
          path="/focus"
          exact
          component={FeaturedCollections}
        />
        <Route path="/focus/:collection" component={FeaturedCollection} />
        <Route path="/mon-compte" component={Account} />
        <Route path="*" component={Error404} />
      </Switch>
    </LoggedInLayout>
  );
};

const PrivateRoute = compose(
  connect(
    ({ auth, status }) => ({
      authenticated: auth.authenticated,
      user: auth.user,
      ...status
    }),
    // ({ auth }) => auth,
    dispatch => ({
      setMember: member => dispatch(authService.login(member))
    })
  ),
  graphql(memberInfoQuery, {
    skip: ({ authenticated, user, online }) =>
      !online || !authenticated || !!user,
    options: () => ({
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data, ownProps: { setMember } }) => {
      if (data.loading || !data.member) return {};
      window.setTimeout(() => setMember(data.member));
      return {};
    }
  }),
  branch(({ authenticated, user }) => !!authenticated && !user, renderNothing) // Loading member data from server
)(function({ authenticated, component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !authenticated ? (
          <Redirect
            to={{
              pathname: "/connexion",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
});

export default compose(
  withRouter, // Mandatory so the redux hoc is forced to update
  connect(
    ({ status, sync, cart }) => ({ ...status, sync, cart }),
    dispatch => ({
      setOnline: () => dispatch(statusService.isOnline()),
      setOffline: () => dispatch(statusService.isOffline()),
      saveOrderOnline: order => dispatch(syncService.saveOrderOnline(order))
    })
  ),
  graphql(cartSaveAsDownloadedMutation, {
    props: ({
      mutate: cartSaveAsDownloaded,
      ownProps: { saveOrderOnline }
    }) => ({
      saveOrder: async order => {
        const res = await cartSaveAsDownloaded({ variables: { cart: order } });
        if (res) saveOrderOnline(order);
      }
    })
  }),
  graphql(saveCartMutation, {
    props: ({ mutate: saveCart, ownProps: { cart } }) => ({
      saveCart: () => saveCart({ variables: { cart } })
    })
  }),
  withHandlers({
    saveOrdersOnline: ({ sync, saveOrder }) => () => {
      (sync.orders || []).forEach(async ({ online, order }) => {
        if (online) return;
        await saveOrder(order);
      });
    }
  }),
  lifecycle({
    componentDidMount() {
      const { setOnline, setOffline, saveOrdersOnline, saveCart } = this.props;
      const online = navigator.onLine;
      if (online) {
        setOnline();
        saveOrdersOnline();
        saveCart();
      } else {
        setOffline();
      }
      window.addEventListener("online", () => {
        setOnline();
        saveOrdersOnline();
        saveCart();
      });
      window.addEventListener("offline", setOffline);

      /* Global site tag (gtag.js) - Google Analytics*/
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-75408042-22");
    }
  })
)(function App({ online }) {
  return (
    <GoogleOAuthProvider 
    clientId="264668796132-pn6ep6n4vcufk6ov90b17rppjde9dpv1.apps.googleusercontent.com">
    <Fragment>
      <Helmet defaultTitle="Initial" titleTemplate="Initial - %s">
        <html lang="fr" className={online ? "online" : "offline"} />
        <meta charSet="utf-8" />
      </Helmet>
      <Messages />
      <Switch>
        <Route path="/connexion" component={Login} />
        <PrivateRoute path="/" component={LoggedInMenu} />
      </Switch>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-75408042-22"
      />
    </Fragment>
    </GoogleOAuthProvider>
  );
});
