import React, { Fragment } from "react";
import _map from "lodash/map";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import categoriesService from "../../stores/categories";
import loading from "../../hoc/loading";

import CategoryIcon from "../../components/CategoryIcon";
import { CategoriesSlider, CategoryLink } from "./style";
import { categoriesQuery } from "../../graphql/queries";

const sliderSettings = {
  arrows: false,
  dots: true,
  rows: 3,
  slidesPerRow: 3
};

export default compose(
  connect(
    ({ categories }) => categories,
    dispatch => ({
      goToPage: page => dispatch(categoriesService.categoriesChangePage(page))
    })
  ),
  graphql(categoriesQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({ data: { loading, categories = {} } }) => {
      return {
        loading,
        categories: _map((categories || {}).edges, "node"),
        total: categories.totalCount
      };
    }
  }),
  loading()
)(function Categories({
  categories = [],
  page,
  // after,
  // limit,
  // total,
  goToPage
}) {
  return (
    <Fragment>
      <Helmet>
        <title>Métiers</title>
      </Helmet>
      <CategoriesSlider
        {...sliderSettings}
        initialSlide={page - 1}
        afterChange={newPage => goToPage(newPage + 1)}
      >
        {categories.map(({ id, name, slug, icon }) => (
          <CategoryLink
            key={id}
            to={`/metiers/${slug}`}
            icon={!!icon && <CategoryIcon icon={icon} />}
          >
            {name}
          </CategoryLink>
        ))}
      </CategoriesSlider>
    </Fragment>
  );
});
