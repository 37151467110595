import "react-app-polyfill/ie11";

import _debounce from "lodash/debounce";
import _isEqual from "lodash/isEqual";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import ReactModal from "react-modal";
import { Provider as ReduxProvider } from "react-redux";
import BrowserRouter from "react-router-dom/BrowserRouter";
import { createStore } from "redux";
import { ThemeProvider } from "styled-components";

import { apolloClient } from "./apollo";
import App from "./App";
import * as theme from "./css/theme";
import { saveCartMutation } from "./graphql/mutations";
import registerServiceWorker from "./registerServiceWorker";
import reducer from "./stores";
import { load, save } from "./stores/localStorage";

ReactModal.setAppElement(document.body);

const reduxState = window.__REDUX_STATE__;
delete window.__REDUX_STATE__;
const store = createStore(
  reducer,
  load() || reduxState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const saveCartLocally = _debounce(save, 257); // prime number — guess why ;)

let oldCart = {};
const saveCartOnline = _debounce(async function (cart) {
  if (_isEqual(cart, oldCart)) return;
  const online = navigator.onLine;
  if (!online) return;
  oldCart = cart;
  return apolloClient.mutate({
    mutation: saveCartMutation,
    variables: { cart },
  });
}, 997); // prime number — guess why ;)

store.subscribe(async function () {
  const { cart, auth, application, sync } = store.getState();
  saveCartLocally({ cart, auth, application, sync });
  await saveCartOnline(cart);
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </ReduxProvider>,
  document.getElementById("root")
);

registerServiceWorker();
