import React from "react";

import { Color, MainColor, SecondaryColor } from "./style";

export default function ColorBadge({ name, colors = [], kind, size = "32px", ...props }) {
  if (!colors || !colors.length) return null;
  return (
    <Color size={size} title={name} {...props}>
      <MainColor size={size} color={colors[0] || "#fff"} />
      {kind === "duo" && (
        <SecondaryColor size={size} color={colors[1] || "#fff"} />
      )}
    </Color>
  );
}
