import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import Standards from "../../components/Standards";

import { Layout, Title } from "./style";

export default function() {
  return (
    <Layout
      header={
        <Fragment>
          <Title>Les risques</Title>
        </Fragment>
      }
    >
      <Helmet>
        <title>Les risques</title>
      </Helmet>
      <Standards />
    </Layout>
  );
}
