import _map from "lodash/map";

import builder from "./util";

export default builder({
  getInitialState: () => ({
    orders: []
  }),
  actions: {
    registerOrder: ({ orders }) => order => ({
      orders: [...orders, { online: null, offline: new Date(), order }]
    }),
    saveOrderOnline: ({ orders }) => order => ({
      orders: _map(orders, orderInfos => {
        if (orderInfos.order !== order) {
          return orderInfos;
        }
        return {
          ...orderInfos,
          online: new Date()
        };
      })
    })
  }
});
