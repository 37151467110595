import { googleLogout } from '@react-oauth/google';
import gql from "graphql-tag";
import _map from "lodash/map";
import React from "react";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { compose } from "recompose";

import { categoriesQuery } from "../../graphql/queries";
import loading from "../../hoc/loading";
import authService from "../../stores/auth";
import {
  Button,
  CloseButton,
  Link,
  MenuList,
  Modal,
  OfflineAlert,
  SubLink
} from "./style";

const memberLogoutMutation = gql`
  mutation MemberLogout {
    memberLogout
  }
`;

export default compose(
  withRouter,
  connect(
    ({ auth, status }) => ({ ...auth, ...status }),
    dispatch => ({
      logout: () => dispatch(authService.logout())
    })
  ),
  graphql(memberLogoutMutation, {
    props: ({ mutate: memberLogout, ownProps: { logout, history } }) => ({
      logout: async () => {
        const res = await memberLogout();
        if (!res) {
          console.error(`Impossible de se déconnecter du serveur.`);
        }
        localStorage.setItem("tokens", null);
        logout();
        googleLogout();
        history.push("/");
      }
    })
  }),
  graphql(categoriesQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({ data: { loading, categories = {} } }) => {
      return {
        loading,
        categories: _map((categories || {}).edges, "node")
      };
    }
  }),
  loading()
)(function Menu({ online, isOpen, onClose, logout, categories }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <CloseButton onClick={onClose}>Fermer</CloseButton>
      <MenuList>
        <Link to={"/"}>Accueil</Link>
        <Link to={"/expertise-initial"}>L'expertise Initial</Link>
        <Link to={"/innovation-textile"}>L'innovation textile</Link>

        <Link to={"/metiers"}>Métiers</Link>
        <SubLink to={"/metiers"}>Tous les métiers</SubLink>
        {_map(categories, ({ id, name, slug }) => (
          <SubLink key={id} to={`/metiers/${slug}`}>
            {name}
          </SubLink>
        ))}

        <Link to={"/collections"}>Collections</Link>
        <SubLink to={"/collections"}>Toutes les collections</SubLink>
        <SubLink to={"/collections/clothing"}>Vêtements</SubLink>
        <SubLink to={"/collections/linen"}>Linges</SubLink>
        <SubLink to={"/collections/carpet"}>Tapis</SubLink>
        <SubLink to={"/collections/accessory"}>Accessoires</SubLink>

        <Link to={"/focus"}>Dernières collections</Link>

        <Link to={"/risques"}>Les risques</Link>

        <Link to={"/mon-compte"}>Utilisateur</Link>
        <SubLink to={"/mon-compte"}>Mon compte</SubLink>
        <SubLink to={"/panier"}>Votre sélection</SubLink>
        <Button disabled={!online} onClick={logout}>
          Déconnexion{!online && <OfflineAlert>Hors ligne</OfflineAlert>}
        </Button>
      </MenuList>
    </Modal>
  );
});
