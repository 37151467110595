import React from "react";
import {
  compose,
  withStateHandlers,
  lifecycle,
  branch,
  renderNothing,
  mapProps
} from "recompose";
import withRouter from "react-router-dom/withRouter";

import { Modal, Success, Error } from "./style";

export default compose(
  withRouter,
  branch(
    ({ location }) => !location.state || !location.state.messages,
    renderNothing
  ),
  mapProps(({ location }) => location.state.messages),
  withStateHandlers(
    { isOpen: true },
    {
      onClose: () => () => ({ isOpen: false })
    }
  ),
  lifecycle({
    componentDidMount() {
      const { isOpen, error, onClose } = this.props;
      if (isOpen && !error) {
        window.setTimeout(onClose, 3000);
      }
    }
  })
)(function Messages({ isOpen, onClose, success, error }) {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      {!!success && <Success>{success}</Success>}
      {!!error && <Error>{error}</Error>}
    </Modal>
  );
});
