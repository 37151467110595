import React from "react";
import styled from "styled-components";

import { Section } from "../../components/Container/style";
import {get} from "../../css/util";

export const Layout = styled(function({header, className, ...props}) {
  return <article className={className}>
    <header>
      <Section>{header}</Section>
    </header>
    <main>
      <Section {...props} />
    </main>
  </article>
})`
  background: rgb(234, 234, 234);
  height: 100%;

  > header {
    padding: ${get('spacing.paddingDouble')} 0;
  }
`;

export const Title = styled.h1`
  ${get('font.headline')};
  text-align: center;
`;