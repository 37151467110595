import gql from "graphql-tag";
import { MediaFragment } from "./fragments";

export const memberInfoQuery = gql`
  query MemberInfo {
    member: memberInfo {
      id
      googleId
      name
      email
      firstname
      lastname
      company
      job
      phone
    }
  }
`;

export const homeQuery = gql`
  ${MediaFragment}

  query HomeQuery {
    featuredCollections: collectionsFindFeatured {
      id
      name
      slug
      listView {
        ...Media
      }
      featuredView {
        ...Media
      }
    }
  }
`;

export const categoriesQuery = gql`
  ${MediaFragment}

  query Categories {
    categories: categoriesFindPublished(pagination: { limit: 999 }) {
      totalCount
      edges {
        node {
          id
          name
          slug
          icon {
            ...Media
          }
        }
      }
    }
  }
`;

export const categoryQuery = gql`
  ${MediaFragment}

  query Category($slug: String!) {
    category: categoryFetchPublishedBySlug(slug: $slug) {
      id
      name
      slug
      icon {
        ...Media
      }
      background {
        ...Media
      }
      collections {
        id
        name
        slug
        kinds
        listView {
          ...Media
        }
      }
    }
  }
`;

export const featuredCollectionsQuery = gql`
  ${MediaFragment}

  query FeaturedCollections {
    collections: collectionsFindFeatured {
      id
      name
      slug
      listView {
        ...Media
      }
      featuredView {
        ...Media
      }
    }
  }
`;

export const collectionsQuery = gql`
  ${MediaFragment}

  query Collections {
    collections: collectionsFindPublished(pagination: { limit: 999 }) {
      edges {
        node {
          id
          name
          slug
          kinds
          listView {
            ...Media
          }
        }
      }
    }
  }
`;

export const collectionQuery = gql`
  ${MediaFragment}

  query Collection($slug: String!, $kind: String) {
    collection: collectionFetchPublishedBySlug(slug: $slug) {
      id
      name
      slug
      baseline
      preview {
        ...Media
      }
      listView {
        ...Media
      }
      featuredView {
        ...Media
      }
      details {
        ...Media
      }
      orderDetails {
        ...Media
      }
      products(kind: $kind) {
        id
        name
        kind
        fullImage
        combination {
          id
          name
          parts {
            id
            name
          }
        }
        combinationPart {
          id
        }
        properties {
          id
          name
          kind
          required
          tags
          colors {
            id
            name
            kind
            colors
          }
        }
        variants {
          id
          reference
          description
          message
          messageType
          published
          image {
            ...Media
          }
          attributes {
            property
            value
          }
          pins {
            image {
              ...Media
            }
            x
            y
          }
        }
        standards
      }
    }
  }
`;
