import React from "react";
import styled, { css } from "styled-components";
import { get, media, reset, responsiveImg, toggle } from "../../../css/util";

export const Article = styled(function({
  className,
  background,
  backButton,
  children
}) {
  return (
    <article className={className}>
      <div className="Image">{backButton}</div>
      <div className="Content">{children}</div>
    </article>
  );
})`
  ${reset()};
  align-items: stretch;
  display: flex;
  height: 100%;
  justify-content: stretch;
  position: relative;
  width: 100%;
  > .Image {
    ${reset()};
    background: transparent url(${get("background")}) center center/cover;
    border-right: 1px solid ${get("color.lightGrey")};
    flex: 1;
    padding: ${get("spacing.padding")};
  }
  > .Content {
    flex: 2;
    overflow: auto;
  }
`;

export const Header = styled.header`
  padding: ${get("spacing.padding")};
  position: relative;
`;

export const Main = styled.main`
  ${media.largeWidth`
    margin: 0 ${get("spacing.paddingDouble")};
  `};
`;

export const Headline = styled(function({ className, icon, children }) {
  return (
    <div className={className}>
      <div className="Inside">
        {!!icon && <div className="Icon">{icon}</div>}
        <div className="Title">{children}</div>
      </div>
    </div>
  );
})`
  align-items: stretch;
  display: block;
  margin: 0 auto ${get("spacing.paddingHalf")} auto;
  overflow: hidden;
  text-align: center;
  width: 100%;
  > .Inside {
    display: inline-block;
    > .Icon {
      display: inline-block;
      height: 50px;
      padding: 0 ${get("spacing.padding")};
      svg {
        ${responsiveImg(true)};
        height: 100%;
      }
    }
    > .Title {
      display: inline-block;
      text-align: left;
    }
  }
`;

export const Title = styled.h1`
  ${reset()};
  ${get("font.static.title")};
  color: ${get("color.title")};
`;

export const Subtitle = styled.h2`
  ${reset()};
  ${get("font.static.subtitle")};
  color: ${get("color.title")};
`;

export const Baseline = styled.p`
  ${reset()};
  ${get("font.static.baseline")};
  color: ${get("color.primary")};
  margin: 0 ${get("spacing.paddingDouble")};
`;

export const Blocks = styled.section`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  margin: ${get("spacing.paddingHalf")} ${get("spacing.padding")};
`;

export const Block = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 ${get("spacing.padding")};
`;

export const Strong = styled.strong`
  ${get("font.static.strong")};
  display: block;
  margin: ${get("spacing.paddingHalf")} 0;
`;

export const Text = styled.div`
  ${get("font.static.text")};
  color: ${get("color.darkBlue")};
`;

export const Paragraph = styled.p`
  ${reset()};
  margin: ${get("spacing.paddingHalf")} 0;
  > strong {
    color: ${get("color.text")};
  }
`;

export const List = styled.ul`
  ${get("font.static.text")};
  margin: ${get("spacing.paddingHalf")} 0 ${get("spacing.paddingHalf")} 1.5em;
  padding: 0;
`;

export const ListItem = Paragraph.withComponent("li").extend`
  list-style: disc;
  margin: 0;
`;

export const Image = styled(function({ className, src, alt, ...props }) {
  return (
    <figure className={className}>
      <img src={src} alt={alt} {...props} />
    </figure>
  );
})`
  ${reset()};
  display: block;
  line-height: 0;
  > img {
    ${responsiveImg()};
  }
`;

export const KpiBlocks = styled(function({ className, title, children }) {
  return (
    <div className={className}>
      <h3>
        <span>{title}</span>
      </h3>
      <div className="Content">{children}</div>
    </div>
  );
})`
  ${reset()};
  margin: ${get("spacing.padding")};

  > h3 {
    ${reset()};
    ${get("font.static.kpititle")};
    color: ${get("color.text")};
    margin: 0 ${get("spacing.padding")};
    position: relative;

    &:before {
      background: ${get("color.text")};
      content: "";
      height: 1px;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }

    > span {
      background: ${get("color.background")};
      display: block;
      margin: 0 auto;
      padding: 0 ${get("spacing.padding")};
      position: relative;
      text-align: center;
      width: 33.3333%;
    }
  }

  > .Content {
    align-items: stretch;
    display: flex;
    flex-direction: row;
  }
`;

export const KpiBlock = styled(function({ figure, icon, className, children }) {
  return (
    <div className={className}>
      {!!icon && <span className="Icon">{icon}</span>}
      {!!figure && <span className="Figure">{figure}</span>}
      {children}
    </div>
  );
})`
  ${get("font.static.kpitext")};
  color: ${get("color.text")};
  flex: 1;
  margin: 0 ${get("spacing.paddingHalf")};
  text-align: center;
  ${toggle(
    "icon",
    "",
    css`
      margin-top: ${get("spacing.padding")};
    `
  )} > .Icon {
    display: block;
    height: 30px;
    padding: ${get("spacing.paddingHalf")};
    text-align: center;
    > svg {
      fill: ${get("color.primary")};
      max-height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
  > .Figure {
    ${get("font.static.kpifigure")};
    color: ${get("color.primary")};
    display: block;
  }
`;
