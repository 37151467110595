import _map from "lodash/map";

//
// DO NOT FORGET TO CHANGE admin/src/common/types.js FILE TOO!
//
// DO NOT FORGET TO CHANGE admin/src/common/types.js FILE TOO!
//
// DO NOT FORGET TO CHANGE admin/src/common/types.js FILE TOO!
//

export const ProductStandards = [
  "ISO 20471-1",
  "ISO 20471-2",
  "ISO 20471-3",
  "ISO 11611-1",
  "ISO 11611-2",
  "EN 13034-6",
  "ISO 11612",
  "IEC 61482-2",
  "EN 1149-5",
  "EN 342",
  "EN 343",
  "EN 14058",
  "EN 14404",
].sort(function (a, b) {
  // Clever sort
  const [, nameA, numberA] = /^(\w+)\s*(\d+)/.exec(a);
  const [, nameB, numberB] = /^(\w+)\s*(\d+)/.exec(b);
  return !!nameA && nameA === nameB
    ? parseInt(numberA || "0", 10) - parseInt(numberB || "0", 10)
    : a.localeCompare(b);
});

export const ProductStandardsInfos = {
  "ISO 20471-1": {
    name: "ISO 20471-1 - Risque de non visibilité - Classe 1",
    content: "iso-20471",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Visibilité</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 20471-1</text>
            <g transform="translate(9.1 0)">
              <path d="M1.8,32.5H30v1.8H1.8V32.5z M5.1,6c0.3-0.3,0.6-0.6,1-0.9v19H1.8v-5.4C6.1,13.8,5.4,7.8,5.1,6 M15.9,1.8h0.1
                c0.6,0,2.6,0.1,4.9,0.8l-5.1,7.5l-5-7.5c2.3-0.7,4.2-0.8,4.8-0.8H15.9z M30,29.6H1.8V27H30V29.6z M22.8,3.3v20.8h-6.1V11.7l5.8-8.6
                C22.6,3.2,22.7,3.2,22.8,3.3 M9.4,3.1l5.7,8.6v12.4H9V3.3C9.1,3.2,9.3,3.2,9.4,3.1 M30,18.7v5.4h-4.3v-19c0.3,0.3,0.7,0.6,1,0.9
                C26.4,7.8,25.7,13.8,30,18.7 M31.5,17.7C27,12.8,28.5,6,28.5,6l0.1-0.4l-0.3-0.3C24.1,0.3,17.4,0,16.1,0l-0.1,0l-0.2,0
                c-1.3,0-8,0.3-12.2,5.2L3.2,5.5L3.3,6c0,0.1,1.6,6.8-3,11.7L0,18v18.1h31.8V18L31.5,17.7z"/>
            </g>
          </svg>`,
  },
  "ISO 20471-2": {
    name: "ISO 20471-2 - Risque de non visibilité - Classe 2",
    content: "iso-20471",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Visibilité</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 20471-2</text>
            <g transform="translate(9.1 0)">
              <path d="M1.8,32.5H30v1.8H1.8V32.5z M5.1,6c0.3-0.3,0.6-0.6,1-0.9v19H1.8v-5.4C6.1,13.8,5.4,7.8,5.1,6 M15.9,1.8h0.1
                c0.6,0,2.6,0.1,4.9,0.8l-5.1,7.5l-5-7.5c2.3-0.7,4.2-0.8,4.8-0.8H15.9z M30,29.6H1.8V27H30V29.6z M22.8,3.3v20.8h-6.1V11.7l5.8-8.6
                C22.6,3.2,22.7,3.2,22.8,3.3 M9.4,3.1l5.7,8.6v12.4H9V3.3C9.1,3.2,9.3,3.2,9.4,3.1 M30,18.7v5.4h-4.3v-19c0.3,0.3,0.7,0.6,1,0.9
                C26.4,7.8,25.7,13.8,30,18.7 M31.5,17.7C27,12.8,28.5,6,28.5,6l0.1-0.4l-0.3-0.3C24.1,0.3,17.4,0,16.1,0l-0.1,0l-0.2,0
                c-1.3,0-8,0.3-12.2,5.2L3.2,5.5L3.3,6c0,0.1,1.6,6.8-3,11.7L0,18v18.1h31.8V18L31.5,17.7z"/>
            </g>
          </svg>`,
  },
  "ISO 20471-3": {
    name: "ISO 20471-3 - Risque de non visibilité - Classe 3",
    content: "iso-20471",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Visibilité</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 20471-3</text>
            <g transform="translate(9.1 0)">
              <path d="M1.8,32.5H30v1.8H1.8V32.5z M5.1,6c0.3-0.3,0.6-0.6,1-0.9v19H1.8v-5.4C6.1,13.8,5.4,7.8,5.1,6 M15.9,1.8h0.1
                c0.6,0,2.6,0.1,4.9,0.8l-5.1,7.5l-5-7.5c2.3-0.7,4.2-0.8,4.8-0.8H15.9z M30,29.6H1.8V27H30V29.6z M22.8,3.3v20.8h-6.1V11.7l5.8-8.6
                C22.6,3.2,22.7,3.2,22.8,3.3 M9.4,3.1l5.7,8.6v12.4H9V3.3C9.1,3.2,9.3,3.2,9.4,3.1 M30,18.7v5.4h-4.3v-19c0.3,0.3,0.7,0.6,1,0.9
                C26.4,7.8,25.7,13.8,30,18.7 M31.5,17.7C27,12.8,28.5,6,28.5,6l0.1-0.4l-0.3-0.3C24.1,0.3,17.4,0,16.1,0l-0.1,0l-0.2,0
                c-1.3,0-8,0.3-12.2,5.2L3.2,5.5L3.3,6c0,0.1,1.6,6.8-3,11.7L0,18v18.1h31.8V18L31.5,17.7z"/>
            </g>
          </svg>`,
  },
  "ISO 11611-1": {
    name: "ISO 11611-1 - Risque thermique : chaleur et flammes - Classe 1",
    content: "iso-11611",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Soudage</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 11611-1</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M6.6,23.5h16.5c0.1,0,0.2-0.1,0.2-0.2v-2.1c0-0.1-0.1-0.2-0.2-0.2H6.6c-0.1,0-0.2,0.1-0.2,0.2v2.1
                C6.4,23.4,6.5,23.5,6.6,23.5"/>
              <path d="M15.4,8.1c0-0.1,0.1-0.2,0.2-0.2h1.9c0.1,0,0.2,0.1,0.2,0.2v1c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2V8.1z
                 M14.2,10.8h0.9c0.1,0,0.2,0.1,0.2,0.2l-1,5.6c0,0.1,0.1,0.2,0.2,0.2l1,0.2c0.1,0,0.2,0,0.2-0.2l1-5.8c0-0.1,0.1-0.2,0.2-0.2h7.6
                c0.1,0,0.2-0.1,0.2-0.2V9.5c0-0.1-0.1-0.2-0.2-0.2h-5.1c-0.1,0-0.2-0.1-0.2-0.2v-1c0-0.1,0.1-0.2,0.2-0.2h5.1c0.1,0,0.2-0.1,0.2-0.2
                V6.6c0-0.1-0.1-0.2-0.2-0.2H14.2C14,6.4,14,6.5,14,6.6v4C14,10.7,14,10.8,14.2,10.8"/>
              <path d="M9.1,20.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C8.2,19.9,8.6,20.2,9.1,20.2"/>
              <path d="M10.2,17.9c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C9.4,17.6,9.8,17.9,10.2,17.9"/>
              <path d="M12.4,16.5c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C11.6,16.2,12,16.5,12.4,16.5"/>
              <path d="M18.1,17.1c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8C17.7,17.9,18.1,17.6,18.1,17.1"/>
              <path d="M18.4,20.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C17.6,19.9,18,20.2,18.4,20.2"/>
            </g>
          </svg>`,
  },
  "ISO 11611-2": {
    name: "ISO 11611-2 - Risque thermique : chaleur et flammes - Classe 2",
    content: "iso-11611",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Soudage</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 11611-2</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M6.6,23.5h16.5c0.1,0,0.2-0.1,0.2-0.2v-2.1c0-0.1-0.1-0.2-0.2-0.2H6.6c-0.1,0-0.2,0.1-0.2,0.2v2.1
                C6.4,23.4,6.5,23.5,6.6,23.5"/>
              <path d="M15.4,8.1c0-0.1,0.1-0.2,0.2-0.2h1.9c0.1,0,0.2,0.1,0.2,0.2v1c0,0.1-0.1,0.2-0.2,0.2h-1.9c-0.1,0-0.2-0.1-0.2-0.2V8.1z
                 M14.2,10.8h0.9c0.1,0,0.2,0.1,0.2,0.2l-1,5.6c0,0.1,0.1,0.2,0.2,0.2l1,0.2c0.1,0,0.2,0,0.2-0.2l1-5.8c0-0.1,0.1-0.2,0.2-0.2h7.6
                c0.1,0,0.2-0.1,0.2-0.2V9.5c0-0.1-0.1-0.2-0.2-0.2h-5.1c-0.1,0-0.2-0.1-0.2-0.2v-1c0-0.1,0.1-0.2,0.2-0.2h5.1c0.1,0,0.2-0.1,0.2-0.2
                V6.6c0-0.1-0.1-0.2-0.2-0.2H14.2C14,6.4,14,6.5,14,6.6v4C14,10.7,14,10.8,14.2,10.8"/>
              <path d="M9.1,20.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C8.2,19.9,8.6,20.2,9.1,20.2"/>
              <path d="M10.2,17.9c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C9.4,17.6,9.8,17.9,10.2,17.9"/>
              <path d="M12.4,16.5c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C11.6,16.2,12,16.5,12.4,16.5"/>
              <path d="M18.1,17.1c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8C17.7,17.9,18.1,17.6,18.1,17.1"/>
              <path d="M18.4,20.2c0.5,0,0.8-0.4,0.8-0.8c0-0.5-0.4-0.8-0.8-0.8c-0.5,0-0.8,0.4-0.8,0.8C17.6,19.9,18,20.2,18.4,20.2"/>
            </g>
          </svg>`,
  },
  "EN 13034-6": {
    name: "EN 13034-6 - Risque produits liquides chimiques",
    content: "en-13034-6",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 66" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Produits</text>
            <text transform="translate(25 54)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">chimiques</text>
            <text transform="translate(25 66)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 13034-6</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M10.1,28h10.6c0.9,0,1.5-0.2,1.8-0.7c0.5-0.7,0.1-1.6,0.1-1.6c0,0-3.2-11-3.9-13.2c-0.6-2.1-0.2-3-0.1-3.1L19.7,7
                c0.1-0.2,0-0.4-0.2-0.4c-0.2-0.1-0.4,0-0.4,0.2L18,9c-0.1,0.1-0.6,1.1,0.1,3.6c0.2,0.6,0.6,1.9,1,3.5h-7.4c0.5-1.5,0.8-2.8,1-3.5
                c0.7-2.4,0.2-3.5,0.1-3.5l-1.1-2.4c-0.1-0.2-0.3-0.2-0.4-0.2C11.1,6.6,11,6.8,11.1,7l1.1,2.4c0,0,0.5,0.9-0.2,3
                c-0.7,2.3-3.9,13.2-3.9,13.2c0,0-0.4,1,0.1,1.7C8.6,27.8,9.2,28,10.1,28"/>
              <path d="M17.2,5.2c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1
                c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1
                s0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.4,0.1-0.5,0.1C23.8,5,23.7,5,23.5,5
                c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.4,0.1-0.5,0.1C22,5,21.9,5,21.7,5c-0.2,0-0.3,0-0.4-0.1
                c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.4,0.1-0.5,0.1C20.2,5,20.1,5,19.9,5c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1
                c-0.3,0-0.4,0.1-0.5,0.1C18.4,5,18.3,5,18.1,5c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1C17.1,4.8,17,4.9,17,5
                C17,5.1,17.1,5.2,17.2,5.2"/>
              <path d="M17.2,4.4c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1
                c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1
                s0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.2,0-0.3,0-0.4-0.1C23,4.1,22.9,4,22.6,4c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1
                C21.2,4.1,21.1,4,20.8,4c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1C19.4,4.1,19.2,4,19,4
                c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1C17.6,4.1,17.4,4,17.2,4C17.1,4,17,4.1,17,4.2
                C17,4.3,17.1,4.4,17.2,4.4"/>
              <path d="M19.9,3.8c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1
                c0.3,0,0.4-0.1,0.5-0.1c0.1-0.1,0.2-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1
                c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1
                c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1s-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1
                c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.2,0.2C19.7,3.7,19.8,3.8,19.9,3.8"/>
              <path d="M24.4,5.6c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1
                c-0.3,0-0.4,0.1-0.5,0.1c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1c-0.1-0.1-0.3-0.1-0.5-0.1c-0.3,0-0.4,0.1-0.5,0.1
                c-0.1,0.1-0.2,0.1-0.4,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.3,0,0.4-0.1,0.5-0.1C20.5,6,20.6,6,20.8,6
                c0.2,0,0.3,0,0.4,0.1c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.4-0.1,0.5-0.1C22.3,6,22.4,6,22.6,6c0.2,0,0.3,0,0.4,0.1
                c0.1,0.1,0.3,0.1,0.5,0.1s0.4-0.1,0.5-0.1C24.1,6,24.2,6,24.4,6c0.1,0,0.2-0.1,0.2-0.2C24.6,5.7,24.5,5.6,24.4,5.6"/>
            </g>
          </svg>`,
  },
  "ISO 11612": {
    name: "ISO 11612 - Risque soudage chaleurs et projections",
    content: "iso-11612",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 66" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Chaleurs et</text>
            <text transform="translate(25 54)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">flammes</text>
            <text transform="translate(25 66)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">ISO 11612</text>
            <g transform="translate(9.55 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M10.9,28.3c1.3,0.4,1.5,0,1.5,0c-2.3-1-3-4.2-2-6.7c2.2,2.3,1.9-1.7,1.7-3c1.3,0.5,1.2,4.1,3.3,4.7
                c-1.4-2.5,0.7-6.8-0.1-8.1c1.6,0.8,1.6,4.1,1.4,5.1c1.4-0.9,0.3-2.7,1.3-4.2c-0.4,1,1.2,3.7,0,5.8c1.2,0.5,1.3-2.1,2.5-1.8
                c-0.6,0.6,0.2,2.5,0.2,4.5c0,1.6-0.8,3.1-2.2,3.8c0,0,1.1,0.1,1.5,0c2-0.6,3.5-3.2,3.5-5.7c-0.1-3.6-2.2-8.2-1.1-9.5
                c-1.6-0.1-1.7,2.5-3.1,2.8c0.9-1.6,0.7-3.7-0.4-5.4c-0.7-1.2-0.8-2.8,0-3.5c-1.5,0.4-2.3,3-1.9,5.1c-2-3.1,0.9-6.8-0.5-9
                c0.4,1.1-1.7,3.4-2.8,5.9c-0.9,2-0.8,5,0.2,7.7c-2.7-2.6-0.4-6.8-2.4-8c1,1.6-2.4,3.4-0.7,7.6c-0.6,0.5-1.1-1.8-2.3-1.3
                c0.7,0.9-0.5,4.3-0.8,5.8C6.9,24.7,8.4,27.6,10.9,28.3"/>
            </g>
          </svg>`,
  },
  "IEC 61482-2": {
    name: "IEC 61482-2 - Risque d'arc électrique",
    content: "iec-61482-2",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 66" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Arc</text>
            <text transform="translate(25 54)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">électrique</text>
            <text transform="translate(25 66)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">IEC 61482-2</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M7.7,16.1l7.8-11.2l7.8,11.2h-5l-2.8-4l-2.8,4H7.7z M7.1,25.5l8.3-12l8.3,12H7.1z M25.4,26.3l-6.5-9.4h6L15.5,3.5L6.1,16.9
                h6l-6.5,9.4H25.4z"/>
            </g>
          </svg>`,
  },
  "EN 1149-5": {
    name: "EN 1149-5 - Risque d'électricité statique",
    content: "en-1149-5",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 66" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Électricité</text>
            <text transform="translate(25 54)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">statique</text>
            <text transform="translate(25 66)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">EN 1149-5</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
              <path d="M7.4,26.5h16.2c0.1,0,0.2-0.1,0.2-0.2v-2c0-0.1-0.1-0.2-0.2-0.2h-7.4l4.9-13.2l-9,5.1l2.7-8.2c0,0,0,0,0.1,0
                c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9c-1,0-1.9,0.8-1.9,1.9c0,0.6,0.3,1.1,0.7,1.5l-3.7,11.1l8.8-5l-4,10.7H7.4
                c-0.1,0-0.2,0.1-0.2,0.2v2C7.2,26.4,7.3,26.5,7.4,26.5"/>
            </g>
          </svg>`,
  },
  "EN 342": {
    name: "EN 342 - Risque froid : de -5° jusqu'à -50°C",
    content: "en-342",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Froid</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">EN 342</text>
            <g transform="translate(9.1 0)">
              <path d="M15.6,22.3l-5.2-3v-5.7l5-2.9l5.2,3v5.8L15.6,22.3z M25,18.7l-0.8-1.4l-2,1.2v-3.9l1.9,1.1l0.8-1.4l-2-1.1l4-2.4l-0.8-1.4
                l-4,2.3V9.4h-1.6v2.4l-3.5-2L19,8.6l-0.8-1.4l-1.9,1.1V3.8h-1.6v4.6l-2.1-1.2l-0.8,1.4l2,1.2l-3.4,2V9.4H8.8v2.3l-4-2.3L4,10.7
                l4.1,2.3l-2.2,1.3l0.8,1.4l2.1-1.2v3.9l-2-1.2L6,18.6l2.1,1.2L4,22.2l0.8,1.4l4-2.4v2.2h1.6v-2.3l3.6,2.1l-2.1,1.2l0.8,1.4l1.9-1.1
                v4.6h1.6v-4.8l2.1,1.2l0.8-1.4l-1.9-1.1l3.4-2v2.2h1.6v-2.1l3.9,2.3l0.8-1.4l-4-2.3L25,18.7z"/>
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
            </g>
          </svg>`,
  },
  "EN 343": {
    name: "EN 343 - Risque intempéries",
    content: "en-343",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Intempéries</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">EN 343</text>
            <g transform="translate(9.1 0)">
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
                <path d="M15.1,16.9c-0.9,0.1-1.5,0.5-2.1,1.2c-0.6-0.7-1.4-1.2-2.3-1.2c-1,0-1.7,0.5-2.3,1.2c-0.6-0.6-1.2-1.1-2.1-1.2
                  c-0.9-0.1-1.6,0.2-2.2,0.8c0,0,0,0,0,0c0.1-0.3,0.1-0.5,0.2-0.8c0.4-1.1,1-2.1,1.7-2.9c1.1-1.2,2.4-2.2,4-2.8
                  c1-0.5,2.1-0.8,3.2-0.9c0.6-0.1,1.2-0.2,1.9-0.2c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.4,0.3-0.4
                  c0.2,0,0.4,0.1,0.4,0.3c0,0.2,0,0.4,0,0.6c0,0,0,0.1,0,0.1c0.2,0,0.3,0,0.5,0c1.1,0.1,2.2,0.3,3.3,0.6c1.4,0.4,2.6,1,3.8,1.9
                  c1.1,0.8,1.9,1.7,2.6,2.9c0.4,0.8,0.8,1.8,0.9,2.5c-0.6-0.7-1.4-1.1-2.3-1.1c-0.9,0-1.6,0.5-2.3,1.2c-0.6-0.7-1.4-1.2-2.3-1.2
                  c-1,0-1.7,0.5-2.3,1.2c-0.5-0.6-1.1-1-1.9-1.1c0,0,0,0.1,0,0.1c0,3.3,0,6.5,0,9.8c0,0.2,0,0.4,0.1,0.6c0.2,0.5,0.5,0.6,1.1,0.6
                  c0.3,0,0.6-0.2,0.7-0.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3
                  c0,0.7-0.1,1.4-0.7,1.9c-0.2,0.2-0.4,0.2-0.7,0.3c-0.4,0-0.8,0-1.2-0.2c-0.4-0.2-0.7-0.6-0.8-1.1c-0.1-0.2-0.1-0.4-0.1-0.6
                  c0-3.3,0-6.5,0-9.8C15.1,17,15.1,17,15.1,16.9z"/>
              <path d="M18.6,8.7c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1c0.3-0.3,1.5-0.5,1.5-0.5S18.9,8.4,18.6,8.7z"/>
              <path d="M17.4,6.4c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1s1.5-0.5,1.5-0.5S17.7,6.2,17.4,6.4z"/>
              <path d="M24.4,6.4c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1S25,4.9,25,4.9S24.7,6.2,24.4,6.4z"/>
              <path d="M21.2,4.7c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1s1.5-0.5,1.5-0.5S21.5,4.5,21.2,4.7z"/>
              <path d="M13.9,7.9c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1s1.5-0.5,1.5-0.5S14.1,7.7,13.9,7.9z"/>
              <path d="M21.2,6.7c-0.3,0.3-0.7,0.3-1,0c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,1.5-0.5,1.5-0.5S21.5,6.5,21.2,6.7z"/>
              <path d="M21.7,9.4c-0.3,0.3-0.7,0.3-1,0s-0.3-0.7,0-1s1.5-0.5,1.5-0.5S22,9.2,21.7,9.4z"/>
            </g>
          </svg>`,
  },
  "EN 14058": {
    name: "EN 14058 - Risque frais : au dessus de -5°C",
    content: "en-14058",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Frais</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">EN 14058</text>
            <g transform="translate(9.1 0)">
              <path d="M15.6,22.3l-5.2-3v-5.7l5-2.9l5.2,3v5.8L15.6,22.3z M25,18.7l-0.8-1.4l-2,1.2v-3.9l1.9,1.1l0.8-1.4l-2-1.1l4-2.4l-0.8-1.4
                l-4,2.3V9.4h-1.6v2.4l-3.5-2L19,8.6l-0.8-1.4l-1.9,1.1V3.8h-1.6v4.6l-2.1-1.2l-0.8,1.4l2,1.2l-3.4,2V9.4H8.8v2.3l-4-2.3L4,10.7
                l4.1,2.3l-2.2,1.3l0.8,1.4l2.1-1.2v3.9l-2-1.2L6,18.6l2.1,1.2L4,22.2l0.8,1.4l4-2.4v2.2h1.6v-2.3l3.6,2.1l-2.1,1.2l0.8,1.4l1.9-1.1
                v4.6h1.6v-4.8l2.1,1.2l0.8-1.4l-1.9-1.1l3.4-2v2.2h1.6v-2.1l3.9,2.3l0.8-1.4l-4-2.3L25,18.7z"/>
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
            </g>
          </svg>`,
  },
  "EN 14404": {
    name: "EN 14404 - Risque hygroma du genoux",
    content: "en-14404",
    svg: `<svg version="1.1"
               xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 58" xml:space="preserve">
            <text transform="translate(25 46)" text-anchor="middle" style="font: 500 9px/9px 'Museo Sans', Helvetica, sans-serif;">Genoux</text>
            <text transform="translate(25 58)" text-anchor="middle" style="font: 100 9px/9px 'Museo Sans', Helvetica, sans-serif;">EN 14404</text>
            <g transform="translate(9.1 0)">
              <path d="M16.2,20.5H7.9v-8.5h3.7c0.2-0.1,0.2-1,0.2-1.5c0-1,0-2.2,0.7-2.9C12.9,7.2,13.3,7,13.9,7h2.3v5.8h9.5V14h-9.5v1.6h9.5v1.1
                h-9.5V20.5z"/>
              <rect x="5.2" y="22.3" width="13.2" height="2.3"/>
              <path d="M29.6,15.8c0,0.5-0.1,12.4-14.1,19c-14-6.6-14.1-18.5-14.1-19V1.3h28.3V15.8z M0,0v15.8c0,0.1,0.1,13.4,15.2,20.4l0.3,0.1
                l0.3-0.1C30.9,29.2,31,15.9,30.9,15.8V0H0z"/>
            </g>
          </svg>`,
  },
};

export const ProductStandardsArray = _map(ProductStandards, (id) => ({
  id,
  ...ProductStandardsInfos[id],
}));
