export function load() {
  if (typeof localStorage === "undefined") return undefined;
  try {
    const serializedState = localStorage.getItem("store");
    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error(`Unable to load state`, err);
    return undefined;
  }
}

export function save(store) {
  if (typeof localStorage === "undefined") return undefined;
  try {
    const serializedState = JSON.stringify(store);
    localStorage.setItem("store", serializedState);
  } catch (err) {
    console.error(`Unable to load state`, err);
  }
}
