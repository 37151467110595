import gql from "graphql-tag";

export const MediaFragment = gql`
  fragment Media on Media {
    id
    kind
    name
    mime
    length
    data
    alt
    description
    tags
    hash
  }
`;