import { css } from "styled-components";

import { sizeInPxToFontSize, unit, reset } from "./util";

export const spacing = {
  standard: 16
};
Object.assign(spacing, {
  // Real-life values
  padding: unit(spacing.standard, "px"),
  paddingHalf: unit(spacing.standard / 2, "px"),
  paddingDouble: unit(spacing.standard * 2, "px")
});

export const color = {
  blue: "#007dc5",
  darkBlue: "#0f202e",
  darkGrey: "#020202",
  grey: "#555554",
  lightGrey: "#ebebeb",
  white: "#ffffff"
};
Object.assign(color, {
  // Real-life values
  primary: color.blue,
  text: color.darkGrey,
  title: color.darkGrey,
  background: color.white,
  success: "#15a575",
  warning: "#eda715",
  error: "#be0a28"
});
Object.assign(color, {
  // Sub-values
  collection: { background: color.lightGrey },
  configurator: { attribute: { secondary: color.grey } },
  line: color.grey,
  button: { normal: color.darkGrey, hover: color.primary },
  slider: { arrow: color.grey },
  header: { background: color.background },
  link: { normal: color.text, hover: color.primary }
});

export const transition = {
  standard: {
    duration: "0.25s",
    mode: "ease"
  }
};
Object.assign(transition, {
  // Real-life values
  default: `${transition.standard.duration} ${transition.standard.mode}`,
  link: `${transition.standard.duration} ${transition.standard.mode}`
});

export const fontFamily = {
  default: ['"Lato"', "Verdana", "sans serif"].join(",")
};
export const fontWeight = {
  default: {
    regular: 400,
    normal: 400,
    bold: 700
  }
};
export const font = {
  default(size = null, lineHeight = 1.3) {
    return css`
      font: ${fontWeight.default.normal}
        ${size ? sizeInPxToFontSize(size) : "1em"} /
        ${size && typeof lineHeight === "number"
          ? sizeInPxToFontSize(size * lineHeight)
          : unit(lineHeight, "em")}
        ${fontFamily.default};
    `;
  },
  bold(size = null, lineHeight = 1.3) {
    return css`
      font: ${fontWeight.default.bold}
        ${size ? sizeInPxToFontSize(size) : "1em"} /
        ${size && typeof lineHeight === "number"
          ? sizeInPxToFontSize(size * lineHeight)
          : unit(lineHeight, "em")}
        ${fontFamily.default};
    `;
  },
  italic(size = null, lineHeight = 1.3) {
    return css`
      font: ${fontWeight.default.normal} italic
        ${size ? sizeInPxToFontSize(size) : "1em"} /
        ${size && typeof lineHeight === "number"
          ? sizeInPxToFontSize(size * lineHeight)
          : unit(lineHeight, "em")}
        ${fontFamily.default};
    `;
  },
  boldItalic(size = null, lineHeight = 1.3) {
    return css`
      font: ${fontWeight.default.bold} italic
        ${size ? sizeInPxToFontSize(size) : "1em"} /
        ${size && typeof lineHeight === "number"
          ? sizeInPxToFontSize(size * lineHeight)
          : unit(lineHeight, "em")}
        ${fontFamily.default};
    `;
  }
};
Object.assign(font, {
  // Real-life values
  headline: css`
    ${font.bold(16)};
    text-transform: uppercase;
  `,
  subheadline: css`
    ${font.bold(13)};
    text-transform: uppercase;
  `,
  button: css`
    ${font.bold(12, "20px")};
    text-transform: uppercase;
  `,

  home: {
    headline: font.default(20),
    slider: font.default(18, "20px"),
    title: css`
      ${font.bold(20)};
      text-transform: uppercase;
    `
  },
  static: {
    title: css`
      ${font.bold(32, 1)};
      text-transform: uppercase;
    `,
    subtitle: css`
      ${font.default(24, 1)};
      text-transform: uppercase;
    `,
    baseline: css`
      ${font.default(22)};
      text-transform: uppercase;
    `,
    strong: css`
      ${font.bold(14)};
      text-transform: uppercase;
    `,
    text: font.default(14, 1.4),
    kpititle: css`
      ${font.default(24, 1)};
      text-transform: uppercase;
    `,
    kpifigure: css`
      ${font.default(24, 1.2)};
    `,
    kpitext: css`
      ${font.default(14, 1.2)};
    `
  },
  mainMenu: {
    firstLevel: css`
      ${font.default(15)};
      text-transform: uppercase;
    `,
    secondLevel: css`
      ${font.bold(13)};
      text-transform: none;
    `
  },
  menu: {
    normal: css`
      ${font.default(14)};
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `,
    active: css`
      ${font.bold(14)};
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `
  },
  configurator: {
    part: css`
      ${font.default(15)};
      text-transform: uppercase;
    `,
    topmenu: {
      normal: css`
        ${font.default(14)};
        text-transform: uppercase;
      `,
      active: css`
        ${font.bold(14)};
        text-transform: uppercase;
      `
    },
    product: {
      normal: css`
        ${font.default(11)};
        text-transform: uppercase;
      `,
      active: css`
        ${font.bold(11)};
        text-transform: uppercase;
      `
    },
    attributes: {
      headline: css`
        ${font.italic(14, 1)};
        text-transform: uppercase;
      `,
      subheadline: css`
        ${font.boldItalic(14, 1)};
        text-transform: none;
      `,
      tag: css`
        ${font.italic(12, 1.5)};
      `
    },
    message: css`
      ${font.default(14)};
    `,
    reference: css`
      ${font.default(10)};
      text-transform: uppercase;
    `
  },
  cart: {
    action: css`
      ${font.bold(12)};
    `,
    item: {
      collection: css`
        ${font.bold(15)};
      `,
      product: css`
        ${font.default(15)};
      `
    }
  }
});

export const style = {
  link: css`
    ${reset()};
    color: ${color.link.normal};
    text-decoration: none;
    transition: color ${transition.link};

    &:hover {
      color: ${color.link.hover};
    }
  `,
  button: css`
    ${reset()};
    ${font.button};
    background: transparent none;
    border: 2px solid ${color.button.normal};
    border-radius: 0;
    color: ${color.button.normal};
    padding: ${spacing.padding} ${spacing.paddingDouble};
    text-align: center;
    text-decoration: none;
    transition: all ${transition.default};

    &:hover {
      border-color: ${color.button.hover};
      color: ${color.button.hover};
    }
  `,
  menu: css`
    ${reset()};
    color: ${color.link.normal};
    text-decoration: none;
    transition: color ${transition.link};

    &:hover {
      color: ${color.link.hover};
    }
  `,
  line: {
    thin: css`1px solid ${color.primary}`,
    bold: css`3px solid ${color.primary}`
  }
};
