import React from "react";
import styled, { css } from "styled-components";
import NavLink from "react-router-dom/NavLink";

import { CollectionsSlider, CollectionLink } from "../Category/style";
import { get, mixin, reset } from "../../css/util";

export const CollectionsTitle = styled.h1`
  ${reset()};
  ${get("font.headline")};
  margin: ${get("spacing.padding")};
  margin-top: ${get("spacing.paddingDouble")};
  text-align: center;
`;
export const CollectionsKindTitle = styled.h2`
  ${reset()};
  ${get("font.subheadline")};
  margin: ${get("spacing.padding")};
  margin-bottom: ${get("spacing.paddingDouble")};
  text-align: center;
`;

export const CollectionsList = styled.nav`
  padding: ${get("spacing.padding")} 0;
`;

export const CollectionsListLink = styled(function CollectionsListLink({
  prefix,
  hasCollectionActive,
  children,
  ...props
}) {
  return (
    <NavLink {...props}>
      {!!prefix && <small>{prefix}</small>}
      {children}
    </NavLink>
  );
})`
  ${get("style.link")};
  ${mixin("font.default", 20)};
  display: block;
  margin-left: 1px;
  padding: ${get("spacing.padding")};
  padding-left: calc(${get("spacing.padding")} + 2px);
  text-align: left;
  ${({ hasCollectionActive, theme }) =>
    !!hasCollectionActive &&
    css`
      border-left: ${theme.style.line.thin};
      padding-left: calc(${get("spacing.padding")} + 1px);
    `};

  &.active {
    border-left: ${get("style.line.bold")};
    margin-left: 0px;
    padding-left: ${get("spacing.padding")};
  }

  > small {
    display: block;
    font-size: 0.5em;
    line-height: 0.75em;
    text-transform: uppercase;
  }
`;

export { CollectionsSlider, CollectionLink };
