import React from "react";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _every from "lodash/every";

import Image from "../Image";
import BackgroundImage from "../BackgroundImage";
import { Images, Pin } from "./style";

export default function ProductImage({
  onHeight = false,
  withPins = false,
  onSelectPin = () => {},
  products = [],
  variants = [],
}) {
  const isBackgroundImage = _every(products, "fullImage");

  return isBackgroundImage ? (
    <BackgroundImage media={variants[0].image} />
  ) : (
    <Images onHeight={onHeight}>
      {_map(
        variants,
        (variant) =>
          !!variant.image && (
            <React.Fragment key={variant.id}>
              <Image
                key={variant.id}
                media={variant.image}
                onHeight={onHeight}
              />
              {!!withPins &&
                _map(
                  _filter(variant.pins, (pin) => !!pin.image),
                  (pin, index) => (
                    <Pin
                      key={variant.id + "-pin" + index}
                      {...pin}
                      onClick={() => onSelectPin(pin)}
                    />
                  )
                )}
            </React.Fragment>
          )
      )}
    </Images>
  );
}
