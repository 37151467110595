import React from "react";

import { Aside, Main, Container } from "./style";

export default function LeftColumn({ column, children, ...props }) {
  return <Container>
    <Aside>{column}</Aside>
    <Main>{children}</Main>
  </Container>;
}
