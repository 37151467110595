import React from "react";
import SVG from './SVG';

export default function CartIcon(props) {
  return (
    <SVG viewBox="0 0 100 100" {...props}>
      <g transform="translate(9.85, 7.35)">
        <path
          d="M39.5,75.6c0-5.4-4.4-9.7-9.7-9.7s-9.7,4.4-9.7,9.7s4.4,9.7,9.7,9.7C35.2,85.3,39.5,80.9,39.5,75.6z M26.1,75.6
      c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7C27.7,79.3,26.1,77.6,26.1,75.6L26.1,75.6z"
        />
        <path
          d="M74.9,75.6c0-5.4-4.4-9.7-9.7-9.7s-9.7,4.4-9.7,9.7s4.4,9.7,9.7,9.7C70.5,85.3,74.9,80.9,74.9,75.6z M61.4,75.6
      c0-2.1,1.7-3.7,3.7-3.7s3.7,1.7,3.7,3.7c0,2.1-1.7,3.7-3.7,3.7S61.4,77.6,61.4,75.6L61.4,75.6z"
        />
        <path
          d="M4.6,0.3L3.7,0.1C2.1-0.2,0.5,0.8,0.1,2.4C-0.2,4,0.8,5.6,2.4,6c0.1,0,0.2,0,0.3,0l0.9,0.2c5,0.9,9,4.8,9.8,9.9l5.5,33.4
      c1.2,7.7,7.9,13.4,15.8,13.4H74c1.7,0,3-1.3,3-3s-1.3-3-3-3H34.6c-4.6,0-8.7-3.2-9.7-7.7h31.6c9.1,0,17.2-5.9,20-14.6l3.6-11.2
      c0.7-2.1-0.5-4.4-2.6-5c-0.4-0.1-0.8-0.2-1.3-0.2H19.8l-0.5-3.1C18,7.6,12.2,1.6,4.6,0.3z M73.5,24.2l-2.7,8.5
      c-2,6.2-7.8,10.4-14.3,10.4H23.9l-3.1-19L73.5,24.2z"
        />
      </g>
    </SVG>
  );
}
