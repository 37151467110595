import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";

import { Section } from "../../components/Container/style";
import { get } from "../../css/util";

export const Layout = Section.withComponent('article').extend`
  text-align: center;
`;

export const Menu = styled.nav`
  display: inline-block;
  margin: ${get("spacing.paddingDouble")} auto;
  padding: 0;
  position: relative;
  text-align: center;
  width: auto;
  
  &:before {
    border-bottom: ${get('style.line.thin')};
    bottom: 1px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
  }
`;

export const MenuLink = styled(NavLink)`
  ${get('style.menu')};
  ${get('font.menu.normal')};
  display: inline-block;
  padding: ${get("spacing.padding")} 0;
  margin: 0 ${get("spacing.padding")};

  &.active {
    border-bottom: ${get('style.line.bold')};
    ${get('font.menu.active')};
    margin-bottom: 0;
  }
`;
