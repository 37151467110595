import React from "react";
import SVG from './SVG';

export default function AccountIcon(props) {
  return (
    <SVG viewBox="0 0 100 100" {...props}>
      <g transform="translate(11, 6.5)">
        <path
          d="M39,46.2c-12.7,0-23.1-10.4-23.1-23.1S26.3,0,39,0s23.1,10.4,23.1,23.1S51.7,46.2,39,46.2z M39,6
          c-9.4,0-17.1,7.7-17.1,17.1S29.6,40.2,39,40.2s17.1-7.7,17.1-17.1S48.4,6,39,6z"
        />
        <path
          d="M39,87c-9.7,0-19-3.1-26.8-8.9c-1.6-1.2-3.2-2.5-4.6-3.9c-1.6-1.6-3.1-3.3-4.5-5.1c-0.9-1.2-1.8-2.6-2.7-4
          c-0.5-0.8-0.6-1.9-0.2-2.8c0.6-1.5,1.4-2.9,2.3-4.2C7,51.3,14.2,46.8,22.2,45.7c1.3-0.2,2.5,0.1,3.5,0.9c3.9,2.8,8.4,4.3,13.2,4.3
          s9.4-1.5,13.3-4.3c1-0.7,2.3-1.1,3.5-0.9c8,1.1,15.2,5.6,19.7,12.4c0.9,1.3,1.7,2.8,2.3,4.2c0.4,0.9,0.3,1.9-0.2,2.8
          c-0.8,1.4-1.7,2.7-2.7,4c-1.4,1.8-2.9,3.5-4.5,5.1c-1.4,1.4-3,2.7-4.6,3.9C58,83.9,48.7,87,39,87z M6.4,63.4
          c0.5,0.7,1,1.4,1.5,2.1c1.2,1.6,2.5,3.1,3.9,4.4c1.2,1.2,2.6,2.4,4,3.4c6.8,5,14.8,7.7,23.3,7.7s16.5-2.7,23.3-7.7
          c1.4-1,2.7-2.2,4-3.4c1.4-1.4,2.7-2.9,3.9-4.4c0.5-0.7,1-1.4,1.5-2.1c-0.3-0.6-0.7-1.3-1.1-1.9l0,0c-3.5-5.2-8.9-8.7-15.1-9.7
          C50.8,55.2,45.1,57,39.2,57s-11.6-1.8-16.4-5.2c-6.1,1-11.6,4.5-15.1,9.7C7.1,62.1,6.7,62.7,6.4,63.4z M22.2,51.5L22.2,51.5
          L22.2,51.5z M55.8,51.5L55.8,51.5L55.8,51.5z"
        />
      </g>
    </SVG>
  );
}
