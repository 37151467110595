import React from "react";
import styled from "styled-components";
import Slick from "react-slick";
import { defaultProps } from "recompose";

import { mixin, get } from "../../css/util";

const Arrow = function({ slideCount, currentSlide, children, ...props }) {
  return (
    <button {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 128 128"
      >
        {children}
      </svg>
    </button>
  );
};

export const Slider = styled(
  defaultProps({
    prevArrow: (
      <Arrow>
        <path
          transform="translate(12.25, 7.65)"
          d="M6.8,39.2l-0.2,0.2c-0.1,0.1-6.7,6.9-6.6,17v0v0c-0.1,10.1,6.5,16.9,6.6,17l0.2,0.2L46,112.7l14.3-14.3
        L27.4,65.5h76.1V44.9h-74l24.9-24.9l5.8-5.8L46,0L6.8,39.2z"
        />
      </Arrow>
    ),
    nextArrow: (
      <Arrow>
        <path
          transform="translate(12.25, 7.65)"
          d="M96.7,73.5l0.2-0.2c0.1-0.1,6.7-6.9,6.6-17v0v0c0.1-10.1-6.5-16.9-6.6-17l-0.2-0.2L57.5,0L43.2,14.3l32.9,32.9
	H0v20.7h74L49.1,92.8l-5.8,5.8l14.2,14.1L96.7,73.5z"
        />
      </Arrow>
    )
  })(Slick)
)`
  .slick-arrow {
    ${get("style.link")};
    color: ${get("color.slider.arrow")};
    cursor: pointer;
    display: block;
    height: 32px;
    margin: -16px ${get("spacing.padding")};
    position: absolute;
    top: 50%;
    width: 32px;

    svg {
      fill: currentColor;
    }

    &.slick-prev {
      right: 100%;
    }
    &.slick-next {
      left: 100%;
    }
    &.slick-disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }

  .slick-dots {
    list-style: none;
    margin: ${get("spacing.standard")} 0;
    text-align: center;
    li {
      display: inline-block;
    }
    button {
      ${get("style.link")};
      ${mixin("font.default", 12)};
      border-top: ${get("style.line.thin")};
      cursor: pointer;
      padding: 5px 0;
      vertical-align: top;
      width: 60px;
    }
    .slick-active button {
      ${mixin("font.bold", 15)};
      border-top: ${get("style.line.bold")};
      padding-top: 4px;
    }
  }
`;
