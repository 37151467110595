import React from "react";
import styled from "styled-components";

import { get } from "../../css/util";

export const Color = styled(function({ size, ...props }) {
  return <span {...props} />;
})`
  display: inline-block;
  height: ${get("size")};
  line-height: 0;
  position: relative;
  width: ${get("size")};
`;

export const MainColor = styled(function({ color, size, ...props }) {
  return <i {...props} />;
})`
  background: ${get("color")};
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &:after {
    border: 1px solid ${get("color.line")};
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }
`;

export const SecondaryColor = MainColor.extend`
  background: transparent;
  border-style: solid;
  border-color: transparent;
  border-bottom-color: ${get("color")};
  border-width: 0 ${get("size")} ${get("size")} 0;
  height: 0;
  width: 0;
`;
