import _mapValues from "lodash/mapValues";

function convertTransformToAction(fn) {
  return function(state, props) {
    return fn(state)(props);
  };
}

function doDispatch(type, fn = x => x) {
  return function(...args) {
    return { type, payload: fn(...args) };
  };
}

export default function builder({ getInitialState, actions: actionsRaw }) {
  const actions = _mapValues(actionsRaw, convertTransformToAction);
  const module = function(state, { type, payload }) {
    if (typeof state === "undefined") return getInitialState();
    if (!type || typeof actions[type] === "undefined") return state;
    return actions[type](state, payload);
  };
  Object.keys(actions).forEach(action => {
    module[action] = doDispatch(action);
  });
  return module;
}
