import builder from "./util";

export default builder({
  getInitialState: () => ({
    online: typeof navigator !== "undefined" ? !!navigator.onLine : null
  }),
  actions: {
    isOnline: () => () => ({ online: true }),
    isOffline: () => () => ({ online: false })
  }
});
