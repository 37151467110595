import React from "react";
import styled from "styled-components";

import { get, mixin, reset } from "../../css/util";

export const Button = styled(function({ icon, className, children, ...props }) {
  return (
    <button className={className} type="button" {...props}>
      {children}
      {!!icon && <img className="Icon" src={icon} alt="" />}
    </button>
  );
})`
  ${reset()};
  ${mixin("font.bold", 14, "20px")};
  color: ${get("color.grey")};
  display: inline-block;
  margin: 10px 0.5em;
  margin-left: auto;
  padding: 0 6px;
  text-align: center;

  > .Icon {
    float: right;
    height: 24px;
    margin: 0 0.5em;
    width: auto;
  }
`;

export const Text = styled(Button.withComponent("span"))`
  ${mixin("font.italic", 14, "20px")};
  text-transform: uppercase;
`;
