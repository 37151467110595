import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

export const Modal = styled(function({ className, ...props }) {
  return (
    <ReactModal overlayClassName={className} closeTimeoutMS={250} className={"Content"} {...props} />
  );
})`
  background-color: rgba(234, 234, 234, 0.75);
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  top: 72px;
  z-index: 9;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease;
    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }

  .Content {
    background: transparent;
    left: 10%;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) scale(1);
    right: 10%;
    outline: none;
  }

  .ReactModal__Content {
    opacity: 0;
    transform: translateY(-50%) scale(0.5);
    transition: all 350ms ease;
    &--after-open {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
    &--before-close {
      opacity: 0;
      transform: translateY(-50%) scale(0.5);
      transition: all 150ms ease;
    }
  }
`;
