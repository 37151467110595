import React from "react";

import { Modal, Message } from "./style";

export default function DataLoader({ total, loaded, children }) {
  return (
    <Modal isOpen={loaded < total}>
      <Message percent={loaded / total}>{children}</Message>
    </Modal>
  );
}
