import React from "react";
import styled, { css } from "styled-components";

export default styled(function SVG({ size, color, ...props }) {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" {...props} />;
})`
  ${({ size }) =>
    !!size &&
    css`
      height: ${size}px;
      width: ${size}px;
    `};
  ${({ color }) =>
    !!color &&
    css`
      fill: ${color};
    `};
`;
