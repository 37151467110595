import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import styled from "styled-components";

import { Section } from "../../components/Container/style";
import { get, mixin, reset } from "../../css/util";
import logo from "../../images/logo-initial.svg";

export const Layout = styled(function({ className, ...props }) {
  return (
    <article className={className}>
      <header />
      <main>
        <Section {...props} />
      </main>
      <footer />
    </article>
  );
})`
  background: #e8e9eb url("/images/login-background.jpg") center bottom
    no-repeat;
  background-size: 1155px 765px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;

  > header,
  > main,
  > footer {
    flex: 1;
    min-height: 240px;
  }
  > header {
    background: #e8e9eb url("/images/login-header.jpg") center bottom no-repeat;
    background-size: 525px 250px;
  }
  > main {
    background: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    text-align: center;
  }
`;

export const Title = styled.h1`
  ${reset()};
  ${mixin("font.bold", 15)};
  color: ${get("color.primary")};
  margin: ${get("spacing.paddingDouble")} auto ${get("spacing.padding")} auto;
`;

export const Logo = styled.img.attrs({
  src: logo
})`
  display: block;
  margin: 0 auto ${get("spacing.paddingDouble")} auto;
  width: 175px;
`;

export const LoginButton = styled.button.attrs({ type: "button" })`
  ${reset()};
  ${mixin("font.bold", 12)};
  ${get("transition.default")};
  background: ${get("color.primary")};
  color: ${get("color.white")};
  padding: ${get("spacing.padding")} ${get("spacing.paddingDouble")};
  text-transform: uppercase;
  &:hover {
    background: #005d92;
  }
  `;
  export const GoogleLoginButton = styled(function GoogleLoginButton({className, ...props}) {
  return <div className={className}><GoogleLogin {...props} /></div>;
  })`
    display: inline-block;
  `;

export const Explanation = styled.p`
  ${reset()};
  ${mixin("font.default", 11)};
  margin: ${get("spacing.paddingDouble")} auto;
`;
