import _filter from "lodash/filter";
import _flattenDeep from "lodash/flattenDeep";
import _map from "lodash/map";
import _throttle from "lodash/throttle";
import _uniq from "lodash/uniq";
import { apolloClient } from "./apollo";
import {
  categoriesQuery,
  categoryQuery,
  homeQuery,
  featuredCollectionsQuery,
  collectionsQuery,
  collectionQuery,
} from "./graphql/queries";
import { media, cdn, series } from "./util";

function addFilesFromCategory(query, slug) {
  return async (files = []) => {
    const {
      data: { category },
    } = await query(categoryQuery, { slug });
    if (!category) return files;
    return [
      ...files,
      media(category.icon, "icon"),
      media(category.background, "category-background"),
    ];
  };
}

function addFilesFromCollectionAndKind(query, slug, kind) {
  return async (files = []) => {
    const {
      data: { collection },
    } = await query(collectionQuery, {
      slug,
      kind,
    });
    if (!collection) return files;
    return [
      ...files,
      collection.details && media(collection.details, "original"),
      collection.listView && media(collection.listView, "collection-list"),
      collection.listView && media(collection.listView, "collection-featured"),
      collection.featuredView &&
        media(collection.featuredView, "collection-featured"),
      collection.preview && media(collection.preview, "collection-preview"),
      collection.orderDetails && media(collection.orderDetails, "original"),
      _map(collection.products, ({ variants }) =>
        _map(
          variants,
          (variant) => variant.image && media(variant.image, "original")
        )
      ),
    ];
  };
}

export default async function (loadingCallback = () => {}) {
  let loading = 0;
  let total = 0;
  let minTotal = 400;
  const updateLoading = _throttle(
    () =>
      loadingCallback({
        loaded: total - loading,
        total: Math.max(minTotal, total),
      }),
    100
  );
  updateLoading();

  const query = async (query, variables = {}, count = 1) => {
    minTotal -= count;
    total += count;
    loading += count;
    updateLoading();
    const res = await apolloClient.query({
      query,
      variables,
    });
    loading -= count;
    updateLoading();
    return res;
  };

  total++;
  loading++;
  const allFiles = await series([
    () => [
      cdn("pdf/catalogue_01.3.jpg"),
      cdn("pdf/catalogue_98.1.jpg"),
      cdn("pdf/catalogue_99.1.png"),
      cdn("pdf/flocon.1.png"),
      cdn("pdf/logo-initial.1.png"),
      cdn("fonts/Lato-Black.ttf"),
      cdn("fonts/Lato-BlackItalic.ttf"),
      cdn("fonts/Lato-Bold.ttf"),
      cdn("fonts/Lato-BoldItalic.ttf"),
      cdn("fonts/Lato-Hairline.ttf"),
      cdn("fonts/Lato-HairlineItalic.ttf"),
      cdn("fonts/Lato-Regular.ttf"),
      cdn("fonts/Lato-Italic.ttf"),
      cdn("fonts/Lato-Light.ttf"),
      cdn("fonts/Lato-LightItalic.ttf"),
      cdn("fonts/MuseoSans-300.ttf"),
      cdn("fonts/MuseoSans-500.ttf"),
      cdn("fonts/MuseoSans-700.ttf"),
    ],
    (files) => query(homeQuery).then(() => files),
    (files) => query(featuredCollectionsQuery).then(() => files),
    (files) =>
      query(categoriesQuery).then(async function ({ data: { categories } }) {
        if (!categories) return files;
        const filesToAdd = await series(
          _map(categories.edges, ({ node: { slug } }) =>
            addFilesFromCategory(query, slug)
          )
        );
        return [...files, filesToAdd];
      }),
    (files) =>
      query(collectionsQuery).then(async function ({ data: { collections } }) {
        if (!collections) return files;
        const filesToAdd = await series(
          _map(
            collections.edges,
            ({ node: { slug } }) => async (filesForCollections = []) => {
              const filesCollectionToAdd = await series(
                _map(["clothing", "linen", "carpet", "accessory"], (kind) =>
                  addFilesFromCollectionAndKind(query, slug, kind)
                )
              );
              return [...filesForCollections, filesCollectionToAdd];
            }
          )
        );
        return [...files, filesToAdd];
      }),
  ]);
  minTotal = total;
  loading--;
  updateLoading();
  return _filter(_uniq(_flattenDeep(allFiles)));
}
