import builder from "./util";

export default builder({
  getInitialState: () => ({
    after: 0,
    limit: 9,
    page: 1,
  }),
  actions: {
    categoriesChangePage: ({limit}) => (page) => ({ after: (page - 1) * limit, limit, page }),
  }
});
