import _get from "lodash/get";
import { css } from "styled-components";

export const breakpoints = {
  desktop: 1140,
  tablet: 768,
  phone: 480
};
// https://www.styled-components.com/docs/advanced
export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
media.largeWidth = (...args) => css`
  @media (min-width: ${1140 / 16}em) {
    ${css(...args)};
  }
`;
media.bigHeight = (...args) => css`
  @media (min-height: ${1024 / 16}em) {
    ${css(...args)};
  }
`;

const _getTheme = (object, propName, defaultValue) =>
  _get(object, propName, _get(object, `theme.${propName}`, defaultValue));

export function get(propName, defaultValue) {
  return function(object) {
    return _getTheme(object, propName, defaultValue);
  };
}

export function getIf(testPropName, propName) {
  return function(object) {
    const testProp = _getTheme(object, testPropName);
    return testProp ? _getTheme(object, propName) : "";
  };
}

export function mixin(propName, ...args) {
  return function(object) {
    const fn = _getTheme(object, propName);
    if (!fn)
      throw new Error(`undefined prop name ${propName} for object ${object}`);
    return fn(...args);
  };
}

export const ROOT_FONT_SIZE_IN_PX = 16;

export function sizeInPxToFontSize(size) {
  return `${Number(size / ROOT_FONT_SIZE_IN_PX).toFixed(2)}rem`;
}

export function toggle(propName, valueIfTrue, valueIfFalse = null) {
  return function(object) {
    const prop = _getTheme(object, propName);
    return prop
      ? css`
          ${valueIfTrue};
        `
      : css`
          ${valueIfFalse};
        `;
  };
}

export function unit(value, unit = "px") {
  if (typeof value === "string") return value;
  return `${value}${unit}`;
}

export function reset() {
  return css`
    background: transparent none;
    border: 0;
    list-style: none;
    margin: 0;
    padding: 0;
  `;
}

export function hide() {
  return css`
    font-size: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 0;
  `;
}

export function responsiveImg(onHeight = false) {
  return onHeight
    ? css`
        max-height: 100%;
        //max-width: 100%; // Needed by Chrome — https://stackoverflow.com/questions/18252469/retain-aspect-ratio-for-full-height-responsive-image-in-chrome
        width: auto;
      `
    : css`
        height: auto;
        max-width: 100%;
      `;
}

export function truncate(width) {
  return `
    ${!!width &&
      css`
        width: ${unit(width)};
      `};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

export function minHeight(height) {
  return (...args) => {
    return css`
      @media (min-height: ${unit(height)}) {
        ${css(...args)};
      }
    `;
  };
}
