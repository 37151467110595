import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { reset, get, mixin } from "../../css/util";

export const Modal = styled(function({ className, ...props }) {
  return (
    <ReactModal
      overlayClassName={className}
      closeTimeoutMS={250}
      className={"Content"}
      {...props}
    />
  );
})`
  &.ReactModal__Overlay {
  }

  .ReactModal__Content {
    background-color: ${get("color.white")};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.33);
    box-sizing: border-box;
    margin: 0 -160px;
    left: 50%;
    position: fixed;
    top: 0;
    width: 320px;
    z-index: 9999;
    
    opacity: 0;
    transform: translateY(-100%);
    transition: all 350ms ease;
    &--after-open {
      opacity: 1;
      transform: translateY(0%);
    }
    &--before-close {
      transition: all 150ms ease;
    }
  }
`;

export const Success = styled.p`
  ${reset()};
  ${mixin('font.bold', 14)};
  background: ${get('color.success')};
  color: ${get('color.white')};
  padding: ${get('spacing.paddingHalf')} ${get('spacing.padding')};
  text-align: center;
`;

export const Error = Success.extend`
  background: ${get('color.error')};
`;