import builder from "./util";

export default builder({
  getInitialState: () => ({
    installed: false,
    version: null
  }),
  actions: {
    isInstalled: () => version => ({ installed: true, version }),
    isUninstalled: () => () => ({ installed: false, version: null }),
    hasUpdated: () => version => ({ installed: true, version })
  }
});
