import React from "react";
import _filter from "lodash/filter";
import _map from "lodash/map";
import _uniqBy from "lodash/uniqBy";

import { ProductStandardsArray } from "../../common/standards";

import { Slider, Image } from "./style";

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  speed: 250,
};

export default function Standards({ standards = null }) {
  const standardsList = _filter(
    ProductStandardsArray,
    ({ id }) => !standards || standards.indexOf(id) !== -1
  );
  return (
    <Slider {...sliderSettings}>
      {_map(_uniqBy(standardsList, "content"), ({ id, name, content }) => (
        <Image key={id} src={`/images/standards/${content}.png`} alt={name} />
      ))}
    </Slider>
  );
}
