import React from "react";
import styled from "styled-components";
import NavLink from "react-router-dom/NavLink";

import { reset, get, mixin, responsiveImg } from "../../css/util";
import CartIcon from "../../components/icons/CartIcon";
import AccountIcon from "../../components/icons/AccountIcon";

import logo from "../../images/logo-initial.svg";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled(function ({ children, ...props }) {
  return (
    <header {...props}>
      <nav>{children}</nav>
    </header>
  );
})`
  background: ${get("color.header.background")};
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
  display: flex;
  padding: ${get("spacing.padding")};
  position: relative;
  vertical-align: middle;
  z-index: 999;

  nav {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 1;
  height: 100%;
  min-height: 1px;
`;

export const MenuButton = styled(function ({ src, children, ...props }) {
  return (
    <button {...props}>
      <img src={src} alt={children} />
    </button>
  );
})`
  ${reset()};
  cursor: pointer;
  line-height: 0;
  margin-right: ${get("spacing.padding")};
  img {
    ${responsiveImg(true)};
    height: 40px;
  }
`;

export const HomeLink = styled(function ({ children, ...props }) {
  return (
    <NavLink title={children} {...props}>
      <img src={logo} alt={children} />
    </NavLink>
  );
})`
  display: inline-block;
  height: 40px;
  line-height: 0;
  vertical-align: middle;

  img {
    ${responsiveImg(true)};
  }
`;

export const AccountLink = styled(function ({ children, ...props }) {
  return (
    <NavLink {...props}>
      <span className="label">{children}</span>
      <AccountIcon size={24} />
    </NavLink>
  );
})`
  ${get("style.link")};
  display: inline-block;
  height: 24px;
  margin: 0 ${get("spacing.paddingHalf")};
  line-height: 0;
  padding: 8px;
  vertical-align: middle;

  > .label {
    ${mixin("font.default", 14, "24px")};
    display: inline-block;
    padding: 0 1em;
    vertical-align: top;
  }

  svg {
    fill: currentColor;
  }
`;

export const CartLink = AccountLink.withComponent(function ({
  children,
  bullet = 0,
  ...props
}) {
  return (
    <NavLink {...props}>
      <CartIcon size={24} />
      {!!bullet && <span className="bullet">{bullet}</span>}
    </NavLink>
  );
}).extend`
  margin-left: ${get("spacing.paddingHalf")};
  position: relative;
  width: 24px;
  
  > .bullet {
    ${mixin("font.bold", 10, "12px")};
    background: ${get("color.blue")};
    border-radius: 100%;
    box-sizing: border-box;
    color: ${get("color.white")};
    min-width: 20px;
    padding: 4px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0px;
  }
`;

export const Update = styled.div`
  border-right: 1px solid ${get("color.lightGrey")};
  display: inline-block;
  margin-left: auto;
  padding: 0 1em;
  text-align: center;
`;
