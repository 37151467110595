import React from "react";
import { graphql } from "react-apollo";
import { connect } from "react-redux";
import Redirect from "react-router-dom/Redirect";
import withRouter from "react-router-dom/withRouter";
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
  withState,
  withStateHandlers
} from "recompose";

import Menu from "../../components/Menu";
import UpdateButton from "../../components/UpdateButton";
import { memberRefreshMutation } from "../../graphql/mutations";
import authService from "../../stores/auth";
import menuIcon from "./images/menu.svg";
import {
  AccountLink,
  CartLink,
  Container,
  Header,
  HomeLink,
  Layout,
  MenuButton,
  Update
} from "./style";

export default compose(
  withState("error", "setError"),
  connect(
    ({ cart, auth, status }) => ({
      cart,
      user: auth.user,
      online: status.online
    }),
    dispatch => ({
      logout: () => dispatch(authService.logout())
    })
  ),
  // Refresh tokens
  graphql(memberRefreshMutation, {
    props: ({ mutate: memberRefreshToken, ownProps: { setError } }) => ({
      refreshTokens: async refreshToken => {
        try {
          const { data } = await memberRefreshToken({
            variables: { refreshToken }
          });
          const tokens = {
            ...data.tokens,
            expires_at: new Date().getTime() + data.tokens.expires_in * 1000
          };
          localStorage.setItem("tokens", JSON.stringify(tokens || null));
          return tokens;
        } catch (err) {
          console.error(err);
          setError(err.graphQLErrors[0].message);
          return false;
        }
      }
    })
  }),
  withStateHandlers(
    { menu: false },
    {
      toggleMenu: ({ menu }) => () => ({
        menu: !menu
      }),
      closeMenu: () => () => ({
        menu: false
      })
    }
  ),
  withRouter,
  withState("ready", "isReady", false),
  lifecycle({
    checkToken() {
      const { online, isReady, refreshTokens, logout } = this.props;
      try {
        const tokens = JSON.parse(localStorage.getItem("tokens") || "null");
        const { refresh_token, expires_at = new Date().getTime() } = tokens;
        if (!refresh_token) throw new Error(`Problem with refresh token`);
        const delayRefresh = Math.max(
          0,
          expires_at - new Date().getTime() - 5000
        );
        this.timeoutRefresh = window.setTimeout(async () => {
          try {
            if (!online) return window.setTimeout(() => isReady(true), 0);
            const ret = await refreshTokens(refresh_token);
            if (!ret || !ret.expires_in)
              throw new Error(`Problem with refresh token`);
            window.setTimeout(() => isReady(true), 0);
            window.setTimeout(
              this.checkToken,
              Math.max(0, ret.expires_in * 1000 - 5000)
            );
          } catch (err) {
            console.error(err);
            localStorage.setItem("tokens", null);
            window.setTimeout(logout, 0);
          }
        }, delayRefresh);
        if (delayRefresh > 0) return window.setTimeout(() => isReady(true), 0);
      } catch (err) {
        console.error(err);
        localStorage.setItem("tokens", null);
        window.setTimeout(logout, 0);
      }
    },

    componentDidMount() {
      this.historyUnlisten = this.props.history.listen(this.props.closeMenu);
      this.checkToken();
    },
    componentWillUnmount() {
      if (this.historyUnlisten) {
        this.historyUnlisten();
      }
    }
  }),
  branch(({ ready }) => !ready, renderNothing)
)(function LoggedIn({
  menu,
  user,
  toggleMenu,
  closeMenu,
  cart,
  online,
  error,
  location,
  children,
  ...props
}) {
  const { from } = location.state || { from: { pathname: "/" } };
  if (!user) return null;
  if (!!error) {
    return (
      <Redirect
        to={{ pathname: "/connexion", state: { from, messages: { error } } }}
      />
    );
  }
  return (
    <Layout {...props}>
      <Header>
        <MenuButton src={menuIcon} onClick={toggleMenu}>
          Menu
        </MenuButton>
        <HomeLink to={"/"}>Accueil</HomeLink>
        {/* <OnlineActivity online={online}>
          {online ? "En ligne" : "Hors ligne"}
        </OnlineActivity> */}
        <Update>
          <UpdateButton />
        </Update>
        <AccountLink to={"/mon-compte"} title="Mon compte">
          {user.name || "Anonyme"}
        </AccountLink>
        <CartLink to={"/panier"} title="Panier" bullet={cart.items.length}>
          {cart.items.length} élément(s) dans le panier
        </CartLink>
      </Header>
      <Container>{children}</Container>
      <Menu isOpen={menu} onClose={closeMenu} />
    </Layout>
  );
});
