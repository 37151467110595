import React, { Fragment } from "react";
import { graphql } from "react-apollo";
import { compose } from "recompose";
import _find from "lodash/find";
import { Helmet } from "react-helmet";

import loading from "../../hoc/loading";
import BackButton from "../../components/BackButton";
import Collection from "../../components/Collection";
import LeftColumnLayout from "../../layouts/LeftColumn";

import { featuredCollectionsQuery } from "../../graphql/queries";

import {
  CollectionsTitle,
  CollectionsList,
  CollectionsListLink
} from "./style";

export default compose(
  graphql(featuredCollectionsQuery, {
    options: {
      errorPolicy: "ignore",
      fetchPolicy: "cache-and-network"
    },
    props: ({ data: { collections, loading } }) => {
      return { collections, loading };
    }
  }),
  loading()
)(function FeaturedCollections({
  collections = [],
  match: { params: { collection } }
}) {
  const activeCollection = collection
    ? _find(collections, ({ slug }) => slug === collection)
    : null;

  return (
    <LeftColumnLayout
      column={
        <Fragment>
          <BackButton to={`/focus`} />
          <CollectionsTitle>Dernières collections</CollectionsTitle>
          <CollectionsList>
            {collections.map(({ id, slug, name }) => (
              <CollectionsListLink
                key={id}
                prefix="Collection"
                hasCollectionActive={true}
                to={`/focus/${slug}`}
              >
                {name}
              </CollectionsListLink>
            ))}
          </CollectionsList>
        </Fragment>
      }
    >
      <Helmet>
        <title>{`Collection ${
          activeCollection.name
        } dans les dernières collections`}</title>
      </Helmet>
      <Collection key={activeCollection.id} slug={activeCollection.slug} />
    </LeftColumnLayout>
  );
});
