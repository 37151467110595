import {media} from "../../css/util";
import { CollectionsSlider as CollectionsSliderOriginal, CollectionLink as CollectionLinkOriginal } from "../Category/style";

export const CollectionsSlider = CollectionsSliderOriginal.extend`
  height: 100%;
  
  ${media.largeWidth`
    margin-left: -15%;
    margin-right: -15%;
  `};
  
  > div {
    margin: 0px auto;
    position: static;
    top: 0%;
  }
`;

export const CollectionLink = CollectionLinkOriginal.extend`
`;
