import React from "react";
import Img from "react-image";
import styled from "styled-components";

import { responsiveImg } from "../../css/util";
import { media } from "../../util";

export default styled(function Image({
  media: mediaObj,
  size = "original",
  onHeight,
  ...props
}) {
  if (!mediaObj) return;
  const src = media(mediaObj, size);
  return <Img src={src} {...props} />;
})`
  ${({ onHeight }) => responsiveImg(!!onHeight)};
`;
