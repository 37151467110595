import React from 'react';
import styled from 'styled-components';

import ReactSlider from "../Slider";
import {get, responsiveImg} from "../../css/util";

export const Slider = styled(function({className, ...props}) {
  return <ReactSlider className={className} {...props} />
})`
  text-align: center;
  height: 100%;
  line-height: 0;
  position: relative;
  width: 100%;
  outline: none;
`;

export const Image = styled.img`
  ${responsiveImg()};
  max-height: calc(100vh - 144px);
  width: auto !important;
`;